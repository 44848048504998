import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Workbook } from '@progress/kendo-angular-excel-export';
import {
  ColumnComponent,
  ColumnReorderEvent,
  ColumnVisibilityChangeEvent,
  ExcelExportEvent,
  GridComponent,
  GridDataResult,
  PageSizeItem,
  RowArgs,
  RowClassArgs,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { saveAs } from '@progress/kendo-file-saver';
import { AuthenticationService, ModalService } from '@stockaid/services';
import {
  SalesVelocitySettingsType,
  ShipmentDetailType,
} from '@stockaid/shared-enums';
import { transformOversizeToYesNo } from '@stockaid/shared-utils';
import { transformBooleanToYesNo } from '@stockaid/utils';
import _ from 'lodash';
import moment from 'moment';
import { SnotifyService } from 'ng-snotify';
import {
  asapScheduler,
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  forkJoin,
  fromEvent,
  iif,
  Observable,
  of,
  Subject,
  Subscription,
  zip,
} from 'rxjs';
import {
  concatMap,
  debounceTime,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  amazonProductLink,
  amazonShipmentDetailLink,
  amazonSkuCentralLink,
  getCurrencyCodeByMarketplaceId,
} from 'src/app/core/constants/amazon-link.constant';
import { EU_MARKET_PLACE_IDS } from 'src/app/core/constants/asc.constant';
import { COUNTRIES } from 'src/app/core/constants/countries.constant';
import { Currencies } from 'src/app/core/constants/currency.constant';
import {
  COMMON_FORMAT_DATE,
  FORMAT_PATTERN,
} from 'src/app/core/constants/format-date.constant';
import { PHONE_NUMMER_PATTERN } from 'src/app/core/constants/patterns.constant';
import { FILTER_WINDOW_SIZE } from 'src/app/core/constants/size-constant';
import { STATES } from 'src/app/core/constants/states.constant';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import { LookUpDisplayControl } from 'src/app/core/infrastructure/classes/metadataDisplayControls/controls/look-up-display-control';
import { CustomPatternValidator } from 'src/app/core/infrastructure/classes/metadataDisplayControls/validators/custom-pattern-validator';
import { LessMoreValidator } from 'src/app/core/infrastructure/classes/metadataDisplayControls/validators/less-more-validator';
import { MinMaxValidator } from 'src/app/core/infrastructure/classes/metadataDisplayControls/validators/min-max-validator';
import { AdvancedFilter } from 'src/app/core/infrastructure/enums/advanced-filter.enum';
import { ColumnWidth } from 'src/app/core/infrastructure/enums/column-width.enum';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { DisplayControlInput } from 'src/app/core/infrastructure/enums/display-control-input.enum';
import { DisplayControlOutput } from 'src/app/core/infrastructure/enums/display-control-output.enum';
import { FieldValidatorType } from 'src/app/core/infrastructure/enums/field-validator-type.enum';
import { GridType } from 'src/app/core/infrastructure/enums/grid-type.enum';
import { Logic, Sort } from 'src/app/core/infrastructure/enums/logic.enum';
import { LookUpMappingType } from 'src/app/core/infrastructure/enums/look-up-mapping-type.enum';
import { MetaDataFieldType } from 'src/app/core/infrastructure/enums/meta-data-field-type.enum';
import {
  DemandField,
  ItemField,
  ItemMetricField,
  PurchaseOrderField,
  RestockSuggestionSupplierField,
  ShipmentDetailField,
  ShipmentField,
  SummaryByVendorField,
  SummaryField,
  SupplyField,
  VendorField,
  PurchaseOrderItemField,
} from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { ModalType } from 'src/app/core/infrastructure/enums/modal-type.enum';
import {
  NotificationKey,
  NotificationType,
} from 'src/app/core/infrastructure/enums/notification.enum';
import { RestockType } from 'src/app/core/infrastructure/enums/restock-type.enum';
import { ShipmentStatus } from 'src/app/core/infrastructure/enums/shipment-status.enum';
import { AdditionalKeysType } from 'src/app/core/infrastructure/interfaces/additional-keys.interface';
import {
  ICountry,
  IState,
} from 'src/app/core/infrastructure/interfaces/country.interface';
import { ICsvBoxColumn } from 'src/app/core/infrastructure/interfaces/csvbox.interface';
import { IFieldValidator } from 'src/app/core/infrastructure/interfaces/i-field-validator';
import { Bom } from 'src/app/core/models/bom';
import { Company } from 'src/app/core/models/company';
import { Demand } from 'src/app/core/models/demand';
import { FileDetails } from 'src/app/core/models/file-details';
import { GridView, IGridViewChangeEvent } from 'src/app/core/models/grid-view';
import { Item } from 'src/app/core/models/item';
import { ItemSite } from 'src/app/core/models/item-site';
import { IPlan } from 'src/app/core/models/plans';
import { POItem } from 'src/app/core/models/po-items';
import { RestockSuggestionSupplier } from 'src/app/core/models/restock-suggestion-supplier';
import { Shipment } from 'src/app/core/models/shipment';
import { ShipmentDetail } from 'src/app/core/models/shipment-detail';
import { SubscriptionStatus } from 'src/app/core/models/subscription';
import { ISummaryResponse, Summary } from 'src/app/core/models/summary';
import { Supplier } from 'src/app/core/models/supplier';
import { Supply } from 'src/app/core/models/supply';
import { User } from 'src/app/core/models/user';
import { SummaryByVendor, Vendor } from 'src/app/core/models/vendor';
import { BillingService } from 'src/app/core/services/billing.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { CustomValidators } from 'src/app/core/services/form/form.validator';
import { HeaderService } from 'src/app/core/services/header.service';
import { ItemService } from 'src/app/core/services/item.service';
import { ProcessingService } from 'src/app/core/services/processing.service';
import { PusherService } from 'src/app/core/services/pusher.service';
import { ResourceService } from 'src/app/core/services/resource-service';
import { ShipmentDetailService } from 'src/app/core/services/shipment-detail.service';
import { ShipmentService } from 'src/app/core/services/shipment.service';
import { SummaryService } from 'src/app/core/services/summary.service';
import { SupplierService } from 'src/app/core/services/supplier.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { VendorService } from 'src/app/core/services/vendor.service';
import {
  addTextUnitToNumber,
  conditionalOperator,
  getContrastYIQ,
  getReadableKendoOperator,
  handleFilterWithDates,
  sortColumnsByField,
  transformColumnWidth,
  transformFieldType,
  transformFilterableColumn,
  transformSortableField,
} from 'src/app/core/utils';
import { ChangeCreatedDateModalComponent } from 'src/app/routes/dashboard/shipments/manage-shipments/shipment-detail/change-created-date-modal/change-created-date-modal.component';
import { ShipmentPickListViewComponent } from 'src/app/routes/dashboard/shipments/manage-shipments/shipment-pick-list-view/shipment-pick-list-view.component';
import { DefaultRestockSuggestionSupplier } from 'src/app/routes/dashboard/shipments/restock-suggestions/restock-suggestions.constant';
import { PROCESSING_STEP_NAME } from 'src/app/routes/onboarding/processing/processing.constant';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { EditTagsModalComponent } from '../edit-tags-modal/edit-tags-modal.component';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { ImportFailModalComponent } from '../import-fail-modal/import-fail-modal.component';
import { PrintStickersModalComponent } from '../print-stickers-modal/print-stickers-modal.component';
import { SalesVelocityModalComponent } from '../restock-settings/restock-settings.component';
import { SaleVelocityCalculationComponent } from '../sale-velocity-calculation/sale-velocity-calculation.component';
import { VendorHiddenModalComponent } from '../vendor-hidden-modal/vendor-hidden-modal.component';
import { ViewOfHistoryComponent } from '../view-of-history/view-of-history.component';
import {
  CountryCode,
  getTip,
  GridName,
  ICON_FORECAST,
} from './forcast-rxdata-table.constant';
import { PurchaseOrder } from 'src/app/core/models/purchase-order';
import { PurchaseOrderStatus } from 'src/app/core/infrastructure/enums/purchase-order-status';
import { HOURS_IN_DAY } from 'src/app/core/constants/misc.constant';
import { PurchaseOrderItemService } from 'src/app/core/services/purchase-order-item.service';
import { PurchaseOrderItem } from 'src/app/core/models/purchase-order-item';
import { PurchaseOrderService } from 'src/app/core/services/purchase-order.service';
import { toggleFullScreen } from '../../full-screen/toggle-full-screen';

declare const CSVBoxImporter: any;

@Component({
  selector: 'app-forecast-rxdata-table',
  templateUrl: './forecast-rxdata-table.component.html',
  styleUrls: ['./forecast-rxdata-table.component.scss'],
})
export class ForecastRXDataTableComponent<T>
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() itemName: GridName;
  @Input() modelType: <T>() => T;
  @Input() rows: any[] = [];
  @Input() loading: boolean = false;
  @Input() resourceService: ResourceService<T>;
  @Input() initGridState: State;
  @Input() saveGridStateIsVisible: boolean = true;
  @Input() addIsVisible: boolean = true;
  @Input() deleteIsVisible: boolean = true;
  @Input() bulkMngtIsVisible: boolean = true;
  @Input() printIsVisible: boolean = false;
  @Input() fullScreenIconIsVisible: boolean = true;
  @Input() toogleFullScreenIsVisible: boolean = true;
  @Input() isViewOnly: boolean = false;
  @Input() hasRemoveItem: boolean = false;
  @Input() company: Company;
  @Input() gridDescription: string;
  @Input() hasLinks = false;
  @Input() isCustom = false;
  @Input() additionalReadOnlyCols: string[] = [];
  @Input() advancedFilter: AdvancedFilter | null = null;
  @Input() shouldLoadGrid: Subject<any>;
  @Input() keyName = 'key';
  @Input() notiDisplayName = 'name';
  @Input() reloadGrid: Subject<any>;
  @Input() itemsKeySelected: string[] = [];
  @Input() itemsSticker: any;
  @Input() isShipmentSupplier = false;
  @Input() refreshIsVisible: boolean = false;
  @Input() fieldsToLookUp: string[];
  @Input() isShipmentDetail: boolean = false;
  @Input() autoOpenModal: boolean = false;
  @Input() isModifyUnitsVisible: boolean = false;
  @Input() canChangeCreatedDate: boolean = false;
  @Input() isPrintList: boolean = false;
  @Input() currentUser: User;
  @Input() isLocalQtyValidationDisabled: boolean = false;
  @Input() searchTerm: string;
  @Input() isSelectable: boolean = true;
  @Input() restockKey: string;
  @Input() shouldShowMaintenanceMessage = false;
  @Input() userSelectedGridView: GridView;
  @Input() userSelectedGridViewState: State;
  @Input() purchaseOrderKey: string;
  @ViewChild('dropDownListFilter') dropDownListFilter: DropDownListComponent;
  @Input() set dynamicColumnsForCsvBox(value) {
    this._dynamicColumnsForCsvBox = value;
    this.loadCsvboxImporter();
  }
  @Input() userMetaDataFields: MetaDataField[] = [];

  get dynamicColumnsForCsvBox(): ICsvBoxColumn[] {
    return this._dynamicColumnsForCsvBox;
  }
  private _dynamicColumnsForCsvBox: ICsvBoxColumn[];

  @Input() set columns(value: MetaDataField[]) {
    // To recreate grid columns.
    this.selectedColumns = null;
    if (!value) {
      return;
    }

    this.selectedColumns = value
      ?.filter((field) => {
        return field?.displayControl?.displayWithCompanyType?.includes(
          this.currentCompany?.companyType
        );
      })
      ?.filter((field) => {
        return (
          ((!this.currentCompany?.isUsingAWDInventoryQty &&
            field?.field !== ItemField.awdInventoryQty &&
            field?.field !== ItemField.inboundAwd) ||
            this.currentCompany?.isUsingAWDInventoryQty) &&
          field?.field !== SummaryField.onNewPo
        );
      })
      .map((field) => Object.assign({}, field));
    this.lockedColumns = this.selectedColumns
      ?.filter((c) => c.locked)
      ?.map((c) => c.field);

    this.transformColumns();

    // recreate grid if the component have created
    if (this.isCreatedApp) {
      this.loadGridItems();
      this.isCreatedApp = false;
    }
  }

  transformColumns(): void {
    this.selectedColumns = this.selectedColumns.map((column) => {
      column.dataType = transformFieldType(column.dataType);
      column.sortable = transformSortableField(column, this.itemName);
      column.reOrderable = true;
      column.filterable = transformFilterableColumn(
        column,
        this.advancedFilter,
        this.itemName
      );
      column.width = column.width
        ? column.width
        : transformColumnWidth(column, this.isPrintList, this.itemName);
      return column;
    });
    this.selectedColumns = sortColumnsByField(
      this.selectedColumns,
      'initialUpload',
      'desc'
    );
  }

  @Input() set selectingItem(value: any) {
    this._selectingItem = value;
    this.loadGridItems();
  }

  get selectingItem(): any {
    return this._selectingItem;
  }

  @Input() set gridData(value: Observable<T[]>) {
    this._gridData = value;
  }

  get gridData(): Observable<T[]> {
    return this._gridData;
  }

  @Input() set specificItemId(value: string) {
    this._specificItemId = value;
    if (!this._specificItemId) {
      return;
    }

    this.gridState.filter.filters = [
      {
        filters: [{ field: 'key', operator: 'eq', value: this.specificItemId }],
        logic: Logic.and,
      },
    ];

    this.isFilter = true;
    this.loadGridItems();
  }
  get specificItemId(): string {
    return this._specificItemId;
  }

  get metaDataFields() {
    const objectProperties = Object.getOwnPropertyNames(this.newItem);
    return this.selectedColumns?.filter((column) => {
      if (column.field === 'itemKey') {
        return false;
      }

      return objectProperties.includes(column.field);
    });
  }

  get country(): AbstractControl {
    return this.supplierForm?.controls?.country;
  }

  get addressLine1(): AbstractControl {
    return this.supplierForm?.controls?.addressLine1;
  }

  get city(): AbstractControl {
    return this.supplierForm?.controls?.city;
  }

  get stateOrProvince(): AbstractControl {
    return this.supplierForm?.controls?.stateOrProvince;
  }

  get usState(): AbstractControl {
    return this.supplierForm?.controls?.usState;
  }

  get postalCode(): AbstractControl {
    return this.supplierForm?.controls?.postalCode;
  }

  get phoneNumber(): AbstractControl {
    return this.supplierForm?.controls?.phoneNumber;
  }

  get isMarketPlaceIdEU(): boolean {
    return this.currentCompany?.marketplaceId === 'EU';
  }

  get displayCurrency() {
    const currencyCodeByMarketPlaceId = getCurrencyCodeByMarketplaceId(
      this.currentCompany?.marketplaceId
    );
    const currencyCodeSetting = this.currentCompany?.currencyCode;
    const code = currencyCodeSetting || currencyCodeByMarketPlaceId || 'USD';
    const currency = Currencies.find((currency) => currency.code === code);
    return { code: currency.code, symbol: currency.symbol };
  }

  get isCompanyTypeASC(): boolean {
    return this.currentCompany?.companyType === CompanyType.ASC;
  }

  get isCompanyTypeCSV(): boolean {
    return this.currentCompany?.companyType === CompanyType.CSV;
  }

  get isCompanyTypeQBFS(): boolean {
    return this.currentCompany?.companyType === CompanyType.QBFS;
  }

  get isDeleteItemsVisible(): boolean {
    return (
      this.itemName === GridName.Item ||
      (this.itemName === GridName.Demand &&
        [CompanyType.ASC, CompanyType.QBFS].includes(
          this.currentCompany?.companyType as CompanyType
        ))
    );
  }

  get isNewLockAvailable(): boolean {
    if (this.isSticker) {
      return false;
    }

    return [
      GridName.Item,
      GridName.Supplier,
      GridName.Demand,
      GridName.Supply,
      GridName.ItemsInPo,
      GridName.AllAvailableItems,
      GridName.Shipment,
      GridName.ShipmentAWD,
      GridName.AmazonShipmentItem,
      GridName.ForecastShipmentItem,
      GridName.SupplierList,
      GridName.PurchaseOrder,
      GridName.PurchaseOrderItem,
    ].includes(this.itemName);
  }

  get isDefaultLockVisible(): boolean {
    if (this.lockedColumns?.length > 1) {
      return true;
    }

    const [lockedColumn] = this.lockedColumns || [];
    switch (this.itemName) {
      case GridName.Item:
      case GridName.Supplier:
        return lockedColumn !== ItemField.name;

      case GridName.SupplierList:
        return lockedColumn !== RestockSuggestionSupplierField.vendorName;

      case GridName.Demand:
      case GridName.Supply:
      case GridName.ItemsInPo:
      case GridName.AllAvailableItems:
      case GridName.AmazonShipmentItem:
      case GridName.ForecastShipmentItem:
      case GridName.PurchaseOrderItem:
        return lockedColumn !== DemandField.itemName;
      case GridName.Shipment:
      case GridName.ShipmentAWD:
        return lockedColumn !== ShipmentField.shipmentId;
      case GridName.PurchaseOrder:
        return lockedColumn !== PurchaseOrderField.key;

      default:
        return false;
    }
  }

  get isUploadUndoable(): boolean {
    return (
      !this.isModalOpended &&
      !this.isOnboarding &&
      [
        GridName.Supplier,
        GridName.Item,
        GridName.Demand,
        GridName.PurchaseOrder,
        GridName.Supply,
        GridName.Bom,
      ].includes(this.itemName)
    );
  }

  @Input() justChangedTab: Subject<any>;
  @Input() reloadItems: Subject<any>;
  @Input() isImportFailModalOpen = false;
  @Input() neededToBeHiddenElements: HTMLElement[] = [];
  @Input() forbiddenFields: string[] = [];
  @Input() isSidebarCollapsed = false;
  @Input() isFullScreen = false;
  @Input() isSticker = false;

  @Input() set removalOrdersFilter(value: CompositeFilterDescriptor) {
    this._removalOrdersFilter = value;
    if (value) {
      this.filterRemovalOrdersQty = (
        value.filters[1] as FilterDescriptor
      ).value;
    }
  }

  get removalOrdersFilter(): CompositeFilterDescriptor {
    return this._removalOrdersFilter;
  }

  private _removalOrdersFilter: CompositeFilterDescriptor;

  @Output() columnChange = new EventEmitter();
  @Output() stateChange = new EventEmitter();
  @Output() reorderChange = new EventEmitter();
  @Output() columnResize = new EventEmitter();
  @Output() rowClick = new EventEmitter();
  @Output() dataChange = new EventEmitter();
  @Output() detailClick = new EventEmitter();
  @Output() clearClick = new EventEmitter();
  @Output() isImportFailModalOpenChange = new EventEmitter();
  @Output() gridViewChange = new EventEmitter();
  @Output() customAddClick = new EventEmitter();
  @Output() itemsStickerChange = new EventEmitter();
  @Output() cancelShipment = new EventEmitter();
  @Output() reloadShipment = new EventEmitter();
  @Output() reloadPo = new EventEmitter();
  @Output() searchChange = new EventEmitter();
  @Output() updateShipmentItemAfterSubmitted = new EventEmitter();
  @Output() casePackChecked = new EventEmitter();
  @Output() onHideSupply = new EventEmitter();
  @Output() removedItemInPOIds = new EventEmitter();
  @Output() openPoPDF = new EventEmitter();
  @Output() createPurchaseOrderShipment = new EventEmitter();
  @Output() printStickerClick = new EventEmitter();
  @ViewChild(GridComponent) grid: GridComponent;
  @ViewChild('checkboxSelectAll') private checkboxSelectAll;
  @ViewChild('checkboxSelectAllTag') private checkboxSelectAllTag;
  @ViewChild('groupcheck') private groupCheckbox;
  @ViewChild('gridView') gridView: HTMLElement;
  @ViewChild('content') modalContent: HTMLElement;

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this.isFullScreen) {
      this.fullScreen();
      window.history.forward();
    }
  }

  readonly AdvancedFilter = AdvancedFilter;
  readonly SummaryField = SummaryField;
  readonly GridName = GridName;
  readonly RestockSuggestionSupplierField = RestockSuggestionSupplierField;
  readonly ShipmentField = ShipmentField;
  readonly ShipmentDetailField = ShipmentDetailField;
  readonly ItemField = ItemField;
  readonly PROCESSING_STEP_NAME = PROCESSING_STEP_NAME;
  readonly CompanyType = CompanyType;
  readonly ShipmentStatus = ShipmentStatus;
  readonly PurchaseOrderStatus = PurchaseOrderStatus;
  readonly PurchaseOrderField = PurchaseOrderField;
  readonly ModalType = ModalType;
  readonly transformBooleanToYesNo = transformBooleanToYesNo;
  readonly transformOversizeToYesNo = transformOversizeToYesNo;
  readonly SalesVelocitySettingsType = SalesVelocitySettingsType;
  readonly addTextUnitToNumber = addTextUnitToNumber;
  readonly VendorField = VendorField;
  readonly countries: ICountry[] = COUNTRIES;
  readonly states: IState[] = STATES;
  readonly amazonProductLink = amazonProductLink;
  readonly amazonShipmentDetailLink = amazonShipmentDetailLink;
  readonly EU_MARKET_PLACE_IDS = EU_MARKET_PLACE_IDS;
  readonly FORMAT_PATTERN = FORMAT_PATTERN;
  readonly FILTER_WINDOW_SIZE = FILTER_WINDOW_SIZE;
  readonly getContrastYIQ = getContrastYIQ;
  readonly MAX_COUNT = 1000000;
  readonly FORMAT_DATE = COMMON_FORMAT_DATE;

  supplierForm: FormGroup;
  suppliers = [];
  vendorSelected;
  addressSelection: any;
  modalReference: NgbModalRef;
  importer: any;
  currentSort: SortDescriptor[];
  totalRecords: number = 0;
  selectedVendor: any;
  leadTimeAndOrderIntervalTotalError: boolean;
  suppliesMaxOrderQty: number = this.currentCompany.autoHideMaxOrderQty;
  public metaDataTypeEnum = MetaDataFieldType;
  public inputTypeEnum = DisplayControlInput;
  public outputTypeEnum = DisplayControlOutput;
  public newForm: FormGroup;
  public newItemMessage: string;
  public downloadFileName: string;
  public isDisabledDelete: boolean = true;
  public isChildEnabled: boolean = false;
  public isDisabledPrintSticker: boolean = true;
  public isFilter: boolean = false;
  public isGridEmpty = true;
  public isDateConflict: boolean = false;
  public isShowImageField: boolean = false;
  public modalTitle: string;
  public modalAddressTitle: string = 'Add Address';
  public addressData = [];
  public formGroups: FormGroup = new FormGroup({ items: new FormArray([]) });

  public gridState: State = {
    skip: 0,
    take: 20,
    sort: [],
    filter: {
      logic: Logic.and,
      filters: [],
    },
    // filter would be here to if we were intialzating it with a value.
    group: [],
  };
  public displayedGridState: State;
  public allData: Observable<any>;
  public gridDataItems: GridDataResult;
  public pageSize: number = 20;
  public pageSizes: PageSizeItem[] = [
    { text: '20', value: 20 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
  ];
  public skip: number = 0;
  public sortDescriptor: SortDescriptor[] = [];
  public filterTerm: number = null;
  public items: any;
  public selectedRows: Map<string, T> = new Map();
  public lookupParentKey: string;
  public currentAsin: string;
  selectedColumns: MetaDataField[] = [];
  disabledExtraFields: string[] = [];
  hasActiveItemsReachLimit = false;
  hasOrdersReachLimit = false;
  columnVisibilityArgs: ColumnVisibilityChangeEvent;
  columnReorderArgs: ColumnReorderEvent & { isLocked: boolean };
  columnResizeArgs: { field: string; newWidth: number };
  clearClicked$: Subject<any> = new Subject<any>();
  ICON_MAXIMIZE = 'assets/images/full-screen.svg';
  ICON_MINIMIZE = 'assets/images/minimize.svg';
  ICON_BARCODE = 'assets/images/fa-barcode-solid.svg';
  iconFullScreen: string = this.ICON_MAXIMIZE;
  DEFAULT_MINIMUM_GRID_HEIGHT = 500;
  gridHeight: number = this.DEFAULT_MINIMUM_GRID_HEIGHT;
  pageable: any = { pageSizes: this.pageSizes };
  sortable: boolean = true;
  reorderable: boolean = true;
  filterable: any = 'menu';
  columnMenu: boolean = true;
  isProcessing: boolean = false;
  isDeleting: boolean = false;
  filterState: CompositeFilterDescriptor;
  searchFilters: CompositeFilterDescriptor[];
  bulkEditForm: FormGroup;
  originalItems: any[];
  isModifyingUnits: boolean = false;
  bulkEditAllowedFields: string[] = [];
  isIconHeader: boolean;
  shipmentLastRefresh: Date;
  selectedTagRows: T[] = [];
  selectedBoxLengthRows: Item[] = [];
  selectedBoxWidthRows: Item[] = [];
  selectedBoxHeightRows: Item[] = [];
  selectedBoxWeightRows: Item[] = [];
  selectedKeyTag: any;
  selectedKeyBoxLength: any = {};
  selectedKeyBoxWidth: any = {};
  selectedKeyBoxHeight: any = {};
  selectedKeyBoxWeight: any = {};
  gridItemSelectedKey: string[] = [];
  hiddenColumns: string[] = [];
  isImporterReady = false;
  selectedPlan: IPlan;
  isSubActive: boolean;
  editingRowIndex: number;
  isExporting = false;
  filterRemovalOrdersQty: number;
  filterList: { text: string; value: any }[];
  defaultFilterList: { text: string; value: any }[];
  isOpenFilter: boolean = false;
  selectedFilter: { field: string; title: string; editor: any }[] = [];
  filterWindowLeft: number;
  filterWindowTop: number;
  defaultFilter = { text: 'Filter by', value: null };
  isDisableFilter = true;
  defaultFilterValue: CompositeFilterDescriptor = {
    logic: Logic.and,
    filters: [],
  };
  filterIndex: number;
  vendorsGroupedByKey: Record<string, Vendor[]> = {};
  reorderSubject$: Subject<any> = new Subject<any>();
  lockSubject$: Subject<any> = new Subject<any>();
  lockDelaySubscription$: Subscription;
  lockedColumns: string[] = [];
  isLockedByButton = false;
  isResettingLockedColumns = false;
  remainingUndoTime: number = 0;
  undoUploadNotification$: Subject<any> = new Subject<any>();
  isShowUndoToast: boolean = false;
  private _gridData: Observable<T[]>;
  private _selectingItem: any;
  private newItem: any;
  private isCreatedApp = false;
  private originalValue: T;
  private saveInProgress: boolean = false;
  private _specificItemId: string;
  private removedItemKeys: string[] = [];
  private isModalOpended = false;
  private currentToast: any;

  iconForecast = ICON_FORECAST;
  ICON_ASC = 'k-icon k-i-sort-asc-sm';
  ICON_DESC = 'k-icon k-i-sort-desc-sm';

  get bulkEditFormArray(): FormArray {
    return this.bulkEditForm.controls.formArray as FormArray;
  }

  get currentCompany() {
    return this.companyService.currentCompany();
  }

  get isShowRestockAMZ() {
    // Case 1: For admin user.
    // Case 2: ASC company && company.displayRestockAMZ
    return (
      this.currentCompany?.companyType === CompanyType.ASC &&
      (this.isAdmin || this.currentCompany?.displayRestockAMZ)
    );
  }

  get isOnboarding() {
    return this.router.url.includes('onboarding');
  }

  get isAdmin(): boolean {
    return this.authService.currentUser?.isAdmin;
  }

  get finalItemName(): string {
    switch (this.itemName) {
      case GridName.Bom:
        return 'Kit';

      case GridName.ItemSite:
        return 'Item';

      default:
        return this.itemName;
    }
  }

  get ignoreOldPOsDays() {
    return this.currentCompany?.ignoreOldPOsDays || 0;
  }

  @Input() set isExtraExportVisible(value: boolean) {
    this._isExtraExportVisible = value;
  }
  get isExtraExportVisible() {
    return this._isExtraExportVisible === undefined
      ? !this.isGridEmpty &&
          ((this.isViewOnly &&
            this.itemName !== GridName.SuggestedPos &&
            (this.itemName === GridName.AllAvailableItems || !this.isCustom)) ||
            this.itemName === GridName.Shipment ||
            this.itemName === GridName.ShipmentAWD ||
            this.itemName === GridName.PurchaseOrder ||
            this.itemName === GridName.ForecastShipmentItem ||
            this.itemName === GridName.ForecastShipmentItemChosen ||
            this.itemName === GridName.ForecastShipmentItemValid ||
            this.itemName === GridName.PurchaseOrderItem ||
            this.itemName === GridName.AmazonShipmentItem)
      : this._isExtraExportVisible;
  }
  private _isExtraExportVisible: boolean;

  constructor(
    private snotifyService: SnotifyService,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private modalSer: ModalService,
    private syncService: SyncService,
    private processingService: ProcessingService,
    private pusherService: PusherService,
    private summaryService: SummaryService,
    private itemService: ItemService,
    private altModalService: ModalService,
    private headerService: HeaderService,
    private billingService: BillingService,
    private vendorService: VendorService,
    private shipmentDetailService: ShipmentDetailService,
    private shipmentService: ShipmentService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    public supplierService: SupplierService,
    private purchaseOrderItemService: PurchaseOrderItemService,
    private purchaseOrderService: PurchaseOrderService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.grid) {
      // when page first initializes this is false, don't die.
      asapScheduler.schedule(() => {
        if (!this.advancedFilter) {
          this.grid.loading = true;
        }
      });

      setTimeout(() => {
        this.calcGridHeight();
      }, 2000);

      if (this.autoOpenModal) {
        this.open(this.modalContent);
      }
    }
    this.calcFilterWindowPosition();
  }

  ngOnInit(): void {
    this.iconFullScreen = this.isFullScreen
      ? this.ICON_MINIMIZE
      : this.ICON_MAXIMIZE;
    this.getFilterList();

    if (!this.dynamicColumnsForCsvBox) {
      this.loadCsvboxImporter();
    }

    this.loadAdditionalData();
    this.calculateUndoLeftTime();

    this.undoUploadNotification$.pipe(debounceTime(300)).subscribe(() => {
      if (this.isShowUndoToast) {
        if (this.currentToast) {
          this.snotifyService.remove(this.currentToast.id);
        }

        this.currentToast = this.snotifyService
          .success('Undo upload successfully')
          .on('mounted', () => {
            this.isShowUndoToast = false;
          });

        this.remainingUndoTime = 0;
      }
    });

    // Get restock key of warehouse and supplier from url
    const isRestockSuggestionShipment = this.router.url.includes(
      'restock-suggestions'
    );

    if (!this.restockKey) {
      this.restockKey = conditionalOperator(
        isRestockSuggestionShipment,
        this.route.snapshot.queryParamMap.get('createNew'),
        this.route.snapshot.paramMap.get('restockKey')
      );
    }

    this.bulkEditForm = this.formBuilder.group({
      formArray: new FormArray([]),
    });

    this.displayedGridState = this.gridState;
    this.loadAdditionalFilters();

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(250),
        tap(() => this.calcGridHeight())
      )
      .subscribe();

    this.justChangedTab?.subscribe(() => {
      setTimeout(() => {
        const iconExpandList = document.querySelectorAll(
          '.forecast-table__header__toolbar__fullScreen'
        );

        iconExpandList.forEach((item) => {
          item.setAttribute(
            'src',
            conditionalOperator(
              this.isFullScreen,
              this.ICON_MINIMIZE,
              this.ICON_MAXIMIZE
            )
          );
        });

        this.calcGridHeight();
      }, 250);
    });

    // Init grid state from user
    this.initializeGridState();

    this.processingService.processingStopped
      .pipe(this.autoCleanUp())
      .subscribe(() => {
        this.loadGridItems();
      });

    this.handleInitialPusher();

    // Reset page offset and calculate active items when switching company
    this.headerService
      .getChange()
      .pipe(
        this.autoCleanUp(),
        switchMap(() => {
          this.gridState.skip = 0;
          this.displayedGridState.skip = 0;
          this.loadGridItems();
          return [GridName.Item, GridName.Demand].includes(this.itemName)
            ? this.calculateHasActiveItemsReachLimit()
            : of(null);
        })
      )
      .subscribe();

    this.setupGridForSpecificItemType();

    this.headerService.goToItemManagementSubject
      .pipe(this.autoCleanUp())
      .subscribe(() => this.isFullScreen && this.fullScreen());

    this.headerService.isFullScreen
      .pipe(
        this.autoCleanUp(),
        tap((isFullScreen) => {
          this.isFullScreen = isFullScreen;
          this.iconFullScreen = this.isFullScreen
            ? this.ICON_MINIMIZE
            : this.ICON_MAXIMIZE;
        })
      )
      .subscribe();

    this.headerService.navbarCollapseSubject$
      .pipe(
        this.autoCleanUp(),
        tap((value) => {
          if (!value) {
            return;
          }

          this.isSidebarCollapsed = !this.isSidebarCollapsed;
        })
      )
      .subscribe();

    zip(this.reorderSubject$, this.lockSubject$)
      .pipe(
        this.autoCleanUp(),
        tap(([reorderEvent, lockEvent]) => {
          const [lockingColumn] = lockEvent?.columns || [];

          if (this.isResettingLockedColumns) {
            this.lockedColumns = [];
          }

          const isLocked = this.checkColumnLockedStatus(lockingColumn);
          if (!isLocked) {
            reorderEvent.newIndex++;
          }

          this.reorderChange.emit({
            ...reorderEvent,
            isLocked,
            isResettingLockedColumns: this.isResettingLockedColumns,
          });
          this.columnReorderArgs = {
            ...reorderEvent,
            isLocked,
            isResettingLockedColumns: this.isResettingLockedColumns,
          };
          this.isResettingLockedColumns = false;
        })
      )
      .subscribe();

    // Reload grid when triggering this subject
    // Ex: complete previous step will reload the grid of the next step in restock/shipment
    this.shouldLoadGrid
      ?.pipe(this.autoCleanUp())
      ?.subscribe(() => this.loadGridItems());

    // The initial item loading
    if (!Boolean(this.shouldLoadGrid)) {
      this.loadGridItems();
    }

    this.reloadGrid?.pipe(this.autoCleanUp())?.subscribe(() => {
      this.loadGridItems();
    });

    this.onStateChange(this.gridState, false);

    this.isCreatedApp = true;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  countryChange(country: ICountry) {
    if (country.countryCode !== CountryCode.US) {
      this.stateOrProvince.setValue('');
    }
  }

  supplierChange(supplier) {
    this.vendorSelected = supplier;
  }

  sortField(field: string) {
    const currentDir = this.gridState.sort?.find((s) => s.field === field)?.dir;

    let dir;
    switch (currentDir) {
      case Sort.asc:
        dir = Sort.desc;
        break;

      case Sort.desc:
        dir = '';
        break;

      default:
        dir = Sort.asc;
    }

    this.currentSort = dir
      ? [
          {
            field,
            dir,
          },
        ]
      : [];
    this.onStateChange(this.gridState);
  }

  getSortIconClass(field: string) {
    const dir = this.gridState.sort?.find((s) => s.field === field)?.dir;

    switch (dir) {
      case Sort.asc:
        return this.ICON_ASC;

      case Sort.desc:
        return this.ICON_DESC;

      default:
        return '';
    }
  }

  handleAddress() {
    let newAddress = new Supplier({
      key: '',
      vendorKey: '',
      countryCode: this.country.value.countryCode,
      addressLine1: this.addressLine1.value,
      city: this.city.value,
      stateOrProvinceCode:
        (this.stateOrProvince.value !== CountryCode.US &&
          this.stateOrProvince.value) ||
        this.usState.value.stateCode,
      postalCode: this.postalCode.value,
      phoneNumber: this.phoneNumber.value,
    });

    if (this.modalAddressTitle === 'Edit Address') {
      this.addressData[this.addressSelection.rowIndex] = newAddress;
      this.modalAddressTitle = 'Add Address';
    } else {
      this.addressData.push(newAddress);
    }

    this.modalReference.close();

    this.buildAddNewAddressForm(new Supplier());
  }

  public cancelAddNewAddress(isClearData?: boolean) {
    if (isClearData) {
      this.addressData = [];
    }

    this.buildAddNewAddressForm(new Supplier());
    this.modalAddressTitle = 'Add Address';
    this.addressSelection = null;
  }

  private buildAddNewAddressForm(supplier: Supplier) {
    this.supplierForm = this.formBuilder.group({
      country: [
        this.countries.find((c) => c.countryCode === supplier.countryCode) ||
          this.countries[0],
        [Validators.required],
      ],
      addressLine1: [
        supplier.addressLine1,
        [Validators.required, Validators.maxLength(180)],
      ],
      addressLine2: [supplier.addressLine2, [Validators.maxLength(60)]],
      city: [supplier.city, [Validators.required, Validators.maxLength(30)]],
      stateOrProvince: [supplier.stateOrProvinceCode],
      usState: [
        this.states.find((c) => c.stateCode === supplier.stateOrProvinceCode) ||
          this.states[0],
        [Validators.required],
      ],
      postalCode: [
        supplier.postalCode,
        [Validators.required, Validators.maxLength(30)],
      ],
      phoneNumber: [
        supplier.phoneNumber,
        [Validators.maxLength(40), Validators.pattern(PHONE_NUMMER_PATTERN)],
      ],
    });
  }

  onCancelShipment(): void {
    this.cancelShipment.emit();
  }

  checkCancelShipmentIsVisible(): boolean {
    const statusCanCancelShipment = [
      ShipmentStatus.RECEIVING,
      ShipmentStatus.SHIPPED,
      ShipmentStatus.IN_TRANSIT,
      ShipmentStatus.DELIVERED,
      ShipmentStatus.CHECKED_IN,
    ];
    return statusCanCancelShipment.includes(this.selectingItem?.status);
  }

  checkShowWarningIcon(orangeAlerts: number, vendorName: string): boolean {
    return (
      orangeAlerts > 0 &&
      vendorName !== DefaultRestockSuggestionSupplier.AllSuppliers &&
      vendorName !== DefaultRestockSuggestionSupplier.MyWarehouse
    );
  }

  handleSyncShipment(): void {
    this.syncService
      .syncShipment(this.currentCompany)
      .pipe(this.autoCleanUp())
      .subscribe(() => {
        this.isProcessing = true;
      });
  }

  handlePrintStickers() {
    this.modalSer.open(PrintStickersModalComponent, {
      size: 'lg',
      data: {
        selectedRows: this.selectedRows,
        gridState: this.gridState,
      },
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  fullScreen(): void {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen && this.isSidebarCollapsed) {
      this.headerService.setNavbarCollapse(true);
    }

    toggleFullScreen(
      this.gridView['nativeElement'],
      this.neededToBeHiddenElements,
      () => {
        this.headerService.isFullScreen.next(this.isFullScreen);
        this.iconFullScreen = this.isFullScreen
          ? this.ICON_MINIMIZE
          : this.ICON_MAXIMIZE;

        this.calcGridHeight();
      },
      this.isFullScreen
    );
  }

  public loadGridItems(): void {
    // Set height after reload site.
    setTimeout(() => this.calcGridHeight(), 250);

    if (this.grid) {
      // when page first initializes this is false, don't die.
      this.grid.loading = true;
    }
    let items$ = this.getItems();

    if (this.advancedFilter) {
      this.loadGridItemsWithAdvancedFilter();

      return;
    }

    if (this.isCustom && this.itemName === GridName.ItemsInPo) {
      this.loadGridItemsOfCustomItemsInPo();
      return;
    }

    // Get count of items list
    let count$ = this.getCount();

    if (this.itemName === GridName.AllAvailableItems) {
      return this.loadAllAvailableItems(count$, items$);
    }

    items$ = this.transformItemsBeforeLoading(items$);

    // Get items and count together
    this.constructGridItems(count$, items$);
  }

  getItems() {
    let items$: Observable<T[] | any[]>;
    switch (this.itemName) {
      case GridName.ItemsInPo:
        if (this.isCustom) {
          return;
        }

        items$ =
          !this.isCustom &&
          this.isViewOnly &&
          this.selectingItem &&
          this.getSummaryByVendorDetail({
            vendorKey: this.selectingItem?.vendorKey,
            removedItemKeys: this.removedItemKeys,
            skip: this.gridState.skip,
            take: this.gridState.take,
            filter: this.filterState || this.gridState.filter,
            sort: this.gridState.sort,
            marketplaceId: this.currentCompany?.marketplaceId,
            currencyCode: this.currentCompany?.currencyCode,
          }).pipe(map(({ model }) => model));

        break;

      case GridName.ForecastShipmentItem:
        items$ = this.loadShipmentDetails(ShipmentDetailType.forecast);
        break;

      case GridName.AmazonShipmentItem:
        items$ = this.loadShipmentDetails(ShipmentDetailType.amazon);
        break;

      case GridName.ForecastShipmentItemChosen:
        items$ = this.loadShipmentDetails(ShipmentDetailType.forecastChosen);
        break;

      case GridName.ForecastShipmentItemValid:
        items$ = this.loadShipmentDetails(ShipmentDetailType.forecastValid);
        break;

      case GridName.PurchaseOrderItem:
        items$ = this.loadPoItem();
        break;

      case GridName.Bom:
        this.gridState.group = this.displayedGridState.group = [
          { field: 'parentName' },
        ];
        items$ = this.getItemFromResource();
        break;

      case GridName.ManageSupplier:
        this.gridState.group = this.displayedGridState.group = [
          { field: 'fullName' },
        ];
        items$ = this.getItemFromResource();
        break;

      case GridName.ItemSite:
        this.gridState.group = this.displayedGridState.group = [
          { field: 'itemName' },
        ];
        items$ = this.getItemFromResource();
        break;

      default:
        items$ = this.getItemFromResource();
        break;
    }

    return items$;
  }

  getCount() {
    let count$: Observable<number>;
    switch (this.itemName) {
      case GridName.ItemsInPo:
        count$ = this.summaryService
          .getCountByVendorKey(
            this.filterState || this.gridState.filter,
            this.selectingItem.vendorKey
          )
          .pipe(map((count) => count - this.removedItemKeys.length));
        break;

      case GridName.ForecastShipmentItem:
        count$ = this.loadShipmentDetailsCount(ShipmentDetailType.forecast);
        break;

      case GridName.AmazonShipmentItem:
        count$ = this.loadShipmentDetailsCount(ShipmentDetailType.amazon);
        break;

      case GridName.ForecastShipmentItemChosen:
        count$ = this.loadShipmentDetailsCount(
          ShipmentDetailType.forecastChosen
        );
        break;

      case GridName.ForecastShipmentItemValid:
        count$ = this.loadShipmentDetailsCount(
          ShipmentDetailType.forecastValid
        );
        break;

      case GridName.PurchaseOrderItem:
        count$ = this.loadPoCount();
        break;

      case GridName.ItemStickers:
        count$ = of(this.itemsKeySelected?.length || 0);
        break;

      case GridName.Demand:
      default:
        if (
          this.itemName === GridName.Demand &&
          this.currentCompany.summaryCounts?.countDemands > this.MAX_COUNT
        ) {
          count$ = of(this.MAX_COUNT);
          break;
        }

        const finalFilter = {
          filters: [
            ...this.gridState.filter.filters,
            ...(this.isCompanyTypeASC && this.removalOrdersFilter
              ? [this.removalOrdersFilter]
              : []),
          ],
          logic: Logic.and,
        };

        count$ = this.resourceService.getCount(
          this.filterState || finalFilter,
          '',
          this.currentCompany?.companyKey,
          this.itemsKeySelected,
          {
            marketplaceId: null,
            currencyCode: null,
            demandSourcePreference: this.currentCompany?.demandSourcePreference,
            isAWD: (this.itemName as GridName) === GridName.ShipmentAWD,
          }
        );
        break;
    }

    return count$;
  }

  getItemFromResource() {
    const finalFilter = {
      filters: [
        ...this.gridState.filter.filters,
        ...(this.isCompanyTypeASC && this.removalOrdersFilter
          ? [this.removalOrdersFilter]
          : []),
      ],
      logic: Logic.and,
    };
    return this.resourceService
      .getFilteredUserTelerikEvent(
        this.gridState.skip,
        this.gridState.take,
        this.filterState || finalFilter,
        this.gridState.sort,
        '',
        this.itemsKeySelected,
        {
          restockKey: null,
          marketplaceId: this.currentCompany?.marketplaceId,
          currencyCode: this.currentCompany?.currencyCode,
          demandSourcePreference: this.currentCompany?.demandSourcePreference,
          isAWD: this.itemName === GridName.ShipmentAWD,
        }
      )
      .pipe(
        this.autoCleanUp(),
        tap((items) => (this.items = items)),
        map((items) =>
          items?.map((item) => {
            if (this.itemName === GridName.PurchaseOrder) {
              const purchaseOrder = item as unknown as PurchaseOrder;
              purchaseOrder.createdAt = purchaseOrder.createdAt
                ? new Date(purchaseOrder.createdAt)
                : purchaseOrder.createdAt;

              purchaseOrder.updatedAt = purchaseOrder.updatedAt
                ? new Date(purchaseOrder.updatedAt)
                : purchaseOrder.updatedAt;
            }
            this.resizeCheckboxCol();
            return item;
          })
        )
      );
  }

  // Special case: Get items cached before to map state with the items table
  loadAllAvailableItems(
    count$?: Observable<number>,
    items$?: Observable<any[]>
  ): void {
    if (!count$) {
      combineLatest([
        this.summaryService.getSummariesAdvanced({
          type: this.advancedFilter,
          take: this.gridState.take,
          skip: this.gridState.skip,
          filter: this.filterState || this.gridState.filter,
          sort: this.gridState.sort,
          marketplaceId: this.currentCompany?.marketplaceId,
          currencyCode: this.currentCompany?.currencyCode,
        }),
        this.summaryService.getCachedPOItems(),
      ])
        .pipe(this.autoCleanUp())
        .subscribe(([items, poItems]) => {
          asapScheduler.schedule(() => {
            this.grid.loading = false;
          });

          this.isGridEmpty = !items.count;
          const groupPoItems = _.groupBy(poItems, 'itemKey');
          const mappedPOItems = items.model.map((item) => {
            return {
              ...item,
              inPO: groupPoItems[item.itemKey]?.[0],
            };
          });

          this.items = mappedPOItems;
          this.totalRecords = items.count;
          this.gridDataItems = { total: items.count, data: mappedPOItems };
        });

      return;
    }

    combineLatest([count$, items$, this.summaryService.getCachedPOItems()])
      .pipe(this.autoCleanUp())
      .subscribe(([total, items, poItems]) => {
        asapScheduler.schedule(() => {
          this.grid.loading = false;
        });

        this.isGridEmpty = !total;
        const groupPoItems = _.groupBy(poItems, 'itemKey');
        const mappedPOItems = (items as Array<POItem>)?.map((item) => {
          return {
            ...item,
            inPO: groupPoItems[item.itemKey] && groupPoItems[item.itemKey]?.[0],
          };
        });

        this.items = mappedPOItems;
        this.totalRecords = total;
        this.gridDataItems = { total, data: mappedPOItems };
      });
  }

  gridSelectByKey = (context: RowArgs): string => {
    if (this.itemName.includes('Shipment Item')) {
      return context.dataItem.itemKey;
    }

    switch (this.itemName) {
      case GridName.Demand:
      case GridName.Supply:
        return (
          context.dataItem.docType +
          '/' +
          context.dataItem.orderKey +
          '/' +
          context.dataItem.rowKey
        );

      case GridName.Bom:
        return context.dataItem.parentKey + '/' + context.dataItem.childKey;

      case GridName.ItemSite:
        return context.dataItem.itemKey + '/' + context.dataItem.key;

      default:
        return context.dataItem.key;
    }
  };

  onSelectionChange(args: SelectionEvent): void {
    // assuming 'key' is the column name for the primary key;

    // remove the rows
    args.deselectedRows.forEach((r: RowArgs) => {
      const key = this.getItemKey(r.dataItem);
      this.selectedRows.delete(key);
    });

    // add the rows.
    args.selectedRows.forEach((r: RowArgs) => {
      const key = this.getItemKey(r.dataItem);
      this.selectedRows.set(key, r.dataItem);
    });

    this.isDisabledPrintSticker = this.isDisabledDelete =
      this.selectedRows.size === 0;
  }

  public onVisibilityChange(e: ColumnVisibilityChangeEvent): void {
    this.columnChange.emit(e.columns);
    this.columnVisibilityArgs = e;
  }

  onColumnReorder(e): void {
    if (this.isPreventReorderColumn(e)) {
      e.preventDefault();
      return;
    }

    this.reorderSubject$.next(e);

    if (!this.isLockedByButton) {
      this.lockDelaySubscription$ = asapScheduler.schedule(() => {
        this.lockSubject$.next(null);
      }, 300);
    }
    this.isLockedByButton = false;
  }

  onColumnLocked(e): void {
    if (this.lockDelaySubscription$) {
      this.lockDelaySubscription$.unsubscribe();
    }

    this.lockSubject$.next(e);
  }

  private isPreventReorderColumn(e: ColumnReorderEvent): boolean {
    switch (this.itemName) {
      case GridName.Supplier:
      case GridName.Item:
      case GridName.ForecastShipmentItem:
      case GridName.Supply:
      case GridName.Demand:
      case GridName.AllAvailableItems:
      case GridName.ItemsInPo:
      case GridName.Bom:
      case GridName.ManageSupplier:
        return this.handlePreventReorderColumn(e);
      default:
        return false;
    }
  }

  cellAddressClick(content, selection) {
    this.modalAddressTitle = 'Edit Address';
    this.openAddrerssModal(content, selection);
  }

  deleteAddress() {
    this.addressData = this.addressData.filter((item) => {
      return item !== this.addressSelection?.dataItem;
    });
    this.modalAddressTitle = 'Add Address';
    this.modalReference.close();
    this.buildAddNewAddressForm(new Supplier());
  }

  openAddrerssModal(content: any, selection?: any): void {
    this.addressSelection = selection;

    const supplier = selection?.dataItem || new Supplier();
    this.supplierForm = this.formBuilder.group({
      country: [
        this.countries.find((c) => c.countryCode === supplier?.countryCode) ||
          this.countries[0],
        [Validators.required],
      ],
      addressLine1: [
        supplier?.addressLine1,
        [Validators.required, Validators.maxLength(180)],
      ],
      city: [supplier?.city, [Validators.required, Validators.maxLength(30)]],
      stateOrProvince: [supplier.stateOrProvinceCode],
      usState: [
        this.states.find(
          (c) => c.stateCode === supplier?.stateOrProvinceCode
        ) || this.states[0],
        [Validators.required],
      ],
      postalCode: [
        supplier?.postalCode,
        [Validators.required, Validators.maxLength(30)],
      ],
      phoneNumber: [
        supplier?.phoneNumber,
        [Validators.maxLength(40), Validators.pattern(PHONE_NUMMER_PATTERN)],
      ],
    });

    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  open(content: any): void {
    this.newItem = this.instantiateType(this.itemName);
    this.newForm = this.createFormGroup(
      this.newItem,
      false,
      this.forbiddenFields
    );

    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

    switch (this.itemName) {
      case GridName.ForecastShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
      case GridName.AmazonShipmentItem:
        this.newForm.setValidators([
          CustomValidators.divisibleBy(
            ShipmentDetailField.shipmentQty,
            ShipmentDetailField.caseQty,
            this.metaDataFields
          ),
          ...(this.isLocalQtyValidationDisabled
            ? []
            : [
                CustomValidators.lessThanOrEqual(
                  ShipmentDetailField.shipmentQty,
                  ShipmentDetailField.localQty,
                  this.metaDataFields
                ),
              ]),
        ]);
        this.newForm.updateValueAndValidity();
        break;

      case GridName.Demand:
        this.newForm.setValidators([
          CustomValidators.lessThanOrEqual(
            DemandField.openQty,
            DemandField.orderQty,
            this.metaDataFields
          ),
        ]);
        this.newForm.updateValueAndValidity();
        break;

      case GridName.Item:
      case GridName.Supplier:
        this.handleValueNewFormChange();
        break;

      case GridName.Supply:
        this.newForm.setValidators([
          CustomValidators.lessThanOrEqual(
            SupplyField.openQty,
            SupplyField.orderQty,
            this.metaDataFields
          ),
        ]);
        this.newForm.updateValueAndValidity();
        break;
    }
  }

  openSalesVelocityModal(
    dataItem: any,
    salesVelocitySettingsType: SalesVelocitySettingsType
  ) {
    this.modalSer.open(SalesVelocityModalComponent, {
      size: 'lg',
      data: {
        gridName: this.itemName,
        dataItem: dataItem,
        salesVelocitySettingsType,
      },
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  private instantiateType(typeName: string): any {
    let newItem: any;

    switch (typeName) {
      case GridName.Item:
        newItem = this.activator(Item);
        break;
      case GridName.Demand:
        newItem = this.activator(Demand);
        break;
      case GridName.Supply:
        newItem = this.activator(Supply);
        break;
      case GridName.Supplier:
        newItem = this.activator(Vendor);
        break;
      case GridName.ManageSupplier:
        newItem = this.activator(Supplier);
        break;
      case GridName.Bom:
        newItem = this.activator(Bom);
        break;
      case GridName.SuggestedPos:
        newItem = this.activator(SummaryByVendor);
        break;
      case GridName.ItemsInPo:
      case GridName.AllAvailableItems:
        newItem = this.activator(POItem);
        break;
      case GridName.ForecastShipmentItem:
      case GridName.AmazonShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        newItem = this.activator(ShipmentDetail);
        break;
      case GridName.SupplierList:
        newItem = this.activator(RestockSuggestionSupplier);
        break;
      case GridName.ItemSite:
        newItem = this.activator(ItemSite);
        break;
      case GridName.PurchaseOrder:
        newItem = this.activator(PurchaseOrder);
        break;
      case GridName.PurchaseOrderItem:
        newItem = this.activator(PurchaseOrderItem);
        break;
      default:
        throw new Error('Type name not accounted for.');
    }

    return newItem;
  }

  public isReadOnly(field: string, forcedValue?: boolean): boolean {
    if (forcedValue) {
      return forcedValue;
    }

    const currentCompanyType = this.companyService.currentCompany().companyType;
    switch (this.itemName) {
      case GridName.ItemsInPo:
      case GridName.AllAvailableItems:
        if (field === SummaryField.doNotOrder) {
          return false;
        }
        break;

      case GridName.Demand:
        // Only CSV co can edit Demand
        if (
          currentCompanyType === CompanyType.CSV ||
          field === DemandField.isHidden
        ) {
          break;
        }
        return true;

      case GridName.Supply:
        if (
          currentCompanyType === CompanyType.QBFS &&
          field === SupplyField.isHidden
        ) {
          return false;
        }
      case GridName.Bom:
        // Only CSV, ASC co can edit Supply and Kits
        if (
          currentCompanyType === CompanyType.CSV ||
          currentCompanyType === CompanyType.ASC
        ) {
          break;
        }
        return true;
    }

    if (this.isViewOnly) {
      return true;
    }

    const readOnlyColumns = [
      'itemKey',
      'parentKey',
      'childKey',
      ItemField.history,
      ItemField.links,
      ItemField.imageUrl,
      ItemField.awdInventoryQty,
      ItemField.inboundAwd,
      ShipmentField.nextStep,
      ShipmentField.options,
      ShipmentField.createdAt,
      ShipmentField.updatedAt,
      ShipmentField.restockType,
      ShipmentField.status,
      ItemField.itemHistoryLength,
      ItemField.tags,
      VendorField.averageHistoryLength,
      ...this.additionalReadOnlyCols,
    ];

    return readOnlyColumns.indexOf(field) > -1;
  }

  public saveItem(modelRef: NgbModalRef): void {
    if (!this.newForm.valid) {
      return;
    }

    const findBomFunc = (item) =>
      this.newItem.parentKey === item.parentKey &&
      this.newItem.childKey === item.childKey;

    Object.assign(this.newItem, this.newForm.getRawValue());
    let additionalKeys = {};

    switch (this.itemName) {
      case GridName.Item:
        this.fireWarehouseError();
        break;

      case GridName.Bom:
        additionalKeys = { type: AdditionalKeysType.createBom };

        if (this.items.find(findBomFunc)) {
          additionalKeys = { type: AdditionalKeysType.updateBom };
        }
        break;
      case GridName.PurchaseOrderItem:
      case GridName.Supply:
      case GridName.Demand:
        if (this.itemName == GridName.Supply && !this.newItem.vendorKey) {
          delete this.newItem.vendorKey;
          delete this.newItem.vendorName;
        }
        additionalKeys = { type: AdditionalKeysType.demandAndSupply };
        this.newItem.docType = '';
        this.newItem.orderKey = '';
        this.newItem.rowKey = '';
        break;

      case GridName.Supplier:
        if (this.isShowRestockAMZ && this.addressData?.length) {
          this.newItem.addressData = this.addressData;
        }
        break;

      case GridName.ItemSite:
        additionalKeys = { type: AdditionalKeysType.itemSite };
        break;

      case GridName.ForecastShipmentItem:
      case GridName.AmazonShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        this.newItem.restockKey = this.selectingItem?.key;
        break;
    }

    [
      'lotMultipleItemKey',
      'lotMultipleItemName',
      'lotMultipleQty',
      'vendorKey',
      'vendorName',
      'onHandMin',
      'onHandThirdPartyMin',
    ].forEach((field) => {
      if (this.newItem[field]) {
        return;
      }

      this.newItem[field] = conditionalOperator(
        ['onHandMin', 'onHandThirdPartyMin'].includes(field),
        '',
        null
      );
    });

    this.isDateConflict = this.onCheckDateConflict(
      this.newItem.dueDate,
      this.newItem.docDate
    );

    if (this.isDateConflict) {
      this.snotifyService.error(
        `The Receive Date must be greater than the PO Date`
      );
      return;
    }

    console.log('huh?')
    this.loading = true;
    this.resourceService
      .save(this.newItem, this.keyName, additionalKeys)
      .pipe(
        this.autoCleanUp(),
        switchMap(this.handleSaveItemWhichHasPassedShipmentOptions),
        tap(() => {
          modelRef.close('Successful Save');
          this.handleSaveItemFromModalSuccessful();
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  onCheckDateConflict(due: string, doc: string): boolean {
    if (!due || !doc) {
      return false;
    }

    const dueDate = new Date(due);
    const docDate = new Date(doc);
    return dueDate.getTime() < docDate.getTime();
  }

  public onStateChange(
    state: State,
    willSaveState: boolean = true,
    isClearClick = false
  ): void {
    if (this.grid?.loading || !this.grid) {
      return;
    }
    state.filter.filters = this.onHandleFilterCheckbox(state);
    if (this.currentSort) {
      state.sort = this.currentSort;
      this.currentSort = null;
    }

    state.sort = state.sort.filter((sorter) => sorter.dir);

    // Don't have change pagination event
    if (this.displayedGridState.skip === state.skip) {
      this.selectedRows.clear();
      this.isDisabledDelete = true;
    }

    this.displayedGridState = { ...state };

    this.applyFilters(this.searchFilters);

    [this.gridState, this.disabledExtraFields] = handleFilterWithDates(state);

    this.isFilter =
      !!this.gridState?.filter?.filters?.length ||
      !!this.gridState?.sort?.length ||
      !!this.advancedFilter ||
      !!this.removalOrdersFilter;

    if (willSaveState) {
      // Emit state to save it to user
      this.stateChange.emit({
        state,
        isClearClick,
        removalOrdersFilter: this.removalOrdersFilter,
      });
    }

    this.loadGridItems();
  }

  onSearch({
    filters,
    searchTerm,
  }: {
    filters: CompositeFilterDescriptor[];
    searchTerm: string;
  }): void {
    this.searchTerm = searchTerm;
    this.searchFilters = filters;
    this.gridState.skip = 0;

    this.searchChange.emit(searchTerm);

    this.applyFilters(filters);
    this.onStateChange(this.gridState);
  }

  onHandleFilterCheckbox(state: State): Array<any> {
    const checkboxStyle = this.selectedColumns
      .reduce(
        (acc, curr) => (curr.dataType === 'BOOLEAN' ? [...acc, curr] : acc),
        []
      )
      .map((checkbox) => checkbox.field);

    const currentState: Array<any> = state.filter.filters;
    currentState.forEach((rule) => {
      const currentFilter = rule.filters[0].field;
      const currentValueFilter = rule.filters[0].value;

      if (checkboxStyle.includes(currentFilter) && !currentValueFilter) {
        rule.logic = Logic.or;
        rule.filters.push({
          field: currentFilter,
          operator: 'eq',
          value: null,
        });
      }
    });

    return currentState;
  }

  public createFormGroup(
    dataItem: any,
    disableRequiredField = false,
    forbiddenFields: string[] = [],
    cellSelected: string = ''
  ): FormGroup {
    const formGroup = new FormGroup({});
    const dateObject = {};

    this.selectedColumns.forEach((mdf: MetaDataField) => {
      this.buildFormGroup(
        dataItem,
        disableRequiredField,
        forbiddenFields,
        cellSelected,
        formGroup,
        dateObject,
        mdf
      );
    });

    if (this.isShipmentDetail) {
      formGroup.addControl(
        ShipmentDetailField.shipmentId,
        this.formBuilder.control('', [])
      );
      formGroup.addControl(
        ShipmentDetailField.isShipByCase,
        this.formBuilder.control('', [])
      );

      formGroup.addControl(
        ShipmentDetailField.whoPreps,
        this.formBuilder.control('SELLER', [])
      );

      formGroup.addControl(
        ShipmentDetailField.shipmentId,
        this.formBuilder.control('', [])
      );
    }

    if (this.itemName === GridName.Supplier) {
      const restockModelMetadata = this.selectedColumns.find(
        (s) => s.field === VendorField.restockModel
      );
      dataItem.restockModel = conditionalOperator(
        dataItem.restockModel,
        dataItem.restockModel,
        restockModelMetadata?.displayControl?.selectSource?.[0]?.val
      );
    }

    formGroup.patchValue({ ...dataItem, ...dateObject });
    return formGroup;
  }

  private activator<T>(type: { new (): T }): T {
    return new type();
  }

  public downloadFile(onlyHeaders: boolean = true) {
    if (this.syncService.isItemDownloading(this.itemName)) {
      this.snotifyService.info(
        "The current item's download has already initiated."
      );
      return;
    }

    this.isExporting = true;
    const excludedFields = this.selectedColumns
      .filter((c) => c.excludedFromTemplate)
      .map((c) => c.field);

    let selectedColumns = this.grid.columns
      .filter((column) => {
        return (
          !column.hidden &&
          (!onlyHeaders ||
            (onlyHeaders &&
              !excludedFields.includes((column as ColumnComponent).field)))
        );
      })
      .map((c) => (c as ColumnComponent).field);

    selectedColumns = selectedColumns.filter((c) => {
      return c !== undefined && c !== ItemField.imageUrl;
    });

    let itemName = this.itemName as string;
    let shipmentDetailType;
    switch (this.itemName) {
      case GridName.AllAvailableItems:
      case GridName.ItemsInPo:
        itemName = 'summary';
        break;

      case GridName.ForecastShipmentItem:
        shipmentDetailType = ShipmentDetailType.forecast;
        itemName = 'shipment-detail';
        break;

      case GridName.AmazonShipmentItem:
        shipmentDetailType = ShipmentDetailType.amazon;
        itemName = 'shipment-detail';
        break;

      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        itemName = 'shipment-detail';
        break;

      case GridName.ItemSite:
        itemName = 'item-site';
        break;

      case GridName.PurchaseOrderItem:
        itemName = 'purchase-order-item';
        break;

      case GridName.ShipmentAWD:
        itemName = 'shipment-awd';
        break;

      default:
        break;
    }

    const sort = this.gridState.sort;
    const filter = this.filterState || this.gridState.filter;

    const notification = new BehaviorSubject<any>(0);
    const downloadSnotify = this.snotifyService.async(
      'Start download file',
      notification
    );
    setTimeout(() => {
      notification.next({ body: 'Downloading.....' });
    }, 1000);
    this.snotifyService.info(
      'You can do another action when generating file!',
      { timeout: 5000 }
    );
    const payload = {
      itemName: itemName,
      fields: selectedColumns,
      headersOnly: onlyHeaders,
      sortAttributes: sort,
      whereClause: filter,
      currencyCode: this.displayCurrency.code,
      marketPlaceId: this.currentCompany.marketplaceId,
      summaryOnHandType: this.currentCompany?.summaryOnHandType,
    };
    this.syncService
      .downloadFile(
        payload,
        this.selectingItem?.vendorKey,
        {
          restockKey: this.restockKey || this.selectingItem?.key,
          shipmentDetailType,
          restockType: this.selectingItem?.restockType,
          gridType: this.shouldLoadGrid ? GridType.ShipmentReview : undefined,
          purchaseOrderKey: this.purchaseOrderKey,
        },
        this.removedItemKeys,
        this.advancedFilter
      )
      .pipe(first())
      .subscribe(
        (response) => {
          if (response?.status === 202) {
            this.handleDownloadLargeFile(notification);
            return;
          }

          notification.next({
            title: 'Success',
            body: 'File downloaded!',
            config: {
              closeOnClick: true,
              timeout: 5000,
              showProgressBar: true,
              type: 'success',
            },
          });
          notification.complete();
          this.syncService.removeDownloadingItem(this.itemName);
          this.isExporting = false;
          const headers: HttpHeaders = response.headers;

          const dispostion: string = headers.get('Content-Disposition') || '';
          const matches = /filename=([^;]+)/gi.exec(dispostion);
          const fileName = (matches[1] || 'untitled')
            .replace('"', '')
            .replace('"', '')
            .trim();

          const a = document.createElement('a');
          const blob = response.body;
          const url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download =
            itemName === 'summary' ? `${this.downloadFileName}.csv` : fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        },
        () => {
          notification.complete();
          this.snotifyService.remove(downloadSnotify.id, true);
          this.syncService.removeDownloadingItem(this.itemName);
          this.isExporting = false;
        }
      );
  }

  public downloadInitialTemplate(): void {
    let itemName =
      this.itemName === GridName.ItemSite ? 'item-site' : this.itemName;

    this.syncService.downloadInitialTemplate(itemName).subscribe((res) => {
      const headers: HttpHeaders = res.headers;

      const dispostion: string = headers.get('Content-Disposition') || '';
      const matches = /filename=([^;]+)/gi.exec(dispostion);
      const fileName = (matches[1] || 'untitled')
        .replace('"', '')
        .replace('"', '')
        .trim();

      const a = document.createElement('a');
      const blob = res.body;
      const url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  public downloadData(isXlsx: boolean = false): void {
    const date = formatDate(new Date(), "MM-dd-yyyy_HH-mm-aaaaa'm'", 'en');
    const itemName = this.itemName
      .split(' ')
      .map((word) => word.toLowerCase())
      .join('_');
    const fileName = this.isCustom
      ? `custom-${itemName}-${date}`
      : `my_suggested-${itemName}-${date}`;

    this.downloadFileName = isXlsx ? `${fileName}.xlsx` : fileName;
  }

  public uploadFile() {
    const modalRef = this.modalService.open(FileUploadModalComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.importer = this.importer;
    modalRef.componentInstance.currentCompany =
      this.companyService.currentCompany();
    modalRef.componentInstance.currentUser = this.authService.currentUser;
    modalRef.componentInstance.fileType = this.itemName.toLowerCase();
    modalRef.componentInstance.fileUploadCompleted
      .pipe(
        takeUntil(this.unsubscribe),
        concatMap((fileDetails: FileDetails) => {
          fileDetails.fileType = this.itemName.toLowerCase();
          this.grid.loading = true;
          return this.syncService.uploadFile(fileDetails);
        })
      )
      .subscribe(
        () => {
          this.processFileNotification();
        },
        () => {}
      );

    modalRef.result.then((isImportOpened) => {
      if (!isImportOpened || !this.grid || this.isImporterReady) {
        return;
      }

      this.grid.loading = true;
    });
  }

  public undoLastUpload() {
    this.grid.loading = true;

    this.syncService
      .undoLastUpload(this.itemName)
      .pipe(this.autoCleanUp())
      .subscribe(
        () => {
          this.remainingUndoTime = 0;
          this.isShowUndoToast = true;
        },
        () => {
          this.grid.loading = false;
        }
      );
  }

  private extractValidators(
    mdf: MetaDataField,
    disableRequiredField: boolean,
    dataItem: any,
    cellSelected: string = ''
  ): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (!disableRequiredField && mdf.required) {
      //if field is required then add validators
      validators.push(Validators.required);
    }

    if (!mdf.validators || mdf.validators.length === 0) {
      return validators;
    }

    //loop through validators array, and add to form field.
    mdf.validators.forEach((validator: IFieldValidator) => {
      switch (validator.type) {
        case FieldValidatorType.MinMax:
          let minMax = validator as MinMaxValidator;

          validators.push(Validators.min(minMax.min));
          validators.push(Validators.max(minMax.max));
          break;
        case FieldValidatorType.Pattern:
          let pattern = validator as CustomPatternValidator;
          validators.push(Validators.pattern(pattern.pattern));
          break;
        case FieldValidatorType.Email:
          validators.push(Validators.email);
          break;
        case FieldValidatorType.LessMore:
          const lessMoreVali = validator as LessMoreValidator;
          validators.push(lessMoreVali.condition);
          break;
      }
    });

    return validators;
  }

  public deleteItems(): void {
    let displayList: string = '';

    this.selectedRows.forEach((value) => {
      switch (true) {
        case !!value['name']:
          displayList += `${value['name']}<br />`;
          break;

        case !!value['itemName'] && !!value['warehouseName']:
          displayList += `${value['itemName']} | ${value['warehouseName']}<br />`;
          break;

        case !!value['itemName']:
          displayList +=
            this.itemName === GridName.ItemSite
              ? `${value['itemName']} | (All Warehouses)<br />`
              : `${value['itemName']}<br />`;
          break;

        case !!value['parentName'] && !!value['childName']:
          displayList += `${value['parentName']} | ${value['childName']}<br />`;
          break;

        case !!value['parentName']:
          displayList += `${value['parentName']} | (All Children)<br />`;
          break;
      }
    });

    displayList = `<div style="overflow:auto;max-height:150px;">${displayList}</div>`;

    Swal.fire({
      title: 'Are you sure?',
      html: `<div >The following ${this.finalItemName}(s) will be deleted:<br /> ${displayList}</div>`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((willDelete) => {
      //if not dismissed.
      if (willDelete.dismiss) {
        return;
      }

      this.grid.loading = true;
      const deleteIds: string[] = [];
      this.selectedRows.forEach((value, key) => {
        if (this.itemName === GridName.ManageSupplier && value['parentName']) {
          const gridResult = this.grid.data as any;
          let items = gridResult.data.find((item) => {
            return item.value === value['parentName'];
          }).items;

          items.forEach((item) => {
            deleteIds.push(item.key);
          });
        } else {
          deleteIds.push(key);
        }
      });

      this.resourceService
        .deleteHideMultiple(deleteIds, this.itemName)
        .pipe(this.autoCleanUp())
        .subscribe(
          (failToDeleteItems) => {
            if (failToDeleteItems?.length) {
              this.displayErrorMessage(failToDeleteItems, 'delete');
            } else {
              this.snotifyService.success(
                'The selected items has been deleted.'
              );
            }

            this.selectedRows.clear();
            this.loadGridItems();
            this.isDisabledDelete = true;

            if (this.itemName !== GridName.Demand) {
              return;
            }

            this.hasOrdersReachLimit =
              (this.selectedPlan?.allowOrders &&
                this.currentCompany?.latestTotalOrder >=
                  this.selectedPlan?.allowOrders &&
                this.isSubActive) ||
              !this.selectedPlan;
          },
          (err) => {
            // something catastrophic must have happened.
            this.selectedRows.clear();
            this.loadGridItems();
            this.isDisabledDelete = true;

            if (!err.statusText) {
              this.snotifyService.error(
                'There was a problem deleting the selected items.'
              );
            }
          }
        );
    });
  }

  public hideItems(): void {
    const displayList = this.buildToBeHiddenItemList();

    Swal.fire({
      title: 'Are you sure?',
      html: `<div>This action will not remove ${this.itemName.toLocaleLowerCase()}(s) from your data source.
      Are you sure that you would like to hide the selected ${this.itemName.toLocaleLowerCase()}(s) below?:<br/> <br/>
      ${displayList}</div>`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((willDelete) => {
      // if not dismissed.
      if (!willDelete.dismiss) {
        this.grid.loading = true;

        const hideIds: string[] = [];
        this.selectedRows.forEach((value, key) => {
          hideIds.push(key);
        });

        this.resourceService
          .deleteHideMultiple(hideIds, this.itemName, true)
          .pipe(this.autoCleanUp())
          .subscribe(
            (failToHideItems) => {
              if (failToHideItems?.length) {
                this.displayErrorMessage(failToHideItems, 'hide');
              } else {
                this.calculateHasActiveItemsReachLimit().subscribe(() => {
                  this.snotifyService.success(
                    'The selected items has been hidden.'
                  );
                });
              }

              this.selectedRows.clear();
              this.loadGridItems();
              this.isDisabledDelete = true;
            },
            (err) => {
              this.isDisabledDelete = true;
              // something catastrophic must have happened.
              this.selectedRows.clear();
              this.loadGridItems();

              if (!err.statusText) {
                this.snotifyService.error(
                  'There was a problem hiding the selected items.'
                );
              }
            }
          );
      }
    });
  }

  onHandSync(): void {
    this.currentCompany.isManualInventory =
      !this.currentCompany.isManualInventory;

    this.companyService.updateCompanyInfo(this.currentCompany).subscribe();
  }

  displayErrorMessage(errors: any[], type: string): void {
    let displayList: string = '';

    errors?.forEach((error) => {
      Object.keys(error).forEach((key, index, arr) => {
        if (error[key]) {
          displayList += `${_.startCase(key)}: ${error[key]}`;

          if (index !== arr.length - 1) {
            displayList += ' - ';
          }
        }
      });
      displayList += '<br />';
    });

    displayList = `<div style="overflow:auto;height:150px;">${displayList}</div>`;

    let title;
    let htmlContent;
    switch (type) {
      case 'delete':
        title = 'Error Deleting...';
        htmlContent = `<div >The following ${this.itemName}(s) could not be deleted:<br /> ${displayList}</div>`;
        break;

      case 'hide':
        title = 'Error Hiding...';
        htmlContent = `<div >The following ${this.itemName}(s) could not be hidden:<br /> ${displayList}</div>`;
        break;
    }

    Swal.fire({
      title,
      html: htmlContent,
      type: 'error',
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: false,
    });
  }

  public cellCloseHandler(args: any): void {
    const { formGroup, dataItem, column, rowIndex } = args;
    const oldDataItem = { ...dataItem };
    this.editingRowIndex = rowIndex;

    if (!formGroup?.controls[column.field].valid) {
      // prevent closing the current edited cell if it has invalid values
      args.preventDefault();
      return;
    }

    if (!formGroup.dirty) {
      return;
    }
    Object.assign(dataItem, formGroup.value);
    this.grid.loading = true;
    this.saveInProgress = true;

    if (
      this.isFieldBoxWeightAndDimensions(column.field) &&
      this.isSelectedKeyBoxWeightAndDimensions(dataItem['key'], column.field)
    ) {
      this.updateMultipleRow(column.field, dataItem);
      return;
    }

    this.updateRow(args, oldDataItem);
  }

  updateRow(args, oldDataItem) {
    const { dataItem, column, sender } = args;

    let allowSave = true;
    let additionalKeys = {};

    // Check if bom already exist
    // Use the already updated bom because `this.items` has been changed
    // If new data only changes quantity, we will update the bom
    switch (this.itemName) {
      case GridName.ItemStickers:
        this.itemsSticker.map((item) =>
          conditionalOperator(item.key === dataItem.key, dataItem, item)
        );
        this.itemsStickerChange.emit(this.itemsSticker);
        this.grid.loading = false;
        this.saveInProgress = false;
        return;

      case GridName.Bom:
        additionalKeys = this.bomAdditionalKeysGenerate(dataItem, oldDataItem);

        // Assign args with new data to save
        Object.assign(args, { dataItem: dataItem });
        break;

      case GridName.Item:
        this.markDirtyItem(dataItem, oldDataItem);

        //if use picked 'selection...' from inventory source dropdown, set the value to null, which is allowed value in the database.
        dataItem.inventorySourcePreference = conditionalOperator(
          dataItem.inventorySourcePreference === '',
          null,
          dataItem.inventorySourcePreference
        );

        dataItem.lotMultipleItemKey = dataItem.lotMultipleItemKey || null;
        dataItem.lotMultipleItemName = dataItem.lotMultipleItemName || null;

        allowSave = this.itemSaveReachLimitHandle(
          dataItem,
          oldDataItem,
          sender
        );
        break;

      case GridName.PurchaseOrderItem:
        additionalKeys = { type: AdditionalKeysType.demandAndSupply };
        break;

      case GridName.Supply:
        allowSave = this.supplySaveInvalidDateHandle(dataItem, sender);
        additionalKeys = { type: AdditionalKeysType.demandAndSupply };
        break;

      case GridName.Demand:
        dataItem.asin = this.currentAsin;
        additionalKeys = { type: AdditionalKeysType.demandAndSupply };
        break;

      case GridName.ItemSite:
        additionalKeys = { type: AdditionalKeysType.itemSite };
        break;

      case GridName.Vendor:
        // When hide a vendor => hide all items by this vendor
        allowSave = this.hideVendorHandle(
          column,
          dataItem,
          args,
          additionalKeys,
          sender
        );
        break;
      case GridName.Shipment:
      case GridName.ShipmentAWD:
        allowSave = this.shipmentSaveInvalidDateHandle(dataItem, oldDataItem);
        break;

      case GridName.ForecastShipmentItem:
      case GridName.AmazonShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        const invalidCaseQtyGreaterShipQty =
          (dataItem.caseQty && !dataItem.shipmentQty) ||
          dataItem.caseQty > dataItem.shipmentQty;
        const invalidShipQtyNotDivisibleCaseQty =
          dataItem.caseQty && dataItem.shipmentQty % dataItem.caseQty !== 0;
        if (invalidCaseQtyGreaterShipQty || invalidShipQtyNotDivisibleCaseQty) {
          allowSave = false;
          this.grid.loading = false;
          this.saveInProgress = false;

          Object.assign(dataItem, this.originalValue);
          asyncScheduler.schedule(() => {
            sender.closeCell();
          });
        }

        switch (true) {
          case invalidCaseQtyGreaterShipQty:
            this.snotifyService.error(
              'Case quantity cannot be greater than ship quantity'
            );
            break;

          case invalidShipQtyNotDivisibleCaseQty:
            this.snotifyService.error(
              'Shipment quantity must be divisible by case quantity'
            );
            break;
        }

        dataItem.isShipByCase = dataItem.caseQty > 0;
        this.casePackChecked.emit(dataItem.caseQty > 0);
        break;
    }

    if (allowSave) {
      this.saveData(args, additionalKeys);
    }
  }

  saveData(args: any, additionalKeys: any): void {
    const { dataItem, column, sender } = args;

    this.sanitizeToBeSavedItem(dataItem);
    //for now we will assume "key" is the primary key/

    this.resourceService
      .save(dataItem, this.keyName, additionalKeys)
      .pipe(
        this.autoCleanUp(),
        switchMap((res: T) => {
          if (this.itemName !== GridName.Demand) {
            return of(res);
          }

          // Get latest total orders
          return this.companyService
            .getById(this.currentCompany.companyKey, true)
            .pipe(map(() => res));
        }),
        switchMap((res: T) => {
          if (
            this.itemName === GridName.AmazonShipmentItem ||
            !this.selectingItem?.stepProgress?.shipmentOptions ||
            this.itemName === GridName.PurchaseOrderItem
          ) {
            return of(res);
          }

          this.selectingItem.stepProgress.shipmentOptions = false;
          this.selectingItem.stepProgress.shipmentReview = false;
          this.selectingItem.stepProgress.complete = false;
          return this.shipmentService.save(this.selectingItem, 'key').pipe(
            tap(() => this.updateShipmentItemAfterSubmitted.emit()),
            map(() => res)
          );
        }),
        switchMap((res: T) => {
          if (this.itemName !== GridName.PurchaseOrderItem || this.isSticker) {
            return of(res);
          }

          let additionalOptions = {
            marketplaceId: this.currentCompany?.marketplaceId,
            currencyCode: this.displayCurrency?.code,
          };

          return this.purchaseOrderService
            .save(this.selectingItem, 'key', {}, additionalOptions)
            .pipe(map(() => res));
        }),
        switchMap((res: T) => {
          const vendor$: Observable<Vendor> = conditionalOperator(
            this.itemName === GridName.Item && dataItem.vendorKey,
            this.vendorService.getById(dataItem.vendorKey),
            of(null)
          );

          return forkJoin([of(res), vendor$]);
        })
      )
      .subscribe(
        ([res, vendor]) => {
          this.grid.loading = false;
          this.saveInProgress = false;
          this.editingRowIndex = null;

          Object.assign(dataItem, res);

          switch (this.itemName) {
            case GridName.Item:
              if ([ItemField.lotMultipleItemName].includes(column.field)) {
                this.saveAndRemindToRunForecast();
              }
              break;

            case GridName.Supplier:
              if (
                [
                  VendorField.leadTime,
                  VendorField.orderInterval,
                  VendorField.serviceLevel,
                  VendorField.restockModel,
                ].includes(column.field)
              ) {
                this.saveAndRemindToRunForecast();
              }
              break;

            case GridName.ForecastShipmentItem:
              this.reloadShipment.emit();
              break;

            case GridName.Demand:
              this.hasOrdersReachLimit =
                (this.selectedPlan?.allowOrders &&
                  this.currentCompany?.latestTotalOrder >=
                    this.selectedPlan?.allowOrders &&
                  this.isSubActive) ||
                !this.selectedPlan;
              break;

            case GridName.PurchaseOrderItem:
              this.reloadPo.emit();
              break;
          }

          let dataItemName: string;
          if (this.itemName === GridName.PurchaseOrderItem) {
            dataItemName = dataItem['itemName'];
          } else {
            dataItemName = dataItem['name'];
          }
          let name = conditionalOperator(
            !!dataItemName,
            dataItemName,
            this.itemName
          );

          this.snotifyService.success(
            `${
              name === 'Supply' ? 'Purchase Order' : name
            } updated successfully.`
          );

          const isColumnSorted = this.gridState.sort?.some(
            (s) => s.field === column.field
          );
          // Bom grid has group so it need to reload when cell has just been updated
          if (
            [GridName.Bom, GridName.ItemSite].includes(this.itemName) ||
            isColumnSorted
          ) {
            this.loadGridItems();
          }

          if (
            this.itemName === GridName.Supply &&
            dataItem.refNum !== this.originalValue['refNum']
          ) {
            const gridData = this.grid.data as GridDataResult;
            this.grid.data = {
              total: gridData.total || 0,
              data: gridData.data.map((item) =>
                dataItem.orderKey === item.orderKey
                  ? { ...item, refNum: dataItem.refNum }
                  : item
              ),
            };
          }
        },
        (err) => {
          console.log('err', err);
          args.preventDefault();
          this.grid.loading = false;
          this.saveInProgress = false;

          // restore original value, if there was an error from the serve.
          Object.assign(dataItem, this.originalValue);

          asyncScheduler.schedule(() => {
            sender.closeCell();
          });
        }
      );
  }

  public cellClickHandler({
    sender,
    rowIndex,
    column,
    columnIndex,
    dataItem,
    isEdited,
  }) {
    const metadataField = this.selectedColumns.find(
      (c) => c.field === column.field
    );

    if (this.getIsCellUnclickable(dataItem, column.field)) {
      return;
    }

    const forcedIsReadOnly = dataItem?.syncedFields?.includes(column?.field);

    if (
      !column.field ||
      isEdited ||
      this.saveInProgress ||
      this.isReadOnly(column.field, forcedIsReadOnly) ||
      metadataField.displayControl.input === DisplayControlInput.Checkbox
    ) {
      this.handleRowClick({ column, dataItem, sender, columnIndex });
      return;
    }

    const editForm = this.createFormGroup(
      dataItem,
      false,
      [],
      metadataField.field
    );

    switch (this.itemName) {
      case GridName.ForecastShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        this.newItem = this.instantiateType(this.itemName);
        editForm.setValidators(
          this.isLocalQtyValidationDisabled
            ? []
            : [
                CustomValidators.lessThanOrEqualStatic(
                  ShipmentDetailField.shipmentQty,
                  ShipmentDetailField.localQty,
                  dataItem.localQty,
                  this.metaDataFields
                ),
              ]
        );
        editForm.updateValueAndValidity();
        break;
      case GridName.PurchaseOrderItem:
        this.newItem = this.instantiateType(this.itemName);
        editForm.setValidators([
          CustomValidators.lessThanOrEqual(
            PurchaseOrderItemField.openQty,
            PurchaseOrderItemField.orderQty,
            this.metaDataFields
          ),
          CustomValidators.greaterThanOrEqual(
            PurchaseOrderItemField.orderQty,
            PurchaseOrderItemField.openQty,
            this.metaDataFields
          ),
        ]);
        editForm.updateValueAndValidity();
        break;
      case GridName.Demand:
        this.newItem = this.instantiateType(this.itemName);
        editForm.setValidators([
          CustomValidators.lessThanOrEqual(
            DemandField.openQty,
            DemandField.orderQty,
            this.metaDataFields
          ),
          CustomValidators.greaterThanOrEqual(
            DemandField.orderQty,
            DemandField.openQty,
            this.metaDataFields
          ),
        ]);
        editForm.updateValueAndValidity();
        break;

      case GridName.Supply:
        this.newItem = this.instantiateType(this.itemName);
        editForm.setValidators([
          CustomValidators.lessThanOrEqual(
            SupplyField.openQty,
            SupplyField.orderQty,
            this.metaDataFields
          ),
          CustomValidators.greaterThanOrEqual(
            SupplyField.orderQty,
            SupplyField.openQty,
            this.metaDataFields
          ),
        ]);
        editForm.updateValueAndValidity();
        break;
    }

    sender.editCell(rowIndex, columnIndex, editForm);
    //store the original just in case we need to fall back.
    //Just like object.assign, this copies only properties,
    //arrays and nested objects are copied by reference.
    this.originalValue = _.clone(dataItem);
    this.handleRowClick({ column, dataItem, sender, columnIndex });
  }

  isEmpty(input) {
    return input.replace(/\s/g, '') === '';
  }

  getMin(col: MetaDataField): number {
    const minMaxValidator = col.validators.find((v: IFieldValidator) => {
      return v.type === FieldValidatorType.MinMax;
    });

    let min = Number.MIN_VALUE;

    if (minMaxValidator) {
      min = (minMaxValidator as MinMaxValidator).min;
    }

    return min;
  }

  getMax(col: MetaDataField): number {
    const minMaxValidator = col.validators.find((v: IFieldValidator) => {
      return v.type === FieldValidatorType.MinMax;
    });

    let max = Number.MAX_VALUE;

    if (minMaxValidator) {
      max = (minMaxValidator as MinMaxValidator).max;
    }

    return max;
  }

  getLessThan(col: MetaDataField): string {
    const lessMoreValidator = col.validators.find((v: IFieldValidator) => {
      return v.type === FieldValidatorType.LessMore;
    });

    if (lessMoreValidator) {
      const compareField = (lessMoreValidator as LessMoreValidator).fieldName;
      const compareFieldName = this.metaDataFields.find(
        (item) => item.field === compareField
      ).displayName;

      return `${col.displayName} must be less than or equal to ${compareFieldName}`;
    }
  }

  resolveFilterType(fieldType: string): string {
    let filterType = 'text';

    switch (fieldType) {
      case MetaDataFieldType.Date:
      case MetaDataFieldType.DateOnly:
        filterType = 'date';
        break;
      case MetaDataFieldType.Decimal:
      case MetaDataFieldType.Integer:
        filterType = 'numeric';
        break;
      case MetaDataFieldType.Boolean:
        filterType = 'boolean';
        break;
      case MetaDataFieldType.String:
      case MetaDataFieldType.UUID:
        break;
    }

    return filterType;
  }

  // Clear all sorts and filters
  clearAll(): void {
    this.gridState.sort = [];
    this.gridState.filter = {
      logic: Logic.and,
      filters: [],
    };
    this.removalOrdersFilter = null;

    this.advancedFilter = null;

    this.onStateChange(this.gridState, true, true);

    this.clearClick.emit();
    this.clearClicked$.next();
  }

  // Enable child lookup input in add modal
  enableChild({ should, parentKey }): void {
    this.isChildEnabled = should;
    this.lookupParentKey = parentKey;
  }

  selectGroup(group, groupCheckBoxRef): void {
    if (this.itemName === GridName.ItemSite) {
      const itemKey = encodeURIComponent(group?.items?.[0]?.itemKey);
      if (groupCheckBoxRef.checked) {
        this.selectedRows.set(itemKey, {
          itemKey,
          itemName: group.value,
        } as unknown as T);
      } else {
        this.selectedRows.delete(itemKey);
      }

      this.isDisabledDelete = this.selectedRows.size === 0;
      return;
    }

    const parentKey = encodeURIComponent(
      group?.items?.[0]?.parentKey || group?.items?.[0]?.key
    );
    if (groupCheckBoxRef.checked) {
      this.selectedRows.set(parentKey, {
        parentKey,
        parentName: group.value,
      } as unknown as T);
    } else {
      this.selectedRows.delete(parentKey);
    }

    this.isDisabledDelete = this.selectedRows.size === 0;
  }

  onDetailPO(data: { dataItem: POItem; modalType: ModalType }): void {
    this.detailClick.emit(data);
  }

  onInventoryItem(sku: string): void {
    window.open(
      amazonSkuCentralLink(sku, this.currentCompany?.marketplaceId),
      '_blank'
    );
  }

  onSaleVelocityCalculation(
    dataItem: Item | Summary,
    isTotalComponentDataOn = false
  ) {
    const modalRef = this.modalService.open(SaleVelocityCalculationComponent, {
      size: 'xl' as string,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.choosenItem = {
      ...dataItem,
      sku: (dataItem as Summary).itemName,
    };
    modalRef.componentInstance.salesVelocitySettingsType =
      SalesVelocitySettingsType.purchasing;
    modalRef.componentInstance.isTotalComponentDataOn = isTotalComponentDataOn;
    modalRef.result.then((updatedDailySalesRate) => {
      if (!updatedDailySalesRate?.hasAdjustedToggleChanged) {
        return;
      }

      const { itemKey, suggReorder } = updatedDailySalesRate || {};
      const gridData = this.grid.data as GridDataResult;
      this.gridDataItems = {
        total: gridData.total,
        data: gridData.data.map((i) => {
          if (i.itemKey !== itemKey) {
            return i;
          }

          if (
            [GridName.AllAvailableItems, GridName.ItemsInPo].includes(
              this.itemName
            )
          ) {
            i.trueRecommendedQty = suggReorder;
          }
          return i;
        }),
      };
    });
  }

  onAmazonItem(asin: string, forcedMarketplaceId?: string): void {
    window.open(
      amazonProductLink(
        asin,
        forcedMarketplaceId || this.currentCompany?.marketplaceId
      ),
      '_blank'
    );
  }

  onHistory(dataItem: any): void {
    this.isModalOpended = true;

    const modalRef = this.modalService.open(ViewOfHistoryComponent, {
      size: 'xl' as string,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.choosenItem = dataItem;
    modalRef.componentInstance.currentCompany = this.currentCompany;
    modalRef.closed
      .pipe(
        this.autoCleanUp(),
        tap(() => (this.isModalOpended = false))
      )
      .subscribe();
    modalRef.dismissed
      .pipe(
        this.autoCleanUp(),
        tap(() => (this.isModalOpended = false))
      )
      .subscribe();
  }

  getAmazonUrl(): string {
    let url = 'amazon.com';
    const marketplaceId = this.currentCompany?.marketplaceId;

    switch (marketplaceId) {
      case 'A2EUQ1WTGCTBG2':
        url = 'amazon.ca';
        break;

      case 'A1AM78C64UM0Y8':
        url = 'amazon.com.mx';
        break;

      case 'A1PA6795UKMFR9':
        url = 'amazon.de';
        break;

      case 'A1RKKUPIHCS9HS':
        url = 'amazon.es';
        break;

      case 'A13V1IB3VIYZZH':
        url = 'amazon.fr';
        break;

      case 'APJ6JRA9NG5V4':
        url = 'amazon.it';
        break;

      case 'A1F83G8C2ARO7P':
        url = 'amazon.co.uk';
        break;

      case 'A21TJRUUN4KGV':
        url = 'amazon.in';
        break;

      case 'A1VC38T7YXB528':
        url = 'amazon.jp';
        break;

      case 'AAHKV2X7AFYLW':
        url = 'amazon.com.cn';
        break;

      default:
        break;
    }

    return url;
  }

  // Get disabled columns in sorting and menu
  checkSortMenuColumns(metaDataField: MetaDataField): boolean {
    if (metaDataField?.restrictFilterAndSort) {
      return false;
    }

    const field = metaDataField?.field;

    return (
      field !== ItemField.history &&
      field !== ItemField.links &&
      field !== ItemField.imageUrl &&
      field !== SummaryField.details &&
      field !== SummaryField.removeAll &&
      field !== ShipmentField.nextStep &&
      field !== ShipmentField.options &&
      field !== RestockSuggestionSupplierField.flag
    );
  }

  checkFilterMenuColumns(metaDataField: MetaDataField): boolean {
    return (
      !metaDataField?.restrictFilterAndSort ||
      ![AdvancedFilter.active, AdvancedFilter.inactive].includes(
        this.advancedFilter
      )
    );
  }

  // Set width column of some special columns to scroll the grid table
  setWidthColumn(metaDataField: MetaDataField): number {
    if (this.isPrintList) {
      return 0;
    }

    switch (this.itemName) {
      case GridName.Item:
        switch (metaDataField.field) {
          case ItemField.history:
            return ColumnWidth.itemHistory;

          case ItemField.links:
            return ColumnWidth.itemLinks;

          case ItemField.isHidden:
          case ItemField.moq:
            return ColumnWidth.itemIsHidden;

          case ItemField.description:
          case ItemField.name:
            return ColumnWidth.itemDesc;

          case ItemField.imageUrl:
            return ColumnWidth.itemImage;

          case ItemField.vendorName:
            return 200;

          case ItemField.vendorPrice:
            return 220;

          case ItemMetricField.s7d:
          case ItemMetricField.s30d:
          case ItemMetricField.s90d:
          case ItemMetricField.s365d:
            return ColumnWidth.itemMetric;

          default:
            return ColumnWidth.default;
        }

      case GridName.ItemsInPo:
      case GridName.AllAvailableItems:
        switch (metaDataField.field) {
          case SummaryField.details:
            return ColumnWidth.poDetails;

          case SummaryField.description:
            return ColumnWidth.poDesc;

          case SummaryField.asin:
            return ColumnWidth.poAsin;

          case SummaryField.imageUrl:
            return ColumnWidth.itemImage;

          default:
            return ColumnWidth.default;
        }

      case GridName.ForecastShipmentItem:
      case GridName.AmazonShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        switch (metaDataField.field) {
          case ShipmentDetailField.itemName:
          case ShipmentDetailField.hasSticker:
          case ShipmentDetailField.cost:
          case ShipmentDetailField.fnsku:
            return ColumnWidth.shipmentDetailMedium;

          case ShipmentDetailField.shipmentQty:
          case ShipmentDetailField.caseQty:
          case ShipmentDetailField.description:
            return ColumnWidth.shipmentDetailLarge;

          case ShipmentDetailField.imageUrl:
            return ColumnWidth.itemImage;

          default:
            return ColumnWidth.default;
        }

      case GridName.Shipment:
      case GridName.ShipmentAWD:
        switch (metaDataField.field) {
          case ShipmentField.shipmentName:
            return ColumnWidth.shipmentName;
          case ShipmentField.receivedQty:
          case ShipmentField.requestedQty:
          case ShipmentField.destinationFulfillmentCenterId:
          case ShipmentField.totalCost:
            return ColumnWidth.shipmentMedium;
          case ShipmentField.status:
            return ColumnWidth.shipmentStatus;
          case ShipmentField.nextStep:
            return ColumnWidth.shipmentNextStep;
          case ShipmentField.options:
            return ColumnWidth.shipmentOptions;
          case ShipmentField.orderNotes:
            return ColumnWidth.shipmentOrderNotes;
          default:
            return ColumnWidth.default;
        }

      default:
        return ColumnWidth.default;
    }
  }

  // Set condition of cell in some columns of summary by vendor
  checkDataSummaryByVendor(
    dataItem: SummaryByVendor,
    metaDataField: MetaDataField
  ): boolean {
    if (this.itemName === GridName.SuggestedPos) {
      switch (metaDataField.field) {
        case SummaryByVendorField.vendorName:
          return (
            metaDataField.field === SummaryByVendorField.vendorName &&
            !dataItem[metaDataField.field]
          );

        case SummaryByVendorField.earliestDueDate:
          const currentDate = new Date();
          const earliestDueDate = new Date(dataItem[metaDataField.field]);
          return earliestDueDate <= currentDate;

        default:
          break;
      }
    }
  }

  checkRestockByVendor(dataItem: RestockSuggestionSupplier): boolean {
    if (this.itemName === GridName.SupplierList) {
      return dataItem.vendorName === null;
    }
  }

  // Prevent reorder some special columns in grid
  setColReorder(metaDataField: MetaDataField): boolean {
    switch (metaDataField?.field) {
      case ItemField.history:
      case ItemField.links:
      case ItemField.imageUrl:
      case SummaryField.details:
        return false;

      default:
        return true;
    }
  }

  // Remove an item and reload grid table
  removeItem(dataItem: POItem): void {
    if (this.isCustom) {
      this.rowClick.emit({ ...dataItem, inPO: false });
    } else {
      this.grid.loading = true;

      if (
        !this.removedItemKeys.find((itemKey) => itemKey === dataItem.itemKey)
      ) {
        this.removedItemKeys.push(dataItem.itemKey);
        this.removedItemInPOIds.emit(this.removedItemKeys);
      }

      forkJoin([
        this.getSummaryByVendorDetail({
          vendorKey: this.selectingItem?.vendorKey,
          removedItemKeys: this.removedItemKeys,
          skip: this.gridState.skip,
          take: this.gridState.take,
          filter: this.filterState || this.gridState.filter,
          sort: this.gridState.sort,
          marketplaceId: this.currentCompany?.marketplaceId,
          currencyCode: this.currentCompany?.currencyCode,
        }),
        this.summaryService.getCountByVendorKey(
          this.filterState || this.gridState.filter,
          this.selectingItem.vendorKey
        ),
      ]).subscribe(([{ model }, count]) => {
        this.grid.loading = false;
        this.dataChange.emit(model);

        this.totalRecords = count - this.removedItemKeys.length;
        this.gridDataItems = {
          total: count - this.removedItemKeys.length,
          data: model,
        };
      });
    }
  }

  // Remove all items and reload grid table
  removeAll(): void {
    if (this.isCustom) {
      this.summaryService.saveItemsToCache([]);
    } else {
      this.removedItemKeys = [];
      this.removedItemInPOIds.emit(this.removedItemKeys);
      const emptyData = [];
      this.dataChange.emit(emptyData);

      this.gridDataItems = { total: 0, data: emptyData };
    }
  }

  // Add all item to POs
  addAll(): void {
    if (!this.items.length) {
      return;
    }

    this.summaryService.saveItemsToCache(this.items?.filter((x) => !x.inPO));
  }

  // Add an item to POs
  toggleItem(dataItem: POItem): void {
    dataItem.inPO = !dataItem.inPO;
    this.rowClick.emit(dataItem);
  }

  get isAddAllVisible(): Observable<boolean> {
    return this.summaryService.getCachedPOItems().pipe(
      this.autoCleanUp(),
      map((poItems) => {
        if (!this.items || !this.items.length) {
          return false;
        }

        return !this.items.every(
          (i: POItem) =>
            poItems.findIndex((po) => po.itemKey === i.itemKey) > -1
        );
      })
    );
  }

  // Remove icons, buttons column out of export
  getExportColumn(metaDataField: MetaDataField): boolean {
    if (metaDataField.includedInExport) {
      return false;
    }

    return (
      !metaDataField?.initialUpload ||
      metaDataField.field === ItemField.links ||
      metaDataField.field === ItemField.history ||
      metaDataField.field === ItemField.imageUrl ||
      metaDataField.field === SummaryField.details ||
      metaDataField.field === SummaryField.removeAll
    );
  }

  ensureGroupCheckboxIsClicked(isChecked: boolean): void {
    let currentRow = this.groupCheckbox?.nativeElement?.closest('tr');

    while (currentRow?.previousElementSibling) {
      currentRow = currentRow?.previousElementSibling;
    }

    while (currentRow) {
      const rowCheckbox = currentRow?.getElementsByClassName('k-checkbox')?.[0];

      if (isChecked) {
        if (!rowCheckbox?.checked) {
          rowCheckbox?.click();
        }
      } else {
        if (rowCheckbox?.checked) {
          rowCheckbox?.click();
        }
      }

      currentRow = currentRow?.nextElementSibling;
    }
  }

  getTooltip(field: string): string {
    return getTip(field, this.currentCompany?.companyType, this.itemName);
  }

  private getSummaryByVendorDetail(state: {
    vendorKey: string;
    removedItemKeys: string[];
    skip: number;
    take: number;
    filter: any;
    sort: SortDescriptor[];
    marketplaceId?: string;
    currencyCode?: string;
  }): Observable<ISummaryResponse> {
    return this.summaryService.getItemsBySummaryVendor(state).pipe();
  }

  calcGridHeight() {
    const gridEl = this.grid?.wrapper?.nativeElement as HTMLElement;
    const { top } = gridEl?.getBoundingClientRect() || {};

    const calculatedHeight = window.innerHeight - top - 25;

    this.gridHeight = this.isFullScreen
      ? window.innerHeight - top + 20
      : Math.max(calculatedHeight, this.DEFAULT_MINIMUM_GRID_HEIGHT);

    switch (this.itemName) {
      case GridName.ItemStickers:
        this.gridHeight = 300;
        break;

      case GridName.AmazonShipmentItem:
        if (this.isViewOnly) {
          this.gridHeight = 650;
        }

        break;

      default:
        break;
    }
  }

  setDefaultMinimumGridHeight() {
    this.gridHeight = this.DEFAULT_MINIMUM_GRID_HEIGHT;
  }

  private resizeCheckboxCol(): void {
    // Double click the resize span to resize the checkbox column
    // which in turn make the checkbox column align correctly in Edit Kits
    if (
      ![GridName.Bom, GridName.ManageSupplier, GridName.ItemSite].includes(
        this.itemName
      )
    ) {
      return;
    }

    const resizeSpan =
      this.checkboxSelectAll?.nativeElement?.nextElementSibling;

    resizeSpan?.dispatchEvent(new MouseEvent('dblclick'));
  }

  private calculateHasActiveItemsReachLimit(): Observable<any> {
    if (this.itemName !== GridName.Item && this.itemName !== GridName.Demand) {
      return of(null);
    }

    let $count: Observable<number>;

    $count =
      this.itemName === GridName.Demand &&
      this.currentCompany.summaryCounts?.countDemands > this.MAX_COUNT
        ? of(this.MAX_COUNT)
        : this.resourceService.getCount(
            {
              filters: [],
              logic: Logic.and,
            },
            '',
            this.currentCompany?.companyKey,
            [],
            {
              marketplaceId: null,
              currencyCode: null,
              demandSourcePreference:
                this.currentCompany?.demandSourcePreference,
              isAWD: (this.itemName as GridName) === GridName.ShipmentAWD,
            }
          );

    if (
      this.gridState.filter.filters?.length &&
      this.currentCompany.summaryCounts.countDemands
    ) {
      $count = of(this.currentCompany.summaryCounts.countDemands);
    }

    return forkJoin([
      $count,
      this.billingService.getPlans(),
      this.billingService.getSubscriptionByCompanyId(
        this.currentCompany?.companyKey
      ),
      this.billingService.getPaymentMethods(this.currentCompany?.customerId),
    ]).pipe(
      this.autoCleanUp(),
      switchMap(([totalActiveItems, { plans }, subscription, paymentMethods]) =>
        iif(
          () => !!subscription,
          this.billingService
            .getAltPlan(subscription?.plan?.id)
            .pipe(
              map((altPlan) => [
                totalActiveItems,
                { plans: plans.concat(altPlan) },
                subscription,
                paymentMethods,
              ])
            ),
          of([totalActiveItems, { plans }, subscription, paymentMethods])
        )
      ),
      tap(([totalActiveItems, { plans }, subscription, paymentMethods]) => {
        this.selectedPlan = plans.find((p) => p.id === subscription?.plan?.id);
        this.isSubActive =
          subscription?.status === SubscriptionStatus.active &&
          !!paymentMethods?.length;

        this.hasActiveItemsReachLimit =
          (this.itemName === GridName.Item &&
            this.selectedPlan?.allowItems &&
            totalActiveItems >= this.selectedPlan?.allowItems &&
            this.isSubActive) ||
          !this.selectedPlan;

        this.hasOrdersReachLimit =
          (this.itemName === GridName.Demand &&
            this.selectedPlan?.allowOrders &&
            this.currentCompany?.latestTotalOrder >=
              this.selectedPlan?.allowOrders &&
            this.isSubActive) ||
          !this.selectedPlan;
      })
    );
  }

  allDataPOItemsCustom = (): Observable<GridDataResult> => {
    // Use this without binding
    return this.summaryService.getCachedPOItems().pipe(
      first(),
      this.autoCleanUp(),
      map((poItems) => {
        // Convert tags to string
        poItems.forEach((poItem) => {
          if (poItem.tags && Array.isArray(poItem.tags)) {
            poItem.tags = poItem.tags.join(',');
          }
        });

        return process(poItems, {
          sort: this.gridState.sort,
          filter: this.filterState || this.gridState.filter,
        }) as GridDataResult;
      })
    );
  };

  handleGridViewChange(e: IGridViewChangeEvent): void {
    const {
      selectedGridView,
      columns,
      gridState,
      searchTerm,
      removalOrdersFilter,
    } = e;
    this.removalOrdersFilter = removalOrdersFilter;
    this.userSelectedGridView = selectedGridView;
    this.gridViewChange.emit({
      selectedGridView,
      columns,
      gridState,
      searchTerm,
      removalOrdersFilter,
    });
    this.searchTerm = searchTerm;
    this.currentSort = gridState.sort;
    this.grid.loading = false;
    this.onStateChange(gridState, false);
  }

  handleCheckboxClick(e, dataItem, column) {
    if (this.getIsCellUnclickable(dataItem, column?.field)) {
      e.preventDefault();
      return;
    }

    const checked = e.target.checked;
    if (checked === dataItem[column.field]) {
      return;
    }

    dataItem[column.field] = checked;
    const oldDataItem = { ...dataItem };

    switch (this.itemName) {
      case GridName.ItemsInPo:
      case GridName.AllAvailableItems:
        this.grid.loading = true;
        this.itemService
          .getById(dataItem.itemKey)
          .pipe(
            this.autoCleanUp(),
            switchMap((item) => {
              item.doNotOrder = checked;
              item.isHidden = item.doNotRestock && item.doNotOrder;
              return this.itemService.save(
                {
                  ...item,
                },
                'key'
              );
            })
          )
          .subscribe((res) => {
            this.grid.loading = false;
            Object.assign(dataItem, res);

            const name = dataItem['itemName'];
            this.snotifyService.success(`${name} updated successfully.`);
            this.loadGridItems();
          });
        return;
      case GridName.ForecastShipmentItem:
      case GridName.AmazonShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        this.isShipByCaseCheckboxHandle(checked, dataItem, column, e);
    }

    switch (column.field) {
      case ItemField.useBackfill:
        e.target.checked = !e.target.checked;
        this.onHistory(dataItem);
        break;

      case VendorField.isHidden:
        if (this.itemName === GridName.Supplier && checked) {
          this.altModalService.open(VendorHiddenModalComponent, {
            data: { vendor: dataItem },
            backdrop: 'static',
            keyboard: false,
            onSuccess: (failToHideItems) => {
              this.grid.loading = false;
              this.saveDataForCheckbox(
                e,
                checked,
                dataItem,
                { ...dataItem, isHidden: true },
                column
              );
              if (failToHideItems?.length) {
                this.displayErrorMessage(failToHideItems, 'hide');
              }
            },
            onError: () => {
              dataItem[column.field] = !dataItem[column.field];
              e.target.checked = !e.target.checked;
              this.grid.loading = false;
              this.saveInProgress = false;
            },
          });
          break;
        }
        this.saveDataForCheckbox(
          e,
          checked,
          dataItem,
          dataItem,
          column,
          oldDataItem
        );
        break;

      default:
        this.saveDataForCheckbox(
          e,
          checked,
          dataItem,
          dataItem,
          column,
          oldDataItem
        );
    }
  }

  // This must be an arrow function so it can access the component variable and functions
  determineGridClass = (args: RowClassArgs) => {
    if (
      !this.isLocalQtyValidationDisabled &&
      [
        GridName.ForecastShipmentItem,
        GridName.ForecastShipmentItemChosen,
        GridName.ForecastShipmentItemValid,
      ].includes(this.itemName) &&
      args?.dataItem?.shipmentQty > args?.dataItem?.localQty
    ) {
      return 'invalid';
    }

    if (this.isPrintList) {
      return 'minified';
    }

    const alreadyLinkedToShipmentCondition =
      this.itemName === GridName.Supply && args?.dataItem?.shipmentKey;
    if (alreadyLinkedToShipmentCondition) {
      return 'k-disabled';
    }

    return {};
  };

  isIncludeInChooser(metaDataField: MetaDataField) {
    // "includedInChooser" can be true/false/undefined
    // If "includedInChooser" has not been set (undefined), isIncludeInChooser will rely on "required"
    return metaDataField.includedInChooser ?? !metaDataField.required;
  }

  refreshSingleShipment(dataItem = null): void {
    this.grid.loading = true;
    this.shipmentService
      .refreshSingleShipment(this.selectingItem?.key || dataItem.key)
      .pipe(this.autoCleanUp())
      .subscribe((shipment) => {
        this.headerService.loadShipmentSubject.next();

        if (dataItem) {
          const gridResult = this.grid.data as any;
          let updatedItems = gridResult.data.map((i) =>
            dataItem.key === i.key
              ? {
                  ...shipment,
                  purchaseOrderKey: i.purchaseOrderKey,
                  refNum: i.refNum,
                }
              : i
          );

          this.gridDataItems = {
            total: gridResult.total,
            data: updatedItems || [],
          };
          this.grid.loading = false;
        }
      });
  }

  // Shipment options:
  onGoToDetail(item: Shipment, isAddNew = false) {
    this.rowClick.emit({ item, isAddNew });
  }
  onAddItem(item: Shipment) {
    this.onGoToDetail(item, true);
  }

  onGoToAwsInventory(item: Shipment) {
    switch (this.itemName) {
      case GridName.Shipment:
        window.open(
          `https://sellercentral.amazon.com/fba/inbound-shipment/summary/${
            item?.shipmentConfirmationId || item?.shipmentId
          }`,
          '_blank'
        );
        break;

      case GridName.ShipmentAWD:
        window.open(
          `https://sellercentral.amazon.com/awd/inbound-shipment/${
            item?.shipmentConfirmationId || item?.shipmentId
          }`,
          '_blank'
        );
        break;
    }
  }

  deletePo(item: Shipment) {
    Swal.fire({
      title: 'Are you sure?',
      html: `<div>The PO item <b>${item.shipmentName}</b> will be deleted</div>`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((willDelete) => {
      //if not dismissed.
      if (!willDelete.dismiss) {
        this.grid.loading = true;

        this.shipmentService
          .delete(item.key)
          .pipe(this.autoCleanUp())
          .subscribe(
            (failToDeleteItem) => {
              if (failToDeleteItem) {
                this.displayErrorMessage(failToDeleteItem, 'delete');
              } else {
                this.snotifyService.success(
                  'The selected item has been deleted.'
                );
              }

              this.selectedRows.clear();
              this.loadGridItems();
              this.isDisabledDelete = true;
            },
            () => {
              // something catastrophic must have happened.
              this.isDisabledDelete = true;
              this.selectedRows.clear();
              this.loadGridItems();
              this.snotifyService.error(
                'There was a problem deleting the selected items.'
              );
            }
          );
      }
    });
  }

  onCreateShipment(shipment: Shipment = null) {
    if (shipment?.restockType === RestockType.Supplier) {
      this.router.navigate(['/dashboard', 'shipments', 'restock-suggestions'], {
        relativeTo: this.route,
        queryParams: {
          ['createNew']: shipment?.key || this.selectingItem.key,
        },
      });

      return;
    }

    this.router.navigate([
      '/dashboard',
      'shipments',
      'create',
      shipment?.key || this.selectingItem.key,
    ]);
  }

  onOpenPrintView(item: Shipment) {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ShipmentPickListViewComponent, {
      size: 'xl' as string,
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.shipmentKey = item.key;
  }

  onOpenPDF(item: PurchaseOrder) {
    this.openPoPDF.emit(item);
  }

  onModifyUnits(): void {
    this.originalItems = _.clone(this.items);
    this.bulkEditFormArray.clear();
    this.bulkEditAllowedFields = [
      ShipmentDetailField.caseQty,
      ShipmentDetailField.shipmentQty,
      ShipmentDetailField.notes,
    ];

    this.newItem = this.instantiateType(this.itemName);
    this.items.forEach((i) => {
      const formGroup = this.createFormGroup(i, false, []);
      formGroup.setValidators([
        CustomValidators.divisibleBy(
          ShipmentDetailField.shipmentQty,
          ShipmentDetailField.caseQty,
          this.metaDataFields
        ),
        CustomValidators.lessThanOrEqual(
          ShipmentDetailField.caseQty,
          ShipmentDetailField.shipmentQty,
          this.metaDataFields
        ),
        CustomValidators.casePackStateUnchanged(),
      ]);
      formGroup.updateValueAndValidity();
      this.bulkEditFormArray.push(formGroup);
    });

    this.editAllRows();
    this.isModifyingUnits = true;
  }

  onModifyUnitsSave(): void {
    // mark all fields as touched to highlight any invalid fields
    this.bulkEditForm.markAllAsTouched();

    if (this.bulkEditForm.invalid) {
      return;
    }

    this.items.forEach((i, idx) => {
      const newValue = this.bulkEditFormArray.value[idx];
      this.items[idx] = {
        ...i,
        ...newValue,
      };
    });

    this.grid.loading = true;
    this.shipmentDetailService
      .updateMultipleShipmentItems(this.items)
      .pipe(
        this.autoCleanUp(),
        finalize(() => {
          this.closeAllRows();
          this.isModifyingUnits = false;
          this.grid.loading = false;
        })
      )
      .subscribe(
        () => {
          const gridResult = this.grid.data as any;
          this.gridDataItems = { data: this.items, total: gridResult.total };
          this.snotifyService.success('Modify successful');
          this.reloadShipment.emit();
        },
        () => {
          this.items = this.originalItems;
          const gridResult = this.grid.data as any;
          this.gridDataItems = { data: this.items, total: gridResult.total };
        }
      );
  }

  onModifyUnitsCancel(): void {
    this.closeAllRows();
    this.items = this.originalItems;

    const gridResult = this.grid.data as any;
    this.gridDataItems = { data: this.items, total: gridResult.total };

    this.isModifyingUnits = false;
  }

  onDeleteShipment(): void {
    Swal.fire({
      title: 'Delete Shipment?',
      html: `<div>Please confirm that you would like to delete this shipment on Seller Central</div>`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((willDelete) => {
      //if not dismissed.
      if (!willDelete.dismiss) {
        this.isDeleting = true;

        this.shipmentService
          .refreshSingleShipment(this.selectingItem?.key)
          .pipe(
            this.autoCleanUp(),
            switchMap(() =>
              this.shipmentService.modifyShipment(this.selectingItem?.key, {
                ...this.selectingItem,
                status: ShipmentStatus.DELETED,
              })
            )
          )
          .subscribe(
            (res: Shipment) => {
              this.snotifyService.success('The shipment has been deleted.');
              this.selectingItem = res;
              this.headerService.loadShipmentSubject.next();
              this.isDeleting = false;
              this.isModifyUnitsVisible = false;
            },
            () => {
              // something catastrophic must have happened.
              this.snotifyService.error(
                'There was a problem deleting the shipment.'
              );
              this.isDeleting = false;
            }
          );
      }
    });
  }

  checkShipmentDetail(): boolean {
    return (
      this.itemName === GridName.ForecastShipmentItem ||
      this.itemName === GridName.ForecastShipmentItemChosen ||
      this.itemName === GridName.ForecastShipmentItemValid ||
      this.itemName === GridName.AmazonShipmentItem
    );
  }

  private editAllRows(): void {
    this.items.forEach((i, idx) => {
      this.grid.editRow(idx, this.bulkEditFormArray.controls[idx] as FormGroup);
    });
  }

  private closeAllRows(): void {
    this.items.forEach((i, idx) => {
      this.grid.closeRow(idx);
    });
    this.bulkEditAllowedFields = [];
  }

  changeCreatedDate() {
    this.shipmentService
      .getById(this.selectingItem?.key)
      .pipe(this.autoCleanUp())
      .subscribe((shipment) => {
        const modalRef = this.modalService.open(
          ChangeCreatedDateModalComponent,
          {
            ariaLabelledBy: 'modal-basic-title',
            backdrop: 'static',
            keyboard: false,
          }
        );
        modalRef.componentInstance.shipment = shipment;
      });
  }

  openEditTagsModal(tagRow: T): void {
    const indexRow = this.selectedTagRows.findIndex(
      (row) => row[this.keyName] === tagRow[this.keyName]
    );
    if (indexRow === -1) {
      this.selectedTagRows.push(tagRow);
      this.selectedKeyTag = _.groupBy(this.selectedTagRows, this.keyName);
    }

    const modalRef = this.modalService.open(EditTagsModalComponent, {
      size: 'lg' as string,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.selectedTagRows = this.selectedTagRows;
    modalRef.componentInstance.resourceService = this.resourceService;
    modalRef.componentInstance.itemName = this.itemName;
    modalRef.componentInstance.selectedKeyTag = this.selectedKeyTag;
    modalRef.componentInstance.saveTagClicked.subscribe((dataItems) => {
      this.selectedTagRows = dataItems;
      const gridDataResult = this.grid.data as GridDataResult;

      this.gridDataItems = {
        total: gridDataResult.total,
        data: gridDataResult.data.map((d) => {
          const item = dataItems.find((dataItem) => dataItem.key === d.key);
          return item ? item : d;
        }),
      };

      if (this.checkboxSelectAllTag?.nativeElement?.checked) {
        this.checkboxSelectAllTag.nativeElement.checked = false;
      }
      this.selectAllTagRows(false);
    });
  }

  selectTagRows(tagRow: T, addRow: boolean): void {
    if (addRow) {
      this.selectedTagRows.push(tagRow);
    } else {
      this.selectedTagRows = this.selectedTagRows.filter(
        (row) => row[this.keyName] !== tagRow[this.keyName]
      );
    }
    this.selectedKeyTag = _.groupBy(this.selectedTagRows, this.keyName);
  }

  selectAllTagRows(addRow: boolean): void {
    this.selectedTagRows = addRow ? this.items : [];
    this.selectedKeyTag = addRow ? _.groupBy(this.items, this.keyName) : [];
  }

  getFlagClass(field: string): string {
    let flagClass =
      'fa fa-flag forecast-table__flag-icon forecast-table__flag-icon';

    switch (field) {
      case RestockSuggestionSupplierField.orangeAlerts:
        flagClass += '--restock-now';
        break;

      case RestockSuggestionSupplierField.yellowAlerts:
        flagClass += '--restock-soon';
        break;

      case RestockSuggestionSupplierField.redAlerts:
        flagClass += '--out-of-stock';
        break;

      case RestockSuggestionSupplierField.tealAlerts:
        flagClass += '--new';
        break;

      case RestockSuggestionSupplierField.greenAlerts:
        flagClass += '--well-stocked';
        break;

      default:
        flagClass = null;
    }

    return flagClass;
  }

  loadAdditionalFilters(): void {
    this.searchFilters = this.searchTerm
      ? [
          {
            logic: Logic.or,
            filters: this.fieldsToLookUp.map((f) => ({
              field: f,
              operator: 'contains',
              value: this.searchTerm,
            })),
          },
        ]
      : [];
  }

  applyFilters(filters: CompositeFilterDescriptor[]): void {
    this.filterState = filters?.length
      ? {
          logic: Logic.and,
          filters: this.gridState.filter.filters.concat(filters),
        }
      : null;
  }

  loadShipmentDetails(
    shipmentDetailType: ShipmentDetailType
  ): Observable<any[] | T[]> {
    return this.shipmentDetailService.getShipmentDetailsByRestockKey(
      this.restockKey || this.selectingItem?.key,
      shipmentDetailType,
      {
        skip: this.gridState.skip,
        take: this.gridState.take,
        filter: this.filterState || this.gridState.filter,
        sort: this.gridState.sort,
      },
      RestockType.Supplier,
      this.currentCompany?.marketplaceId,
      this.currentCompany?.currencyCode
    );
  }

  loadShipmentDetailsCount(
    shipmentDetailType: ShipmentDetailType
  ): Observable<number> {
    return this.shipmentDetailService.getShipmentDetailsCountByRestockKey(
      this.restockKey || this.selectingItem?.key,
      shipmentDetailType,
      this.filterState || this.gridState.filter
    );
  }

  toggleAmazonLinkDropdown(rowId) {
    const toggler = document.getElementById(rowId);
    toggler.click();
  }

  processFileNotification(): void {
    this.pusherService.userChannel.bind('notification', (data) => {
      if (
        this.isModalOpended ||
        data.key === NotificationKey.undoUploadStatus
      ) {
        return;
      }

      switch (data.type) {
        case 'close':
          Swal.fire({
            html: `
              <div >
                Upload file successfully
              </div>
            `,
            type: 'success',
            showCloseButton: false,
            allowOutsideClick: false,
            cancelButtonText: this.isUploadUndoable && 'Undo Last Upload',
            reverseButtons: true,
            showCancelButton: this.isUploadUndoable ? true : false,
          }).then((res) => {
            if (res?.value) {
              this.calculateUndoLeftTime();
              this.loadGridItems();
            }

            if (res?.dismiss) {
              this.isUploadUndoable && this.undoLastUpload();
            }
          });

          this.selectedRows.clear();
          this.grid.loading = false;
          break;
        case 'notice':
          if (!this.isImportFailModalOpen) {
            this.isImportFailModalOpen = true;
            this.isImportFailModalOpenChange.emit(this.isImportFailModalOpen);

            const isCsvFile = data.req.body.fileName.includes('.csv');
            if (!isCsvFile) {
              data.msg = {
                ...data.msg,
                error: ['Invalid file type. Please import the CSV file.'],
              };
            }

            this.modalService.dismissAll();
            this.altModalService.open(ImportFailModalComponent, {
              data: { message: data },
              backdrop: 'static',
              keyboard: false,
              onError: () => {
                this.isImportFailModalOpen = false;
                this.isImportFailModalOpenChange.emit(
                  this.isImportFailModalOpen
                );
              },
            });
            this.loadGridItems();
          }

          this.grid.loading = false;
          break;
      }
    });
  }

  setCsvboxImporterDynamicColumns(columnsGroupedByField): void {
    const dynamicColumns = [];
    this.companyService.currentCompany().companyType === CompanyType.ASC
      ? dynamicColumns.push({
          column_name: 'ASIN-SKU',
          matching_keywords: 'ASIN-SKU',
          type: 'text',
        })
      : dynamicColumns.push({
          column_name: 'Item Key',
          matching_keywords: 'Item Key',
          type: 'text',
        });

    [
      ItemField.onHand,
      ItemField.onHandMin,
      ItemField.onHandThirdParty,
      ItemField.onHandThirdPartyMin,
    ].forEach((field) => {
      const [metadata] = columnsGroupedByField[field] || [];
      dynamicColumns.push({
        column_name: metadata.displayName,
        info_hint: this.getTooltip(metadata.field),
        matching_keywords: metadata.displayName,
        type: 'number',
      });
    });
    if (this.companyService.currentCompany().companyType === CompanyType.ASC) {
      [ItemField.asin, ItemField.fnsku].forEach((field) => {
        const [metadata] = columnsGroupedByField[field] || [];
        dynamicColumns.push({
          column_name: metadata.displayName,
          info_hint: this.getTooltip(metadata.field),
          matching_keywords: metadata.displayName,
          type: 'text',
          required: true,
        });
      });

      const [onHandFbmMetadata] =
        columnsGroupedByField[ItemField.onHandFbm] || [];
      dynamicColumns.push({
        column_name: onHandFbmMetadata.displayName,
        info_hint: this.getTooltip(onHandFbmMetadata.field),
        matching_keywords: onHandFbmMetadata.displayName,
        type: 'number',
      });

      const [inventorySourcePreferenceMetadata] =
        columnsGroupedByField[ItemField.inventorySourcePreference] || [];
      dynamicColumns.push({
        column_name: inventorySourcePreferenceMetadata.displayName,
        info_hint: this.getTooltip(inventorySourcePreferenceMetadata.field),
        matching_keywords: inventorySourcePreferenceMetadata.displayName,
        type: 'text',
      });
    }
    this.importer.setDynamicColumns(dynamicColumns);
  }

  loadCsvboxImporter(): void {
    const companyType = this.currentCompany.companyType;
    let type = '';
    if (companyType === CompanyType.ASC) {
      type = 'asc';
    }

    if (companyType === CompanyType.CSV) {
      type = 'csv';
    }

    if (companyType === CompanyType.QBFS) {
      type = 'qb';
    }

    if (
      this.bulkMngtIsVisible &&
      [
        GridName.Item,
        GridName.Supplier,
        GridName.Demand,
        GridName.Supply,
        GridName.Bom,
        GridName.ItemSite,
      ].includes(this.itemName)
    ) {
      this.importer = new CSVBoxImporter(
        environment.csvboxLicenseKeys[type][this.itemName.toLowerCase()],
        {},
        () => {},
        { lazy: true }
      );
      this.importer.setDynamicColumns(this.dynamicColumnsForCsvBox);
      this.importer.listen('onSubmit', (data) => {
        this.grid.loading = true;
        const uploadedColumns = data?.column_mappings
          ?.map((m) => {
            const [key] = Object.keys(m) || [];
            return m[key];
          })
          ?.filter((m) => m);

        if (uploadedColumns?.length) {
          this.companyService
            .updateCompanyInfo({
              companyType: this.companyService.currentCompany().companyType,
              companyKey: this.companyService.currentCompany().companyKey,
              uploadedColumns,
            })
            .pipe(this.autoCleanUp())
            .subscribe(() => {
              this.processFileNotification();
            });
        }
      });

      this.importer.listen('onReady', () => {
        this.isImporterReady = true;
        if (this.grid) {
          this.grid.loading = false;
        }
      });
    }
  }

  saveAndRemindToRunForecast(): void {
    this.companyService
      .updateCompanyInfo({
        ...this.currentCompany,
        willRemindToRunForecast: true,
      })
      .pipe(this.autoCleanUp())
      .subscribe((c) =>
        this.companyService.updateCacheCompany({
          ...c,
          isAuthorized: this.currentCompany.isAuthorized,
        })
      );
  }

  getIsolatedSummaryValue(dataItem: any, field: string) {
    return dataItem?.isolatedValues?.[field];
  }

  getItemSiteTotal(group: any) {
    if (this.itemName !== GridName.ItemSite) {
      return '';
    }

    return ` - Total: ${group?.items?.[0]?.total || 0}`;
  }

  private loadAdditionalData() {
    this.forbiddenFields = this.forbiddenFields.concat([
      ItemField.isHidden,
      ItemField.createdAt,
      ItemField.useBackfill,
      ItemField.useHistoryOverride,
      ItemField.imageUrl,
      ItemField.itemHistoryLength,
      ItemMetricField.s7d,
      ItemMetricField.s30d,
      ItemMetricField.s90d,
      ItemMetricField.s365d,
      VendorField.averageHistoryLength,
    ]);

    if (this.itemName === GridName.Supplier) {
      this.hiddenColumns = [
        VendorField.phoneNumber,
        VendorField.addressLine1,
        VendorField.addressLine2,
        VendorField.city,
        VendorField.countryCode,
        VendorField.postalCode,
        VendorField.stateOrProvinceCode,
      ];

      if (!this.isShowRestockAMZ) {
        this.forbiddenFields = this.forbiddenFields.concat([
          VendorField.phoneNumber,
          VendorField.countryCode,
          VendorField.addressLine1,
          VendorField.addressLine2,
          VendorField.stateOrProvinceCode,
          VendorField.postalCode,
          VendorField.city,
        ]);
      }
    }
  }

  private initializeGridState() {
    if (
      this.initGridState &&
      !this.specificItemId &&
      this.initGridState.filter
    ) {
      this.initGridState.filter.filters =
        this.initGridState.filter?.filters.filter((f) => Boolean(f));
      // Convert date string in filters into Date object
      const dateFilters = this.initGridState.filter?.filters.filter((fltr) =>
        ['Date', 'created', 'updated'].some((cond) =>
          fltr['filters']?.[0]?.field?.includes(cond)
        )
      );

      dateFilters?.forEach((filter: any) => {
        filter.filters.map((fltr) => {
          fltr.value = new Date(fltr.value);

          return fltr;
        });
      });

      this.displayedGridState = _.cloneDeep(this.initGridState);
      if (
        this.itemName !== GridName.ItemStickers &&
        this.displayedGridState.take >= 50
      ) {
        this.displayedGridState.take = 20;
      }

      [this.gridState, this.disabledExtraFields] = handleFilterWithDates(
        this.initGridState
      );

      if (
        this.itemName !== GridName.ItemStickers &&
        this.gridState.take >= 50
      ) {
        this.gridState.take = 20;
      }

      this.isFilter =
        !!this.gridState?.filter?.filters?.length ||
        !!this.gridState?.sort?.length ||
        !!this.advancedFilter ||
        !!this.removalOrdersFilter;
    }
  }

  private handleInitialPusher() {
    this.pusherService.userChannel.bind('notification', (data) => {
      if (
        (data.type === 'notice' || data.type === 'close') &&
        [
          GridName.SuggestedPos,
          GridName.AllAvailableItems,
          GridName.ItemsInPo,
        ].includes(this.itemName)
      ) {
        this.loadGridItems();
        return;
      }

      if (
        data.type === 'close' &&
        [
          GridName.Shipment,
          GridName.ShipmentAWD,
          GridName.ManageSupplier,
        ].includes(this.itemName)
      ) {
        this.isProcessing = false;

        switch (data.key) {
          case 'errorConnect':
            this.snotifyService.error(
              'Access network denied, so that break process sync data'
            );
            break;

          case 'syncShipmentStatus':
            this.companyService
              .getById(this.currentCompany?.companyKey, true)
              .pipe(this.autoCleanUp())
              .subscribe(() => {
                this.loadGridItems();
              });
            break;
        }
      }

      if (
        [NotificationType.notice, NotificationType.close].includes(data.type) &&
        [
          NotificationKey.summaryStatus,
          NotificationKey.mwsSyncStart,
          NotificationKey.undoUploadStatus,
        ].includes(data.key)
      ) {
        if (
          data.key === NotificationKey.undoUploadStatus &&
          data.type === 'close'
        ) {
          this.undoUploadNotification$.next();
        }

        this.loadGridItems();
      }
    });
  }

  private setupGridForSpecificItemType() {
    this.modalTitle = this.itemName;
    switch (this.itemName) {
      case GridName.Item:
        this.calculateHasActiveItemsReachLimit().subscribe();
        this.isShowImageField = true;
        break;
      case GridName.ItemStickers:
        this.pageable = false;
        this.filterable = false;
        this.reorderable = false;
        this.sortable = false;
        this.columnMenu = false;
        break;
      case GridName.ManageSupplier:
        this.modalTitle = GridName.Supplier;
        break;
      case GridName.ForecastShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        this.modalTitle = 'SKU';
        this.isShowImageField = true;
        break;
      case GridName.ItemsInPo:
      case GridName.AllAvailableItems:
        this.isIconHeader = true;
        this.isShowImageField = true;
        break;
      case GridName.Demand:
      case GridName.Supply:
      case GridName.AmazonShipmentItem:
        this.itemName === GridName.Demand &&
          this.calculateHasActiveItemsReachLimit().subscribe();
        this.isShowImageField = true;
        break;
    }

    // Print Pick List grid does not need paging
    if (this.isPrintList) {
      this.pageable = false;
      this.gridState.take = -1;
    }
  }

  private handleSaveItemWhichHasPassedShipmentOptions = () => {
    if (
      this.itemName === GridName.AmazonShipmentItem ||
      !this.selectingItem?.stepProgress?.shipmentOptions
    ) {
      return of(null);
    }

    this.selectingItem.stepProgress.shipmentOptions = false;
    this.selectingItem.stepProgress.shipmentReview = false;
    this.selectingItem.stepProgress.complete = false;
    return this.shipmentService.save(this.selectingItem, 'key');
  };

  private handleSaveItemFromModalSuccessful() {
    this.loadGridItems();
    this.snotifyService.success(
      `${
        this.finalItemName === 'Supply' ? 'Purchase Order' : this.finalItemName
      } successfully created.`
    );
    this.newForm.reset();
    this.newItem = {};
    this.addressData = [];
    this.loading = false;
    if (this.checkShipmentDetail()) {
      this.reloadShipment.emit();
    }

    switch (this.itemName) {
      case GridName.Item:
        this.saveAndRemindToRunForecast();
        break;

      case GridName.Demand:
        this.hasOrdersReachLimit =
          (this.selectedPlan?.allowOrders &&
            this.currentCompany?.latestTotalOrder >=
              this.selectedPlan?.allowOrders &&
            this.isSubActive) ||
          !this.selectedPlan;
        break;
    }
  }

  private handleRowClick(args) {
    const { column, dataItem, sender, columnIndex } = args;
    // emit event row click on grid
    if (
      (this.isViewOnly && this.itemName === GridName.SuggestedPos) ||
      ([
        GridName.Supplier,
        GridName.SupplierList,
        GridName.ManageSupplier,
      ].includes(this.itemName) &&
        column.title != 'Links')
    ) {
      this.rowClick.emit(dataItem);
    }

    // If onchange at item, and column is Use Backfill, then show the onHistory popup
    const indexOfUseBackfillInSender = sender.columns._results.findIndex(
      (element: { field: string }) => element.field === 'useBackfill'
    );
    const columnIndexOfUseBackfill =
      sender.columns._results[indexOfUseBackfillInSender]?.leafIndex;
    if (
      this.itemName === GridName.Item &&
      columnIndex === columnIndexOfUseBackfill
    ) {
      // column.field === 'useBackfill'
      this.onHistory(dataItem);
    }
  }

  private buildFormGroup(
    dataItem: any,
    disableRequiredField,
    forbiddenFields: string[],
    cellSelected: string,
    formGroup: FormGroup,
    dateObject: any,
    mdf: MetaDataField
  ) {
    if (
      forbiddenFields.includes(mdf.field) ||
      (this.bulkEditAllowedFields.length &&
        !this.bulkEditAllowedFields.includes(mdf.field))
    ) {
      return;
    }

    if (
      ([ItemField.leadTime, ItemField.orderInterval] as string[]).includes(
        mdf.field
      )
    ) {
      this.setSpecificFieldValidator(mdf, dataItem, cellSelected);
    }

    let validators = this.extractValidators(
      mdf,
      disableRequiredField,
      dataItem,
      cellSelected
    );

    if (
      this.itemName === 'Item' &&
      [ItemField.history, ItemField.links, ItemField.imageUrl].includes(
        mdf.field as ItemField
      )
    ) {
      formGroup.addControl(mdf.field, this.formBuilder.control(null));
      return;
    }

    // This is for add modal, there is isReadOnly() that disable editable cell
    // Make asin, id, key related fields readonly
    if (mdf.field === 'createdAt' || mdf.field?.includes('Key')) {
      formGroup.addControl(
        mdf.field,
        this.formBuilder.control({ value: '', disabled: true }, [])
      );
      return;
    }

    switch (mdf.displayControl.input) {
      case DisplayControlInput.Date:
        formGroup.addControl(
          mdf.field,
          this.formBuilder.control(null, validators)
        );

        const transformedDate = conditionalOperator(
          dataItem[mdf.field],
          new Date(dataItem[mdf.field]),
          null
        );
        dateObject[mdf.field] = conditionalOperator(
          isNaN(transformedDate),
          new Date(),
          transformedDate
        );
        break;

      case DisplayControlInput.LookUp:
        formGroup.addControl(
          mdf.field,
          this.formBuilder.control('', validators)
        );

        let lookupControl = mdf.displayControl as LookUpDisplayControl;
        //get the field that will hold the key
        let mapping = lookupControl.lookUpConfig.mapping.find((x) => {
          return x.type === LookUpMappingType.Key;
        });

        if (mapping) {
          formGroup.addControl(mapping.to, this.formBuilder.control(''));
        }
        break;

      default:
        formGroup.addControl(
          mdf.field,
          this.formBuilder.control(
            '',
            conditionalOperator(
              this.isLocalQtyValidationDisabled &&
                mdf.field === ShipmentDetailField.localQty,
              [],
              validators
            )
          )
        );
    }
  }

  private markDirtyItem(dataItem: Item, oldDataItem: Item) {
    for (const key in dataItem) {
      if (['createdAt', 'created_at', 'updated_at'].includes(key)) {
        continue;
      }

      if (dataItem[key] !== oldDataItem[key]) {
        dataItem.forecastDirty = true;
        break;
      }
    }
  }

  private bomAdditionalKeysGenerate(dataItem: Bom, oldDataItem: Bom) {
    let additionalKeys: any = {
      type: AdditionalKeysType.updateBom,
    };

    if (
      this.items.find(
        (item) =>
          dataItem.parentKey === item.parentKey &&
          dataItem.childKey === item.childKey &&
          dataItem.qty === item.qty
      )
    ) {
      additionalKeys = {
        type: AdditionalKeysType.updateBom,
        parentKey: oldDataItem.parentKey,
        childKey: oldDataItem.childKey,
      };
    }

    return additionalKeys;
  }

  private itemSaveReachLimitHandle(dataItem: Item, oldDataItem: Item, sender) {
    let allowSave = true;
    if (
      this.hasActiveItemsReachLimit &&
      dataItem.isHidden !== oldDataItem.isHidden &&
      !dataItem.isHidden
    ) {
      this.snotifyService.error(
        'Your active item number has exceeded the limit of the current subscription.'
      );
      allowSave = false;
      this.grid.loading = false;
      this.saveInProgress = false;
      Object.assign(dataItem, this.originalValue);
      asyncScheduler.schedule(() => {
        sender.closeCell();
      });
    }

    return allowSave;
  }

  private supplySaveInvalidDateHandle(dataItem, sender) {
    let allowSave = true;
    if (!dataItem.vendorKey) {
      dataItem.vendorKey = null;
      dataItem.vendorName = null;
    }

    const invalidDueDocDate = this.onCheckDateConflict(
      dataItem.dueDate,
      dataItem.docDate
    );
    if (invalidDueDocDate) {
      allowSave = false;
      this.grid.loading = false;
      this.saveInProgress = false;

      Object.assign(dataItem, this.originalValue);
      asyncScheduler.schedule(() => {
        sender.closeCell();
      });

      this.snotifyService.error(
        'The Receive Date must be greater than the PO Date'
      );
    }

    return allowSave;
  }

  private hideVendorHandle(column, dataItem, args, additionalKeys, sender) {
    let allowSave = true;
    if (column.field === VendorField.isHidden && dataItem.isHidden) {
      allowSave = false;
      this.altModalService.open(VendorHiddenModalComponent, {
        data: { vendor: dataItem },
        backdrop: 'static',
        keyboard: false,
        onSuccess: (failToHideItems) => {
          this.grid.loading = false;
          this.saveData({ ...args, isHidden: true }, additionalKeys);
          if (failToHideItems?.length) {
            this.displayErrorMessage(failToHideItems, 'hide');
          }
        },
        onError: () => {
          args.preventDefault();
          this.grid.loading = false;
          this.saveInProgress = false;
          Object.assign(dataItem, this.originalValue);
          asyncScheduler.schedule(() => {
            sender.closeCell();
          });
        },
      });
    }

    return allowSave;
  }

  private shipmentSaveInvalidDateHandle(dataItem, oldDataItem) {
    let allowSave = true;

    if (
      new Date(dataItem.receiveDate).toDateString() !==
      new Date(oldDataItem.receiveDate).toDateString()
    ) {
      const receiveDate = new Date(dataItem.receiveDate);
      let maxDate = new Date();
      maxDate.setDate(new Date().getDate() + 365);
      if (
        receiveDate.getTime() < new Date().getTime() ||
        receiveDate.getTime() > maxDate.getTime()
      ) {
        allowSave = false;
        this.grid.loading = false;
        this.saveInProgress = false;
        this.snotifyService.error(`The Receive Date must be in the future `);
        dataItem.receiveDate = oldDataItem.receiveDate;
      }
    }

    return allowSave;
  }

  private sanitizeToBeSavedItem(dataItem) {
    if (dataItem.vendorKey === '' || dataItem.vendorName === '') {
      dataItem.vendorKey = null;
      dataItem.vendorName = null;
    }

    if (
      [GridName.Shipment, GridName.ShipmentAWD].includes(this.itemName) &&
      dataItem.orderNotes === ''
    ) {
      dataItem.orderNotes = null;
    }

    if (this.itemName === GridName.PurchaseOrderItem && this.currentCompany?.companyType === CompanyType.QBFS) {
      dataItem.openQty = dataItem.orderQty;
    }
  }

  private saveDataForCheckbox(e, checked, dataItem, item, col, oldItem?) {
    this.grid.loading = true;
    item[col.field] = checked;
    this.isHiddenRelationshipHandle(item, col, checked);

    if (this.itemName === GridName.ItemStickers) {
      this.itemsSticker.map((item) =>
        item.key === dataItem.key ? dataItem : item
      );
      this.itemsStickerChange.emit(this.itemsSticker);

      this.grid.loading = false;
      this.saveInProgress = false;
      return;
    }

    const additionalKeys =
      [GridName.Demand, GridName.Supply, GridName.PurchaseOrderItem].includes(this.itemName)
        ? { type: AdditionalKeysType.demandAndSupply }
        : {};

    this.resourceService.save(item, this.keyName, additionalKeys).subscribe(
      (res: T) => {
        this.grid.loading = false;
        this.saveInProgress = false;

        Object.assign(item, res);

        const name = item['name'] ? item['name'] : this.itemName;
        this.snotifyService.success(
          `${name === 'Supply' ? 'Purchase Order' : name} updated successfully.`
        );

        const isColumnSorted = this.gridState.sort?.some(
          (s) => s.field === col.field
        );
        if (isColumnSorted) {
          this.loadGridItems();
        }

        if (
          this.itemName === GridName.Item &&
          item.isHidden !== oldItem.isHidden
        ) {
          this.calculateHasActiveItemsReachLimit().subscribe();
        }
      },
      () => {
        e.target.click();
        this.grid.loading = false;
        this.saveInProgress = false;
      }
    );
  }

  private isHiddenRelationshipHandle(item, col, checked) {
    if (this.itemName === GridName.Item) {
      item.isHidden = item.doNotRestock && item.doNotOrder;
    }

    if (this.itemName === GridName.Item && col.field === ItemField.isHidden) {
      item.isHidden = checked;
      item.doNotOrder = item.isHidden;
      item.doNotRestock = item.isHidden;
    }
  }

  private isShipByCaseCheckboxHandle(checked, dataItem, column, e) {
    if (
      checked &&
      !dataItem.caseQty &&
      column.field === ShipmentDetailField.isShipByCase
    ) {
      dataItem.isShipByCase = Boolean(dataItem.caseQty);
      e.target.checked = true;
      this.casePackChecked.emit(checked);
      return;
    } else if (checked && column.field === ShipmentDetailField.hasSticker) {
      dataItem.stickerQty = 1;
    }

    this.casePackChecked.emit(checked);
  }

  private handlePreventReorderColumn(e) {
    if (e.newIndex < 0) {
      return true;
    }

    return false;
  }

  onExcelExport(args: ExcelExportEvent): void {
    if (this.itemName !== GridName.ItemsInPo) {
      return;
    }

    args.preventDefault();

    let currencyCols = [];
    this.selectedColumns.forEach((column) => {
      if (column.displayControl.output === DisplayControlOutput.Currency) {
        currencyCols.push(column.displayName);
      }
    });

    const columns = [];
    const workbook = args.workbook;
    const rows = workbook.sheets[0].rows;
    const headerOptions = rows[0].cells;
    headerOptions.unshift({ ...headerOptions[0], value: 'Tips' });
    rows.map((row) => row.type === 'data' && row.cells.unshift({ value: '' }));

    this.selectedColumns.forEach((col) => {
      !this.getExportColumn(col) && columns.push(col);
    });

    this.handleExcelExport(rows, columns, workbook, currencyCols).subscribe();
  }

  handleExcelExport(rows, columns, workbook, currencyCols) {
    return this.allDataPOItemsCustom().pipe(
      tap((poItems) => {
        for (let index = rows.length - 1; index > 0; index--) {
          const newRow = [{ value: 'Individual SKU data' }];
          if (poItems.data?.[index - 1]?.isChild) {
            for (const col of columns) {
              newRow.push({
                value:
                  poItems.data?.[index - 1]?.isolatedValues?.[col['field']] ||
                  poItems.data?.[index - 1]?.[col['field']],
              });
            }
            rows[index].cells[0].value = 'Total Component data';
            rows.splice(index + 1, 0, { cells: newRow, type: 'data' });
          }
        }
      }),
      tap(() => {
        let listColCurrencyIndex = [];
        rows.forEach((row) => {
          // Store the price column index
          if (row.type === 'header') {
            row.cells.forEach((cell, index) => {
              if (currencyCols.includes(cell.value)) {
                cell.value = cell.value + ' ' + this.displayCurrency.symbol;
                listColCurrencyIndex.push(index);
              }
            });
          }
          // Use the column index to format the price cell values
          if (row.type === 'data') {
            row.cells.forEach((cell, index) => {
              if (listColCurrencyIndex.includes(index)) {
                cell.format =
                  cell.value || cell.value === 0
                    ? `${this.displayCurrency.symbol}#,##0.00`
                    : '';
              }
            });
          }
        });
      }),
      tap(() => {
        this.downloadData(true);
        new Workbook(workbook).toDataURL().then((dataUrl: string) => {
          saveAs(dataUrl, this.downloadFileName);
        });
      })
    );
  }

  private handleDownloadLargeFile(notification: BehaviorSubject<any>) {
    this.pusherService.userChannel.bind('notification', (data) => {
      if (data.type === 'close' && data.key === 'downloadCsvStart') {
        const a = document.createElement('a');
        a.href = data.csvLink;
        a.click();
        a.remove();

        notification.next({
          title: 'Success',
          body: 'File downloaded!',
          config: {
            closeOnClick: true,
            timeout: 5000,
            showProgressBar: true,
            type: 'success',
          },
        });

        notification.complete();
        this.syncService.removeDownloadingItem(this.itemName);
        this.isExporting = false;
      }

      if (data.type === 'notice' && data.key === 'downloadCsvStart') {
        notification.next({
          title: 'Error',
          body: data.msg.error,
          config: {
            closeOnClick: true,
            timeout: 5000,
            type: 'error',
          },
        });

        notification.complete();
        this.syncService.removeDownloadingItem(this.itemName);
        this.isExporting = false;
      }
    });
  }

  private loadGridItemsWithAdvancedFilter() {
    this.isFilter = true;

    switch (this.itemName) {
      case GridName.Item:
        this.itemService
          .getItemsAdvanced({
            type: this.advancedFilter,
            take: this.gridState.take,
            skip: this.gridState.skip,
            filter: this.filterState || this.gridState.filter,
            sort: this.gridState.sort,
            marketplaceId: this.currentCompany?.marketplaceId,
            currencyCode: this.currentCompany?.currencyCode,
          })
          .pipe(
            this.autoCleanUp(),
            switchMap(({ model, count }) => {
              const vendors$ = this.vendorService.getByMultipleIds(
                _.uniq(model.filter((i) => i.vendorKey).map((i) => i.vendorKey))
              );

              return forkJoin([of({ model, count }), vendors$]);
            })
          )
          .subscribe(([{ model, count }, vendors]) => {
            this.vendorsGroupedByKey = _.groupBy(vendors, 'key');
            const items = model.map((i) => ({
              ...i,
              inventorySourcePreference: _.isEmpty(i.inventorySourcePreference)
                ? this.currentCompany.inventorySourcePreference
                : i.inventorySourcePreference,
            }));

            asapScheduler.schedule(() => {
              this.grid.loading = false;
            });

            this.totalRecords = count;
            this.gridDataItems = {
              total: count,
              data: items,
            };
          });

        break;
      case GridName.AllAvailableItems:
        this.loadAllAvailableItems();

        break;
    }
  }

  private ensureObjectIsDate(items: any[]) {
    return items.map((item) => {
      for (let key in item) {
        if (key.includes('Date') && item?.[key]) {
          item[key] = new Date(item[key]);
        }
      }

      return item;
    });
  }

  private loadGridItemsOfCustomItemsInPo() {
    this.summaryService
      .getCachedPOItems()
      .pipe(this.autoCleanUp())
      .subscribe((items) => {
        if (this.grid) {
          setTimeout(() => (this.grid.loading = false));
        }

        this.justChangedTab?.subscribe(() => {
          setTimeout(() => (this.grid.loading = false));
        });

        const gridData = filterBy(
          this.ensureObjectIsDate(items),
          this.filterState || this.gridState.filter
        );

        const pagedData = gridData?.slice(
          this.gridState.skip,
          this.gridState.skip + this.gridState.take
        );

        this.isGridEmpty = !gridData.length;
        this.totalRecords = gridData.length;
        this.gridDataItems = {
          total: gridData.length,
          data: orderBy(pagedData, this.gridState.sort),
        };
      });
  }

  private transformItemsBeforeLoading(items$: Observable<T[] | any[]>) {
    switch (this.itemName) {
      case GridName.Item:
        return items$.pipe(
          switchMap((items) => {
            const vendors$ = this.vendorService.getByMultipleIds(
              _.uniq(
                items?.filter((i: any) => i.vendorKey).map((i) => i.vendorKey)
              )
            );

            return forkJoin([of(items), vendors$]);
          }),
          map(([items, vendors]) => {
            this.vendorsGroupedByKey = _.groupBy(vendors, 'key');
            return items;
          })
        );

      case GridName.Supplier:
        return items$.pipe(
          map((items: any[]) => {
            return items.map((i) => ({
              ...i,
              countryCode: COUNTRIES.find(
                (c) => c.countryCode === i.countryCode
              )?.countryCode,
              stateOrProvinceCode:
                STATES.find((s) => s.stateCode === i.stateOrProvinceCode)
                  ?.name || i.stateOrProvinceCode,
            })) as T[];
          })
        );

      default:
        return items$;
    }
  }

  private reapplyGridItemSelectedKey(items: any[]) {
    if (!this.selectedRows?.size) {
      return;
    }

    items.forEach((item) => {
      // The key of selected row equal with the key of items
      switch (this.itemName) {
        case GridName.Supply:
        case GridName.Demand:
          if (
            this.selectedRows.get(
              `${item['docType']}/${item['orderKey']}/${item['rowKey']}`
            ) !== undefined
          ) {
            this.gridItemSelectedKey.push(
              `${item['docType']}/${item['orderKey']}/${item['rowKey']}`
            );
          }
          break;

        case GridName.Bom:
          if (
            this.selectedRows.get(
              `${item['parentKey']}/${item['childKey']}`
            ) !== undefined
          ) {
            this.gridItemSelectedKey.push(
              `${item['parentKey']}/${item['childKey']}`
            );
          }
          break;

        case GridName.ItemSite:
          if (
            this.selectedRows.get(`${item['itemKey']}/${item['key']}`) !==
            undefined
          ) {
            this.gridItemSelectedKey.push(`${item['itemKey']}/${item['key']}`);
          }
          break;

        default:
          if (this.selectedRows.get(item['key']) !== undefined) {
            this.gridItemSelectedKey.push(item['key']);
          }
          break;
      }
    });
  }

  private constructGridItems(
    count$: Observable<number>,
    items$: Observable<T[] | any[]>
  ) {
    forkJoin([count$, items$])
      .pipe(
        this.autoCleanUp(),
        map(([total, items]) => {
          this.items = items;
          if (this.grid) {
            setTimeout(() => (this.grid.loading = false));
          }

          this.isGridEmpty = !total;

          switch (this.itemName) {
            case GridName.Demand:
              this.headerService.setStepCompleted(
                PROCESSING_STEP_NAME.DEMAND,
                total > 0
              );
              break;

            case GridName.ManageSupplier:
            case GridName.Bom:
            case GridName.ItemSite:
              this.totalRecords = total;
              return {
                ...process(items, { group: this.gridState.group }),
                total,
              } as GridDataResult;

            case GridName.ItemStickers:
              items.forEach((item: any) => {
                item.hasSticker = true;
                item.stickerQty = 1;
              });

              const isItemSticker = this.itemsSticker?.length >= 0;
              isItemSticker && (items = this.itemsSticker);
              !isItemSticker && (this.itemsSticker = items);
              this.itemsStickerChange.emit(this.itemsSticker);
              break;

            case GridName.Shipment:
            case GridName.ShipmentAWD:
              if (!this.gridState.sort?.length) {
                const pendingItems = _.remove(items, (i) => {
                  return i.status === ShipmentStatus.PENDING;
                });
                pendingItems.sort((a, b) => {
                  const aDate = new Date(a.updatedAt).getTime();
                  const bDate = new Date(b.updatedAt).getTime();

                  return bDate - aDate;
                });

                items = pendingItems.concat(items);
              }

              break;

            default:
              break;
          }

          this.companyService
            .getNewById(this.currentCompany.companyKey)
            .pipe(
              this.autoCleanUp(),
              tap(
                (company) =>
                  (this.shipmentLastRefresh = company?.shipmentLastRefresh)
              )
            )
            .subscribe();
          this.selectedTagRows = [];
          this.selectedKeyTag = {};

          if (this.checkboxSelectAllTag?.nativeElement?.checked) {
            this.selectedTagRows = items;
            this.selectedKeyTag = _.groupBy(this.selectedTagRows, 'key');
          }

          // reset array states of selected rows
          this.gridItemSelectedKey = [];

          // Have a rows selected before
          this.reapplyGridItemSelectedKey(items);

          this.totalRecords = total;
          return { total, data: items || [] } as GridDataResult;
        })
      )
      .subscribe((data) => {
        this.gridDataItems = data;
      });
  }

  private getItemKey(dataItem: T) {
    switch (true) {
      case this.itemName === GridName.Demand:
      case this.itemName === GridName.Supply:
        return `${encodeURIComponent(dataItem['docType'])}/${encodeURIComponent(
          dataItem['orderKey']
        )}/${encodeURIComponent(dataItem['rowKey'])}`;

      case this.itemName === GridName.Bom:
        return `${encodeURIComponent(
          dataItem['parentKey']
        )}/${encodeURIComponent(dataItem['childKey'])}`;

      case this.itemName.includes('Shipment Item'):
        return `${encodeURIComponent(dataItem['itemKey'])}`;

      case this.itemName === GridName.ItemSite:
        return `${encodeURIComponent(dataItem['itemKey'])}/${encodeURIComponent(
          dataItem['key']
        )}`;

      default:
        return encodeURIComponent(dataItem['key']);
    }
  }

  private fireWarehouseError() {
    if (
      this.currentCompany &&
      !this.currentCompany.willDisableMultipleWarehouses
    ) {
      this.snotifyService.info(
        'As you are enabling Multiple Warehouses, the inputted Warehouse Qty will not apply to the created Item.'
      );
    }
  }

  onResizeColumn(columns) {
    const column = _.head(columns);

    this.columnResizeArgs = {
      field: column.column.field,
      newWidth: column.newWidth,
    };

    this.columnResize.emit({
      field: column.column.field,
      newWidth: column.newWidth,
    });
  }

  eraseWarehouseInventory() {
    Swal.fire({
      html: 'If you are sure you want to clear your </br>Warehouse Inventory?',
      type: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((willSave) => {
      if (willSave.dismiss) {
        return;
      }
      this.itemService.eraseWarehouseInventory().subscribe(() => {
        Swal.fire({
          html: `
            <div>Erased Warehouse Qty successfully. </br>Please run forecast to view your updates.</div>
          `,
          type: 'success',
          allowOutsideClick: false,
        }).then(() => this.loadGridItems());
      });
    });
  }

  isFieldBoxWeightAndDimensions(field) {
    return (
      this.itemName === GridName.Item &&
      [
        ItemField.boxLength,
        ItemField.boxWidth,
        ItemField.boxHeight,
        ItemField.boxWeight,
      ].includes(field)
    );
  }

  isSelectedKeyBoxWeightAndDimensions(key, field) {
    switch (field) {
      case ItemField.boxLength:
        return !!this.selectedKeyBoxLength[key];
      case ItemField.boxWidth:
        return !!this.selectedKeyBoxWidth[key];
      case ItemField.boxHeight:
        return !!this.selectedKeyBoxHeight[key];
      case ItemField.boxWeight:
        return !!this.selectedKeyBoxWeight[key];
    }
  }

  isSelectAllBoxWeightAndDimensionsRows(field) {
    switch (field) {
      case ItemField.boxLength:
        return !!this.selectedKeyBoxLength.length;
      case ItemField.boxWidth:
        return !!this.selectedKeyBoxWidth.length;
      case ItemField.boxHeight:
        return !!this.selectedKeyBoxHeight.length;
      case ItemField.boxWeight:
        return !!this.selectedKeyBoxWeight.length;
    }
  }

  selectBoxWeightAndDimensionsRows(boxRow: Item, addRow: boolean, field) {
    switch (field) {
      case ItemField.boxLength:
        if (addRow) {
          this.selectedBoxLengthRows.push(boxRow);
        } else {
          this.selectedBoxLengthRows = this.selectedBoxLengthRows.filter(
            (row) => row[this.keyName] !== boxRow[this.keyName]
          );
        }
        this.selectedKeyBoxLength = _.groupBy(
          this.selectedBoxLengthRows,
          this.keyName
        );
        return;
      case ItemField.boxWidth:
        if (addRow) {
          this.selectedBoxWidthRows.push(boxRow);
        } else {
          this.selectedBoxWidthRows = this.selectedBoxWidthRows.filter(
            (row) => row[this.keyName] !== boxRow[this.keyName]
          );
        }
        this.selectedKeyBoxWidth = _.groupBy(
          this.selectedBoxWidthRows,
          this.keyName
        );
        return;
      case ItemField.boxHeight:
        if (addRow) {
          this.selectedBoxHeightRows.push(boxRow);
        } else {
          this.selectedBoxHeightRows = this.selectedBoxHeightRows.filter(
            (row) => row[this.keyName] !== boxRow[this.keyName]
          );
        }
        this.selectedKeyBoxHeight = _.groupBy(
          this.selectedBoxHeightRows,
          this.keyName
        );
        return;
      case ItemField.boxWeight:
        if (addRow) {
          this.selectedBoxWeightRows.push(boxRow);
        } else {
          this.selectedBoxWeightRows = this.selectedBoxWeightRows.filter(
            (row) => row[this.keyName] !== boxRow[this.keyName]
          );
        }
        this.selectedKeyBoxWeight = _.groupBy(
          this.selectedBoxWeightRows,
          this.keyName
        );
        return;
    }
  }

  selectAllBoxWeightAndDimensionsRows(addRow: boolean, field): void {
    switch (field) {
      case ItemField.boxLength:
        this.selectedBoxLengthRows = conditionalOperator(
          addRow,
          this.items,
          []
        );
        this.selectedKeyBoxLength = conditionalOperator(
          addRow,
          _.groupBy(this.items, this.keyName),
          []
        );
        return;
      case ItemField.boxWidth:
        this.selectedBoxWidthRows = conditionalOperator(addRow, this.items, []);
        this.selectedKeyBoxWidth = conditionalOperator(
          addRow,
          _.groupBy(this.items, this.keyName),
          []
        );
        return;
      case ItemField.boxHeight:
        this.selectedBoxHeightRows = conditionalOperator(
          addRow,
          this.items,
          []
        );
        this.selectedKeyBoxHeight = conditionalOperator(
          addRow,
          _.groupBy(this.items, this.keyName),
          []
        );
        return;
      case ItemField.boxWeight:
        this.selectedBoxWeightRows = conditionalOperator(
          addRow,
          this.items,
          []
        );
        this.selectedKeyBoxWeight = conditionalOperator(
          addRow,
          _.groupBy(this.items, this.keyName),
          []
        );
        return;
    }
  }

  updateMultipleRow(field, dataItem) {
    let update$;
    let element;
    switch (field) {
      case ItemField.boxLength:
        this.selectedBoxLengthRows.forEach(
          (row) => (row['boxLength'] = parseInt(dataItem[field]))
        );
        update$ = this.itemService
          .updateMultiple(this.selectedBoxLengthRows)
          .pipe(this.autoCleanUp());
        element = document.getElementsByClassName(field);
        break;
      case ItemField.boxWidth:
        this.selectedBoxWidthRows.forEach(
          (row) => (row['boxWidth'] = parseInt(dataItem[field]))
        );
        update$ = this.itemService
          .updateMultiple(this.selectedBoxWidthRows)
          .pipe(this.autoCleanUp());
        element = document.getElementsByClassName(field);
        break;
      case ItemField.boxHeight:
        this.selectedBoxHeightRows.forEach(
          (row) => (row['boxHeight'] = parseInt(dataItem[field]))
        );
        update$ = this.itemService
          .updateMultiple(this.selectedBoxHeightRows)
          .pipe(this.autoCleanUp());
        element = document.getElementsByClassName(field);
        break;
      case ItemField.boxWeight:
        this.selectedBoxWeightRows.forEach(
          (row) => (row['boxWeight'] = parseInt(dataItem[field]))
        );
        update$ = this.itemService
          .updateMultiple(this.selectedBoxWeightRows)
          .pipe(this.autoCleanUp());
        element = document.getElementsByClassName(field);
        break;
    }

    update$.subscribe(() => {
      this.selectAllBoxWeightAndDimensionsRows(false, field);
      if (element[0].checked) {
        element[0].checked = false;
      }
      this.loadGridItems();
      this.snotifyService.success('Updated successfully');
      this.grid.loading = false;
      this.saveInProgress = false;
    });
  }

  filterRemovalOrders(minOrderQtyPop: NgbPopover) {
    minOrderQtyPop.close();
    this.removalOrdersFilter = {
      filters: [
        { field: 'orderKey', operator: 'startswith', value: 'S01' },
        {
          field: 'orderQty',
          operator: 'gte',
          value: this.filterRemovalOrdersQty,
        },
      ],
      logic: Logic.and,
    };
    this.onStateChange(this.gridState);
  }

  clearFilterRemovalOrders(minOrderQtyPop: NgbPopover) {
    minOrderQtyPop.close();
    this.filterRemovalOrdersQty = null;
    this.removalOrdersFilter = null;
    this.onStateChange(this.gridState);
  }

  private buildToBeHiddenItemList() {
    let displayList = '';

    switch (this.itemName) {
      case GridName.Item:
        this.selectedRows?.forEach((value) => {
          displayList += `${value[ItemField.name]}<br />`;
        });
        break;

      case GridName.Demand:
        displayList += 'Order ID | Item Name<br /><br />';
        this.selectedRows?.forEach((value) => {
          displayList += `${value[DemandField.orderKey]} | ${
            value[DemandField.itemName]
          }<br />`;
        });
        break;

      default:
        throw new Error(
          `Hide functionality does not support item type ${this.itemName}`
        );
    }

    displayList = `<div style="overflow:auto;max-height:150px;">${displayList}</div>`;
    return displayList;
  }

  filterChange(filter) {
    this.filterList = this.defaultFilterList.filter(
      (s) => s.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  selectionChange(selection) {
    this.filterIndex = this.gridState.filter.filters.findIndex((f) => {
      const group = _.groupBy(
        (f as CompositeFilterDescriptor).filters,
        'field'
      );
      return group[selection?.value?.field];
    });

    if (!selection.value) return;
    asyncScheduler.schedule(() => {
      const btn = document.querySelector(`.k-button[title*=Group]`);
      btn?.setAttribute('hidden', 'true');
      const addFilter = document.querySelector(`.k-button[title='Add Filter`);
      addFilter?.setAttribute('hidden', 'true');
    });
    this.isOpenFilter = true;
    this.selectedFilter = [];
    this.selectedFilter.push({
      field: selection?.value?.field,
      title: selection?.text,
      editor: this.getType(selection?.value?.dataType),
    });

    this.defaultFilterValue = {
      logic: Logic.and,
      filters: [
        { operator: 'eq', value: null, field: selection?.value?.field },
      ],
    };
    if (!this.gridState.filter?.filters?.length) {
      this.isDisableFilter = true;
    }
  }

  getType(type) {
    if (['string', 'text'].includes(type)) return 'string';
    if (type === 'numeric') return 'number';
    return type;
  }

  getFilterList() {
    this.defaultFilterList = this.selectedColumns
      .filter((col) => col.filterable)
      .map((col) => {
        return { text: col.displayName, value: col };
      });
    this.filterList = this.defaultFilterList.slice();
  }

  applyExtraFilters(extraFilters: CompositeFilterDescriptor): void {
    if (!extraFilters.filters.length) {
      return;
    }

    this.isOpenFilter = false;
    this.dropDownListFilter.reset();
    if (!this.gridState.filter.filters.length) {
      this.gridState.filter.filters.push(extraFilters);
      this.filterIndex = 0;
      this.onStateChange(this.gridState);
      return;
    }

    if (this.filterIndex >= 0) {
      this.gridState.filter.filters[this.filterIndex] = extraFilters;
    } else {
      this.gridState.filter.filters.push(extraFilters);
    }

    this.onStateChange(this.gridState);
  }

  deleteExtraFilter(index): void {
    this.gridState.filter.filters.splice(index, 1);
    this.onStateChange(this.gridState);
  }

  getExtraFilterChipLabel(filterChip): string {
    const displayName = this.getDisplayName(
      _.head(filterChip.filters).field as string
    );
    const chipValue = this.defaultFilterList.find(
      (filter) => filter.value.field === _.head(filterChip.filters).field
    );

    let label = '';
    let filterValue;

    filterChip.filters.forEach((filter, idx) => {
      const operator = getReadableKendoOperator(
        filter.operator as string,
        filter.value
      );

      if (chipValue?.value?.dataType === 'date') {
        filter.value = formatDate(filter.value, this.FORMAT_DATE, 'en');
      }

      filterValue = this.getDisplayFilterValue(filter.operator, filter.value);

      const displayFilter = `${filterValue === null ? 'Null' : filterValue}`;

      label += `${operator} ${displayFilter} ${
        idx === filterChip.filters.length - 1 ? '' : filterChip.logic
      } `;
    });

    return `${displayName}: ${label}`;
  }

  getDisplayName(field: string): string {
    return (
      this.selectedColumns?.find((metadataField) => {
        return field === metadataField.field;
      })?.displayName || _.startCase(field)
    );
  }

  getDisplayFilterValue(filterOperator, filterValue) {
    if (filterOperator === 'isnull' || filterOperator === 'isnotnull') {
      return '';
    }
    const value = Number.isNaN(filterValue) ? `"${filterValue}"` : filterValue;
    return value;
  }

  calcFilterWindowPosition(): void {
    const navBarElement = document.querySelector('.pcoded-navbar');
    const headerElement = document.querySelector('.pcoded-header');
    const isRestockSection = [
      GridName.Shipment,
      GridName.ShipmentAWD,
      GridName.SupplierList,
      GridName.ManageSupplier,
      GridName.PurchaseOrder,
    ].includes(this.itemName);
    const filterWindowTopDivider = isRestockSection ? 2 : 5;

    this.filterWindowLeft =
      (window.innerWidth - navBarElement.getBoundingClientRect().right) / 2 -
      FILTER_WINDOW_SIZE.WIDTH / 2;
    this.filterWindowTop =
      (window.innerHeight - headerElement.getBoundingClientRect().bottom) /
        filterWindowTopDivider -
      FILTER_WINDOW_SIZE.HEIGHT / 2;
  }

  clearAllFilter() {
    this.gridState.filter = this.defaultFilterValue;
    this.dropDownListFilter.reset();
    this.onStateChange(this.gridState);
  }

  onCloseWindowFilter() {
    this.isOpenFilter = false;
    this.dropDownListFilter.reset();
  }

  onFilterChange(data) {
    if (!data?.filters?.length) {
      this.isDisableFilter = true;
      return;
    }

    this.isDisableFilter = data?.filters.find(
      (filter) =>
        (filter.value === null || filter.value === '') &&
        !['isempty', 'isnotempty', 'isnull', 'isnotnull'].includes(
          filter.operator
        )
    );
  }

  setSpecificFieldValidator(mdf, dataItem, cell) {
    let vendorLeadTime;
    let vendorOrderInterval;
    let trueValue;

    if (!!cell && this.vendorsGroupedByKey?.[dataItem?.vendorKey]) {
      vendorLeadTime = _.head(
        this.vendorsGroupedByKey[dataItem.vendorKey]
      ).leadTime;
      vendorOrderInterval = _.head(
        this.vendorsGroupedByKey[dataItem.vendorKey]
      ).orderInterval;
    }

    if (mdf.field === ItemField.leadTime) {
      trueValue =
        dataItem?.orderInterval ??
        vendorOrderInterval ??
        this.currentCompany.orderInterval;
      trueValue = cell ? trueValue : this.currentCompany.orderInterval;
      const maxValue = 360 - trueValue > 0 ? 360 - trueValue : 1;
      mdf.validators = [new MinMaxValidator({ min: 1, max: maxValue })];
      return;
    }

    if (mdf.field === ItemField.orderInterval) {
      trueValue =
        dataItem?.leadTime ?? vendorLeadTime ?? this.currentCompany.leadTime;
      trueValue = cell ? trueValue : this.currentCompany.leadTime;
      const maxValue = 360 - trueValue > 0 ? 360 - trueValue : 0;
      mdf.validators = [new MinMaxValidator({ min: 0, max: maxValue })];
    }
  }

  handleSelectedVendor(value) {
    this.selectedVendor = value;
    if (!!value && value?.leadTime !== null && value?.orderInterval !== null) {
      const maxLeadTime =
        360 - value.orderInterval > 0 ? 360 - value.orderInterval : 1;
      const maxOrderInterval =
        360 - value.leadTime > 0 ? 360 - value.leadTime : 0;
      this.newForm.controls.leadTime.setValidators([
        Validators.min(1),
        Validators.max(maxLeadTime),
      ]);
      this.newForm.controls.orderInterval.setValidators([
        Validators.min(0),
        Validators.max(maxOrderInterval),
      ]);
      this.newForm.controls.leadTime.updateValueAndValidity({
        emitEvent: false,
      });
      this.newForm.controls.orderInterval.updateValueAndValidity({
        emitEvent: false,
      });
      return;
    }

    if (!value) {
      const maxLeadTime =
        360 - this.currentCompany.orderInterval > 0
          ? 360 - this.currentCompany.orderInterval
          : 1;
      const maxOrderInterval =
        360 - this.currentCompany.leadTime > 0
          ? 360 - this.currentCompany.leadTime
          : 0;
      this.newForm.controls.leadTime.setValidators([
        Validators.min(1),
        Validators.max(maxLeadTime),
      ]);
      this.newForm.controls.orderInterval.setValidators([
        Validators.min(0),
        Validators.max(maxOrderInterval),
      ]);
      this.newForm.controls.leadTime.updateValueAndValidity({
        emitEvent: false,
      });
      this.newForm.controls.orderInterval.updateValueAndValidity({
        emitEvent: false,
      });
    }
  }

  handleValueNewFormChange() {
    combineLatest([
      this.newForm.controls.leadTime.valueChanges,
      this.newForm.controls.orderInterval.valueChanges,
    ])
      .pipe(
        tap(([leadTime, orderInterval]) => {
          if (leadTime === null || orderInterval === null) {
            this.leadTimeAndOrderIntervalTotalError = false;
            this.handleSelectedVendor(this.selectedVendor);
            return;
          }
          const maxOrderInterval = 360 - leadTime > 0 ? 360 - leadTime : 0;
          const maxLeadTime = 360 - orderInterval > 0 ? 360 - orderInterval : 1;
          this.leadTimeAndOrderIntervalTotalError =
            leadTime > 0 && orderInterval > 0 && leadTime + orderInterval > 360;
          this.newForm.controls.leadTime.setValidators([
            Validators.min(1),
            Validators.max(maxLeadTime),
          ]);
          this.newForm.controls.orderInterval.setValidators([
            Validators.min(0),
            Validators.max(maxOrderInterval),
          ]);
          this.newForm.controls.leadTime.updateValueAndValidity({
            emitEvent: false,
          });
          this.newForm.controls.orderInterval.updateValueAndValidity({
            emitEvent: false,
          });
        })
      )
      .subscribe();
  }

  hideSupply(suppliesMaxOrderQty) {
    this.loading = true;
    this.suppliesMaxOrderQty = suppliesMaxOrderQty;
    this.currentCompany.autoHideMaxOrderQty = this.suppliesMaxOrderQty;
    this.companyService
      .save(this.currentCompany, 'companyKey')
      .pipe(
        this.autoCleanUp(),
        tap(
          () => {
            this.snotifyService.success(
              'Min Order Qty to Hide has been saved.'
            );
            this.companyService.updateCacheCompany(this.currentCompany);

            if (this.suppliesMaxOrderQty !== null) {
              this.onHideSupply.emit(this.suppliesMaxOrderQty);
            }
          },
          () => {
            this.snotifyService.error(
              'Min Order Qty to Hide has failed to save.'
            );
          }
        ),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  checkColumnLockedStatus(lockingColumn) {
    if (!lockingColumn) {
      return null;
    }

    const isLocked = !this.lockedColumns.includes(lockingColumn.field);
    if (isLocked) {
      this.lockedColumns = [...this.lockedColumns, lockingColumn.field];
    } else {
      this.lockedColumns = this.lockedColumns.filter(
        (c) => c !== lockingColumn.field
      );
    }

    return isLocked;
  }

  lockColumnWithButton(event: MouseEvent, field: string) {
    event.stopImmediatePropagation();
    this.isLockedByButton = true;

    const col = this.grid.columns.find((c: any) => c.field === field);
    const isUnlocking = this.lockedColumns.includes(field);
    col.locked = !isUnlocking;

    const excludeCheckboxColNumber = [
      GridName.PurchaseOrder,
      GridName.Shipment,
      GridName.ShipmentAWD,
      GridName.SupplierList,
      GridName.RestockSuggestion,
    ].includes(this.itemName)
      ? -1
      : 0;
    this.grid.reorderColumn(
      col,
      this.lockedColumns?.length +
        (isUnlocking ? -1 : 1) +
        excludeCheckboxColNumber
    );

    this.lockSubject$.next({ columns: [col] });
  }

  resetLockedColumns() {
    this.isLockedByButton = true;

    asapScheduler.schedule(() => {});

    let toBeLockedField;
    switch (this.itemName) {
      case GridName.Item:
      case GridName.Supplier:
        toBeLockedField = ItemField.name;
        break;

      case GridName.SupplierList:
        toBeLockedField = RestockSuggestionSupplierField.vendorName;
        break;

      case GridName.Demand:
      case GridName.Supply:
      case GridName.ItemsInPo:
      case GridName.AllAvailableItems:
      case GridName.AmazonShipmentItem:
      case GridName.ForecastShipmentItem:
      case GridName.PurchaseOrderItem:
        toBeLockedField = DemandField.itemName;
        break;
      case GridName.PurchaseOrder:
        toBeLockedField = PurchaseOrderField.refNum;
        break;
      case GridName.Shipment:
      case GridName.ShipmentAWD:
        toBeLockedField = ShipmentField.shipmentId;
        break;
    }

    const col = this.grid.columns.find((c: any) => c.field === toBeLockedField);
    if (!toBeLockedField || !col) {
      return;
    }

    col.locked = true;
    const excludeCheckboxColNumber = [
      GridName.PurchaseOrder,
      GridName.Shipment,
      GridName.ShipmentAWD,
      GridName.SupplierList,
      GridName.RestockSuggestion,
    ].includes(this.itemName)
      ? 0
      : 1;

    this.isResettingLockedColumns = true;

    this.grid.reorderColumn(col, excludeCheckboxColNumber);
    this.lockSubject$.next({ columns: [col] });
  }

  calculateUndoLeftTime() {
    this.companyService
      .getById(this.currentCompany.companyKey, true)
      .pipe(
        this.autoCleanUp(),
        tap((company) => {
          switch (this.itemName) {
            case GridName.Item:
              this.remainingUndoTime =
                HOURS_IN_DAY -
                moment().diff(company.itemLastUploadDate, 'hours');
              break;

            case GridName.Supplier:
              this.remainingUndoTime =
                HOURS_IN_DAY -
                moment().diff(company.vendorLastUploadDate, 'hours');
              break;

            case GridName.Demand:
              this.remainingUndoTime =
                HOURS_IN_DAY -
                moment().diff(company.demandLastUploadDate, 'hours');
              break;

            case GridName.Supply:
            case GridName.PurchaseOrder:
              this.remainingUndoTime =
                HOURS_IN_DAY -
                moment().diff(company.supplyLastUploadDate, 'hours');
              break;

            case GridName.Bom:
              this.remainingUndoTime =
                HOURS_IN_DAY -
                moment().diff(company.bomLastUploadDate, 'hours');
              break;

            default:
              this.remainingUndoTime = 0;
              break;
          }
        })
      )
      .subscribe();
  }

  isButtonDisabled(dataItem: PurchaseOrder, buttonName: string) {
    switch (dataItem?.status) {
      case PurchaseOrderStatus.PENDING:
        return [
          'Search',
          'Print',
          'Close',
          'CreateShipment',
          'PrintSticker',
        ].includes(buttonName);
      case PurchaseOrderStatus.OPEN:
      case null:
        return (
          ['Edit'].includes(buttonName) ||
          (buttonName === 'CreateShipment' && dataItem?.shipmentKey)
        );
      default:
        return ['Edit', 'Close', 'CreateShipment'].includes(buttonName);
    }
  }

  isCreatingPOButtonDisabled(dataItem: any) {
    return (
      [
        ShipmentStatus.PENDING,
        ShipmentStatus.CLOSED,
        ShipmentStatus.CANCELED,
        ShipmentStatus.DELETED,
        ShipmentStatus.CANCELLED,
      ].includes(dataItem?.status) || dataItem?.purchaseOrderKey
    );
  }

  onClosePo(dataItem: any) {
    if (
      !this.isCompanyTypeQBFS &&
      dataItem.status === PurchaseOrderStatus.OPEN
    ) {
      const additionalOptions = {
        marketplaceId: this.currentCompany?.marketplaceId,
        currencyCode: this.displayCurrency?.code,
        isClosePo: true,
      };
      const payload = { ...dataItem };
      payload.status = PurchaseOrderStatus.CLOSED;
      payload.openQty = 0;
      this.loading = true;
      this.purchaseOrderService
        .save(payload, 'key', {}, additionalOptions)
        .pipe(
          tap((res) => {
            if (res) {
              this.loadGridItems();
              this.snotifyService.success(
                `item ${res.refNum} is successfully updated`
              );
            }
          }),
          finalize(() => (this.loading = false))
        )
        .subscribe();
    }
  }

  onDeletePo(item: PurchaseOrder) {
    Swal.fire({
      title: 'Are you sure?',
      html: `<div>The PO <b>${item.refNum}</b> will be deleted</div>`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((willDelete) => {
      //if not dismissed.
      if (!willDelete.dismiss) {
        this.grid.loading = true;
        this.resourceService
          .delete(item.key)
          .pipe(this.autoCleanUp())
          .subscribe(
            (failToDeleteItem) => {
              if (failToDeleteItem) {
                this.displayErrorMessage(failToDeleteItem, 'delete');
              } else {
                this.snotifyService.success(
                  'The selected item has been deleted.'
                );
              }

              this.selectedRows.clear();
              this.loadGridItems();
              this.isDisabledDelete = true;
            },
            () => {
              // something catastrophic must have happened.
              this.isDisabledDelete = true;
              this.selectedRows.clear();
              this.loadGridItems();
              this.snotifyService.error(
                'There was a problem deleting the selected items.'
              );
            }
          );
      }
    });
  }

  onEditPo(item: PurchaseOrder) {
    this.resourceService.getById(item?.key).subscribe((res: any) => {
      if (res?.key) {
        this.router.navigate([
          '/dashboard',
          'purchases',
          'custom',
          'purchase-order',
          res.key,
        ]);
      }
    });
  }

  loadPoItem(): Observable<any[] | T[]> {
    if (this.purchaseOrderKey) {
      return this.purchaseOrderItemService.getPurchaseOrderItemsByPOKey(
        this.purchaseOrderKey,
        {
          skip: this.gridState.skip,
          take: this.gridState.take,
          filter: this.filterState || this.gridState.filter,
          sort: this.gridState.sort,
        },
        this.currentCompany?.marketplaceId,
        this.currentCompany?.currencyCode
      );
    }
    return of(null);
  }

  loadPoCount(): Observable<any> {
    if (this.purchaseOrderKey && this.gridState) {
      return this.purchaseOrderItemService.getPurchaseOrderItemCountByPOKey(
        this.purchaseOrderKey,
        this.gridState?.filter
      );
    }
    return of(null);
  }

  onAddFilter(filter) {
    filter?.filters.push({
      operator: 'eq',
      value: null,
      field: this.selectedFilter[0].field,
    });
    this.isDisableFilter = true;
  }

  onCreatePurchaseOrderShipment(dataItem: Shipment | PurchaseOrder) {
    this.createPurchaseOrderShipment.emit(dataItem);
  }

  onPrintSticker(dataItem: PurchaseOrder) {
    this.printStickerClick.emit(dataItem);
  }

  getIsRowGrey(dataItem: any) {
    if (this.isSticker) {
      return false;
    }

    if (dataItem?.isHidden) {
      return true;
    }

    if (
      [GridName.Supply, GridName.PurchaseOrderItem].includes(this.itemName) &&
      dataItem?.shipmentKey
    ) {
      return true;
    }

    return false;
  }

  linkToPurchaseOrder(dataItem: Shipment) {
    return dataItem.purchaseOrderStatus === PurchaseOrderStatus.PENDING
      ? `/dashboard/purchases/custom/purchase-order/${dataItem.purchaseOrderKey}`
      : `/dashboard/purchases/po-vault/detail/${dataItem.purchaseOrderKey}`;
  }

  linkToShipment(dataItem) {
    return dataItem.status === ShipmentStatus.PENDING
      ? `/dashboard/shipments/create/${dataItem.shipmentKey}`
      : `/dashboard/shipments/manage-shipments/detail/${dataItem.shipmentKey}`;
  }

  private getIsCellUnclickable(dataItem: any, field: string) {
    const shipmentCondition =
      [GridName.Shipment, GridName.ShipmentAWD].includes(this.itemName) &&
      field !== ShipmentField.refNum &&
      [
        ShipmentStatus.CANCELED,
        ShipmentStatus.DELETED,
        ShipmentStatus.CLOSED,
        ShipmentStatus.SHIPPED,
      ].includes(dataItem.status);

    const refNumCondition =
      [GridName.Shipment, GridName.ShipmentAWD].includes(this.itemName) &&
      field === ShipmentField.refNum;
    const shipmentIdCondition =
      this.itemName === GridName.PurchaseOrder &&
      field === PurchaseOrderField.shipmentId;

    const alreadyLinkedToShipmentCondition =
      [GridName.Supply, GridName.PurchaseOrderItem].includes(this.itemName) &&
      dataItem.shipmentKey && !this.isSticker;

    return (
      shipmentCondition ||
      refNumCondition ||
      shipmentIdCondition ||
      alreadyLinkedToShipmentCondition
    );
  }
}
