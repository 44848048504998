import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { State } from '@progress/kendo-data-query';
import { BaseComponent, MODAL_DATA } from '@stockaid/core';
import JsBarcode from 'jsbarcode';
import jspdf from 'jspdf';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import {
  ItemField,
  PurchaseOrderItemField,
} from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { Item } from 'src/app/core/models/item';
import { ItemService } from 'src/app/core/services/item.service';
import { MetadataService } from 'src/app/core/services/metadata.service';
import {
  OptionalDatas,
  PrintOptions,
  StickerFormats,
} from 'src/app/routes/dashboard/shipments/steps/complete/sticker.constants';
import { asyncScheduler, Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { MetaData } from 'src/app/core/infrastructure/classes/meta-data';
import { conditionalOperator, sanatizeText } from '@stockaid/utils';
import { Supply } from 'src/app/core/models/supply';
import { SupplyService } from 'src/app/core/services/supply.service';
import { GridName } from '../forecast-rxdata-table/forcast-rxdata-table.constant';
import { Logic } from 'src/app/core/infrastructure/enums/logic.enum';
import { PurchaseOrderItemService } from 'src/app/core/services/purchase-order-item.service';

@Component({
  selector: 'app-print-stickers-modal',
  templateUrl: './print-stickers-modal.component.html',
  styleUrls: ['./print-stickers-modal.component.scss'],
})
export class PrintStickersModalComponent
  extends BaseComponent
  implements OnInit
{
  readonly OptionalDatas = OptionalDatas;
  readonly StickerFormats = StickerFormats;
  readonly PrintOptions = PrintOptions;
  shouldShowCustomText1 = false;
  shouldShowCustomText2 = false;
  shouldShowCustomSize = false;
  isModifySticker = true;
  isLoading: boolean = false;
  isPrintLoading: boolean = false;

  modifySticker = 'Modify Sticker Quantities';
  stickerForm: FormGroup;
  metadataFields: MetaDataField[] = [];
  itemRef: Item = new Item();
  supplyRef: Supply = new Supply();
  itemsKeySelected: string[] = [];
  itemsSticker: any;

  @ViewChild('grid') public grid;

  gridState: State = {
    skip: 0,
    take: 100,
    sort: [],
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  readonly GridName = GridName;

  private willAddPage = false;

  get purchaseOrderKey(): string {
    return this.data?.purchaseOrderKey;
  }

  get optionalData1(): AbstractControl {
    return this.stickerForm?.controls?.optionalData1;
  }

  get customText1(): AbstractControl {
    return this.stickerForm?.controls?.customText1;
  }

  get optionalData2(): AbstractControl {
    return this.stickerForm?.controls?.optionalData2;
  }

  get customText2(): AbstractControl {
    return this.stickerForm?.controls?.customText2;
  }

  get stickerFormat(): AbstractControl {
    return this.stickerForm?.controls?.stickerFormat;
  }

  get customWidth(): AbstractControl {
    return this.stickerForm?.controls?.customWidth;
  }

  get customHeight(): AbstractControl {
    return this.stickerForm?.controls?.customHeight;
  }

  get printOption(): AbstractControl {
    return this.stickerForm?.controls?.printOption;
  }

  get printRequiredStickerOnly(): AbstractControl {
    return this.stickerForm?.controls?.printRequiredStickerOnly;
  }

  readonly additionalReadOnlyCols = [ItemField.name, ItemField.description];

  constructor(
    public activeModal: NgbActiveModal,
    public itemService: ItemService,
    public supplyService: SupplyService,
    private fb: FormBuilder,
    private metadataService: MetadataService,
    private purchaseOrderItemService: PurchaseOrderItemService,
    @Inject(MODAL_DATA) private data: any
  ) {
    super();
  }

  ngOnInit() {
    this.initStickerForm();
    this.loadGrid().subscribe();
  }

  loadGrid(): Observable<MetaData> {
    if (this.data.selectedRows) {
      this.data.selectedRows.forEach((value) => {
        this.itemsKeySelected.push(value['key'] || value['itemKey']);
      });
      this.gridState = this.data.gridState;
    }

    return this.metadataService
      .getPrintStickerMetaData(!!this.purchaseOrderKey)
      .pipe(
        this.autoCleanUp(),
        tap((metadata) => {
          this.metadataFields = metadata.fields;
        })
      );
  }

  generateBarcode(): void {
    JsBarcode('#barcode', 'FNSKU', {
      width: 1.5,
      height: 50,
      fontSize: 14,
      font: 'sans-serif',
      displayValue: false,
    });
  }

  search(searchTerm: string) {
    if (!searchTerm) {
      return;
    }

    if (this.purchaseOrderKey) {
      let filterSearch = [
        {
          filters: [
            {
              field: PurchaseOrderItemField.hasSticker,
              operator: 'eq',
              value: true,
            },
            {
              field: PurchaseOrderItemField.itemName,
              operator: 'contains',
              value: searchTerm,
            },
          ],
          logic: Logic.and,
        },
      ];
      this.gridState.filter.filters = filterSearch;
      this.grid.gridState = this.gridState;
    } else {
      this.itemsSticker = this.itemsSticker?.filter((item) => {
        const itemName = item?.name?.toLowerCase();
        return itemName?.includes(searchTerm?.toLowerCase());
      });
    }

    this.grid.loadGridItems(true);
  }

  clearSearch(searchTerm: string) {
    if (!searchTerm) {
      this.itemsSticker = null;
      this.grid.loadGridItems(true);
    }
  }

  handleModify(): void {
    this.isModifySticker = !this.isModifySticker;

    if (!this.isModifySticker) {
      this.modifySticker = 'See Preview';
    } else {
      this.modifySticker = 'Modify Sticker Quantities';
    }
  }

  handleOptionalData1Change(): void {
    switch (this.optionalData1.value) {
      case 'customText':
        this.customText1.setValidators([Validators.required]);
        this.shouldShowCustomText1 = true;
        break;

      case 'blank':
      default:
        this.optionalData1.value === 'blank' &&
          this.optionalData2.setValue('blank');
        this.customText1.setValidators([]);
        this.customText1.setValue('');
        this.shouldShowCustomText1 = false;
    }

    this.generateBarcode();
  }

  handleOptionalData2Change(): void {
    if (this.optionalData2.value === 'customText') {
      this.customText2.setValidators([Validators.required]);
      this.shouldShowCustomText2 = true;
    } else {
      this.customText2.setValidators([]);
      this.customText2.setValue('');
      this.shouldShowCustomText2 = false;
    }

    this.generateBarcode();
  }

  handleStickerFormatChange(): void {
    if (this.stickerFormat.value === 'customSize') {
      this.customWidth.setValidators([Validators.required, Validators.min(45)]);
      this.customHeight.setValidators([
        Validators.required,
        Validators.min(25.4),
      ]);
      this.shouldShowCustomSize = true;
    } else {
      this.customWidth.setValidators([]);
      this.customHeight.setValidators([]);
      this.shouldShowCustomSize = false;
    }
  }

  get optionalBarcodeText1() {
    return this.onResolveStringText(
      this.shouldShowCustomText1
        ? sanatizeText(this.customText1.value)
        : OptionalDatas[this.optionalData1.value],
      this.customText1.value?.split(' ')?.length > 1 ? 'description' : 'text'
    );
  }

  get optionalBarcodeText2() {
    return this.onResolveStringText(
      this.shouldShowCustomText2
        ? sanatizeText(this.customText2.value)
        : OptionalDatas[this.optionalData2.value],
      this.customText2.value?.split(' ')?.length > 1 ? 'description' : 'text'
    );
  }

  itemsStickerChange(itemsSticker: any) {
    this.itemsSticker = itemsSticker;
  }

  downloadPDF(download = true) {
    this.isLoading = download;
    this.isPrintLoading = !download;
    let {
      imgWidth,
      imgHeight,
      pageFormat,
      maxStickerNumber,
      orientation,
      stickerSpaceHeight,
    } = this.constructStickerInfo();

    imgHeight -= ['_27_1', '_30_66'].includes(this.stickerFormat.value) ? 0 : 9;

    asyncScheduler.schedule(() => {
      const optionalDataMappings = {
        blank: '',
        sku: 'name',
        asin: 'asin',
        upc: 'upc',
        ean: 'ean',
      };
      const pdf = new jspdf({
        orientation,
        unit: 'mm',
        format: pageFormat,
        compress: true,
      });
      pdf.setLineDashPattern([5, 2, 2, 2], 0);
      pdf.setFontSize(9);

      const { maxStickerPerRow, maxStickerPerColumn } =
        this.constructMaxSticker(maxStickerNumber);

      let spaceWidth =
        ((pageFormat as number[])[0] - maxStickerPerRow * imgWidth) /
        (maxStickerPerRow + 1);
      let spaceHeight = conditionalOperator(
        maxStickerNumber === 1,
        0,
        ((pageFormat as number[])[1] -
          maxStickerPerColumn * (imgHeight + stickerSpaceHeight)) /
          2
      );

      let initialSpaceWidth = 0;
      let initialSpaceHeight = 0;
      if (['_27_1', '_30_66'].includes(this.stickerFormat.value)) {
        initialSpaceWidth = 4.7625;
        initialSpaceHeight = 12.7;
        spaceWidth = 3.175;
      }

      this.printStickers({
        spaceWidth,
        spaceHeight,
        initialSpaceWidth,
        initialSpaceHeight,
        optionalDataMappings,
        imgWidth,
        imgHeight,
        maxStickerPerRow,
        maxStickerPerColumn,
        pdf,
        stickerSpaceHeight,
      })
        .pipe(
          this.autoCleanUp(),
          first(),
          tap(() => {
            if (download) {
              const today = formatDate(
                new Date(),
                "MM-dd-yyyy_hh-mm-aaaaa'm'",
                'en'
              );
              let fileName = `shipments-${today.toUpperCase()}`;

              pdf.save(fileName);
              this.isLoading = false;
            } else {
              let embed =
                "<embed width='100%' height='100%' src='" +
                pdf.output('bloburl') +
                "'/>";
              let x = window.open();
              x.document.open();
              x.document.write(embed);
              x.document.close();
              this.isPrintLoading = false;
            }
          })
        )
        .subscribe();
    });
  }

  onResolveStringText(
    stringText: string,
    type: string,
    width?: number
  ): string {
    const stringTextLength = stringText?.length;
    let totalCut: number = 16;
    let limitLength: number = 22;

    if (width < 60) {
      totalCut = 12;
      limitLength = 16;
    }

    if (!stringTextLength) {
      return '';
    }
    if (stringTextLength <= limitLength) {
      return stringText;
    }

    switch (type) {
      case 'description': {
        const hasParentheses = stringText.indexOf('(') > -1;
        let descriptions = hasParentheses
          ? stringText.substring(stringText.indexOf('('), stringTextLength)
          : '';

        descriptions = this.findLongestLastPartOfDesc(
          hasParentheses,
          stringText,
          limitLength,
          totalCut,
          descriptions
        );

        const endingEllipsis = hasParentheses ? '...)' : '...';
        const descriptionRest = `...${
          descriptions.length > limitLength
            ? descriptions.substring(0, totalCut) + endingEllipsis
            : descriptions
        }`;

        return `${stringText.substring(0, totalCut)}${descriptionRest}`.trim();
      }

      case 'text': {
        let stringSplit = stringText.split('');
        if (stringSplit.length > totalCut * 2) {
          stringSplit = [...stringSplit].filter(
            (_, index) =>
              index <= totalCut || index > stringText.length - totalCut / 2
          );
          stringSplit.splice(totalCut + 1, 0, '...');
        }

        return `${stringSplit.join('')}`;
      }
    }
  }

  private initStickerForm(): void {
    this.stickerForm = this.fb.group({
      optionalData1: ['blank', [Validators.required]],
      customText1: [''],
      optionalData2: ['blank', [Validators.required]],
      customText2: [''],
      stickerFormat: ['_30_66', [Validators.required]],
      customWidth: [0],
      customHeight: [0],
      printOption: ['continuously', [Validators.required]],
      printRequiredStickerOnly: [true],
    });
  }

  private constructStickerInfo() {
    let imgWidth;
    let imgHeight;
    let pageFormat: string | number[];
    let maxStickerNumber = 1;
    let orientation: any = 'p';
    let stickerSpaceHeight = 0;
    switch (this.stickerFormat.value) {
      case '_24':
        imgWidth = 64.0;
        imgHeight = 33.9;
        pageFormat = [210, 297];
        maxStickerNumber = 24;
        stickerSpaceHeight = 12;
        break;

      case '_27_1':
        imgWidth = 66.675;
        imgHeight = 25.4;
        pageFormat = [215.9, 279.4];
        maxStickerNumber = 27;
        stickerSpaceHeight = 3.175;
        break;

      case '_30_66':
        imgWidth = 66.675;
        imgHeight = 25.4;
        pageFormat = [215.9, 279.4];
        maxStickerNumber = 30;
        break;

      case '_50':
        imgWidth = 50.0;
        imgHeight = 30.0;
        pageFormat = [imgWidth, imgHeight + 3];
        orientation = 'l';
        break;

      case 'customSize':
        imgWidth = this.customWidth.value;
        imgHeight = this.customHeight.value;
        pageFormat = [imgWidth, imgHeight + 3];
        orientation = imgWidth > imgHeight ? 'l' : 'p';
        break;
    }

    return {
      imgWidth,
      imgHeight,
      pageFormat,
      maxStickerNumber,
      orientation,
      stickerSpaceHeight,
    };
  }

  private constructMaxSticker(maxStickerNumber: number) {
    let maxStickerPerRow;
    let maxStickerPerColumn;
    switch (maxStickerNumber) {
      case 1:
        maxStickerPerRow = 1;
        maxStickerPerColumn = 1;
        break;

      case 24:
        maxStickerPerRow = 3;
        maxStickerPerColumn = 8;
        break;

      case 27:
        maxStickerPerRow = 3;
        maxStickerPerColumn = 9;
        break;

      case 30:
        maxStickerPerRow = 3;
        maxStickerPerColumn = 10;
    }

    if (this.printOption.value === 'own') {
      maxStickerPerRow = 1;
      maxStickerPerColumn = 1;
    }

    return {
      maxStickerPerRow,
      maxStickerPerColumn,
    };
  }

  private printBarcodeTexts({
    pdf,
    barcodeValue1,
    barcodeValue2,
    xCoordinate,
    yCoordinate,
    imgWidth,
    imgHeight,
  }) {
    pdf.text(
      `${barcodeValue1}`,
      xCoordinate + imgWidth / 2,
      yCoordinate +
        imgHeight +
        (['_27_1', '_30_66'].includes(this.stickerFormat.value) ? 8 : 5),
      { align: 'center' }
    );
    !['_27_1', '_30_66'].includes(this.stickerFormat.value) &&
      pdf.text(
        `${barcodeValue2}`,
        xCoordinate + imgWidth / 2,
        yCoordinate + imgHeight + 8,
        { align: 'center' }
      );
  }

  private printSpacing({
    itemPrint,
    idx,
    currentStickerNumberOnRow,
    currentStickerNumberOnCol,
    xCoordinate,
    yCoordinate,
    spaceWidth,
    spaceHeight,
    initialSpaceWidth,
    initialSpaceHeight,
    imgHeight,
    maxStickerPerRow,
    maxStickerPerColumn,
    stickerSpaceHeight,
  }) {
    let willAddPage = false;
    if (currentStickerNumberOnRow === maxStickerPerRow) {
      currentStickerNumberOnCol++;
      currentStickerNumberOnRow = 0;
      xCoordinate = initialSpaceWidth || spaceWidth;
      yCoordinate += imgHeight + stickerSpaceHeight;
    }
    if (currentStickerNumberOnCol === maxStickerPerColumn) {
      currentStickerNumberOnCol = 0;
      yCoordinate = initialSpaceHeight || spaceHeight;
      if (idx !== itemPrint.length) {
        willAddPage = true;
      }
    }

    return {
      currentStickerNumberOnRow,
      currentStickerNumberOnCol,
      xCoordinate,
      yCoordinate,
      willAddPage,
    };
  }

  private printStickers({
    spaceWidth,
    spaceHeight,
    initialSpaceWidth,
    initialSpaceHeight,
    optionalDataMappings,
    imgWidth,
    imgHeight,
    maxStickerPerRow,
    maxStickerPerColumn,
    pdf,
    stickerSpaceHeight,
  }) {
    const options = {
      spaceWidth,
      spaceHeight,
      initialSpaceWidth,
      initialSpaceHeight,
      xCoordinate: initialSpaceWidth || spaceWidth,
      yCoordinate: initialSpaceHeight || spaceHeight,
      currentStickerNumberOnRow: 0,
      currentStickerNumberOnCol: 0,
      bigIdx: 0,
    };

    const printRequiredStickerOnly = this.printRequiredStickerOnly.value;
    const hasStickerFilter = printRequiredStickerOnly
      ? {
          filter: {
            logic: 'and' as Logic,
            filters: [{ field: 'hasSticker', operator: 'eq', value: true }],
          },
        }
      : {};

    const item$ = this.purchaseOrderKey
      ? this.purchaseOrderItemService.getPurchaseOrderItemsByPOKey(
          this.purchaseOrderKey,
          { take: 1000, ...hasStickerFilter },
          null,
          null,
          this.optionalData1.value === 'supplierSku' ||
            this.optionalData2.value === 'supplierSku'
        )
      : of(
          this.itemsSticker?.filter(
            (item) => item.hasSticker || !printRequiredStickerOnly
          ) || []
        );

    return item$.pipe(
      this.autoCleanUp(),
      tap((itemPrint: any) => {
        for (const [idx, i] of itemPrint.entries()) {
          const barcodeValue1 = this.onResolveStringText(
            conditionalOperator(
              this.optionalData1.value === 'customText',
              sanatizeText(this.customText1.value),
              i[optionalDataMappings[this.optionalData1.value]] || ''
            ),
            this.customText1.value?.split(' ')?.length > 1
              ? 'description'
              : 'text',
            imgWidth
          );

          const barcodeValue2 = this.onResolveStringText(
            conditionalOperator(
              this.optionalData2.value === 'customText',
              sanatizeText(this.customText2.value),
              i[optionalDataMappings[this.optionalData2.value]] || ''
            ),
            this.customText2.value?.split(' ')?.length > 1
              ? 'description'
              : 'text',
            imgWidth
          );

          const barcodeOption = ['_27_1', '_30_66'].includes(
            this.stickerFormat.value
          )
            ? {
                margin: 0,
                marginBottom: 10,
              }
            : {};

          JsBarcode('#temp-barcode', `${i.fnsku || i.asin || i.name}`, {
            width: 1,
            height: 40,
            fontSize: 100,
            textMargin: 0,
            displayValue: false,
            ...barcodeOption,
          });

          this.printStickerForItem({
            options,
            idx,
            barcodeValue1,
            barcodeValue2,
            imgWidth,
            imgHeight,
            maxStickerPerRow,
            maxStickerPerColumn,
            pdf,
            stickerSpaceHeight,
            i,
            itemPrint,
          });
        }

        this.willAddPage = false;
      })
    );
  }

  private printStickerForItem({
    options,
    idx,
    barcodeValue1,
    barcodeValue2,
    imgWidth,
    imgHeight,
    maxStickerPerRow,
    maxStickerPerColumn,
    pdf,
    stickerSpaceHeight,
    i,
    itemPrint,
  }) {
    for (let stickerTurn = 0; stickerTurn < i.stickerQty; stickerTurn++) {
      if (this.willAddPage) {
        pdf.addPage();
        this.willAddPage = false;
      }

      if (
        this.printOption.value === 'blank' &&
        options.bigIdx % 2 !== 0 &&
        maxStickerPerRow !== 1 &&
        maxStickerPerColumn !== 1
      ) {
        options.currentStickerNumberOnRow++;
        options.xCoordinate += imgWidth + options.spaceWidth;
      }

      const wrapper = document.getElementById('barcode-wrapper');
      const img = document.getElementById('temp-barcode') as HTMLImageElement;
      wrapper.classList.remove('hidden');

      pdf.addImage(
        img.src,
        'JPEG',
        options.xCoordinate + 2,
        options.yCoordinate + 2,
        imgWidth - 4,
        ['_27_1', '_30_66'].includes(this.stickerFormat.value)
          ? imgHeight - 10 - 4
          : imgHeight - 4,
        '',
        'FAST'
      );
      pdf.text(
        `${i.fnsku ? i.fnsku : ''}    ${i.condition ? i.condition : ''}`,

        options.xCoordinate + imgWidth / 2,
        options.yCoordinate +
          (['_27_1', '_30_66'].includes(this.stickerFormat.value)
            ? imgHeight - 10
            : imgHeight - 1),
        { align: 'center' }
      );

      const description = this.onResolveStringText(
        sanatizeText(i.description),
        i.description?.split(' ')?.length > 1 ? 'description' : 'text',
        imgWidth
      );
      pdf.text(
        description,
        options.xCoordinate + imgWidth / 2,
        options.yCoordinate +
          (['_27_1', '_30_66'].includes(this.stickerFormat.value)
            ? imgHeight - 10 + 4
            : imgHeight + 2),
        { align: 'center' }
      );

      this.printBarcodeTexts({
        pdf,
        barcodeValue1,
        barcodeValue2,
        xCoordinate: options.xCoordinate,
        yCoordinate: options.yCoordinate,
        imgWidth,
        imgHeight: ['_27_1', '_30_66'].includes(this.stickerFormat.value)
          ? imgHeight - 10
          : imgHeight,
      });

      options.currentStickerNumberOnRow++;
      options.xCoordinate += imgWidth + options.spaceWidth;

      const spacingResult = this.printSpacing({
        itemPrint,
        idx,
        currentStickerNumberOnRow: options.currentStickerNumberOnRow,
        currentStickerNumberOnCol: options.currentStickerNumberOnCol,
        xCoordinate: options.xCoordinate,
        yCoordinate: options.yCoordinate,
        spaceWidth: options.spaceWidth,
        spaceHeight: options.spaceHeight,
        initialSpaceWidth: options.initialSpaceWidth,
        initialSpaceHeight: options.initialSpaceHeight,
        imgHeight,
        maxStickerPerRow,
        maxStickerPerColumn,
        stickerSpaceHeight,
      });

      options.currentStickerNumberOnRow =
        spacingResult.currentStickerNumberOnRow;
      options.currentStickerNumberOnCol =
        spacingResult.currentStickerNumberOnCol;
      options.xCoordinate = spacingResult.xCoordinate;
      options.yCoordinate = spacingResult.yCoordinate;
      this.willAddPage = spacingResult.willAddPage;

      if (idx === itemPrint.length - 1) {
        wrapper.classList.add('hidden');
      }

      options.bigIdx++;
    }
  }

  private findLongestLastPartOfDesc(
    hasParentheses,
    stringText,
    limitLength,
    totalCut,
    descriptions
  ) {
    let tempDesc = descriptions;
    const stringTextLength = stringText.length;
    if (!hasParentheses) {
      let whitespaceIndex = stringText.lastIndexOf(' ');
      while (tempDesc.length <= limitLength - totalCut) {
        tempDesc = stringText
          .substring(
            stringText.lastIndexOf(' ', whitespaceIndex--),
            stringTextLength
          )
          .substring(1);
      }
    }

    return tempDesc;
  }
}
