<div #pickList class="shipment-pick-list">
  <div
    class="shipment-pick-list__title"
    [class.shipment-pick-list__title--maximized]="!printListOptions.isMinified"
  >
    <h4>Shipment Pick List</h4>
    <hr />
  </div>
  <div class="shipment-pick-list__overall">
    <div class="shipment-pick-list__info mr-2">
      <div>
        <h6>
          Shipment ID: <span>{{ shipmentId }}</span>
        </h6>
        <h6>
          Name: <span>{{ shipmentName }}</span>
        </h6>
        <h6>
          Label: <span>{{ label }}</span>
        </h6>
      </div>

      <div class="mt-2">
        <h6>
          Total SKUs: <span>{{ selectedShipment?.totalSKUs || 0 }}</span>
        </h6>
        <h6>
          Total Units: <span>{{ selectedShipment?.totalUnits || 0 }}</span>
        </h6>
      </div>
    </div>
    <div class="shipment-pick-list__info ml-2">
      <div class="container">
        <div class="row mb-1">
          <div class="col-6"><h6>Ship From:</h6></div>
          <div class="col-6"><h6>Ship To:</h6></div>
        </div>
        <div class="row my-1">
          <div class="col-6">
            {{ selectedShipment?.shipFromAddress?.fullName || selectedShipment?.shipmentSource }}
          </div>
          <div class="col-6">
            {{ selectedShipment?.destinationFulfillmentCenterId }}
          </div>
        </div>
        <div class="row my-1">
          <div class="col-6">
            {{ selectedShipment?.shipFromAddress?.addressLine1 }}
          </div>
          <div class="col-6">
            {{ selectedShipment?.shipToAddress?.addressLine1 }}
          </div>
        </div>
        <div class="row my-1">
          <div class="col-6">
            {{ selectedShipment?.shipFromAddress?.city }}
            {{ selectedShipment?.shipFromAddress?.stateOrProvinceCode }}
            {{ selectedShipment?.shipFromAddress?.postalCode }}
            {{ selectedShipment?.shipFromAddress?.countryCode }}
          </div>
          <div class="col-6">
            <ng-container *ngIf="selectedShipment?.shipToAddress?.addressLine2; else altAddress">
              {{ selectedShipment?.shipToAddress?.addressLine2 }}
              {{ selectedShipment?.shipToAddress?.countryCode }}
            </ng-container>

            <ng-template #altAddress>
              {{ selectedShipment?.shipToAddress?.city }}
              {{ selectedShipment?.shipToAddress?.stateOrProvinceCode }}
              {{ selectedShipment?.shipToAddress?.postalCode }}
              {{ selectedShipment?.shipToAddress?.countryCode }}
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedShipment">
    <app-forecast-rxdata-table
      #forecastTable
      *ngIf="metadataFields?.length"
      itemName="Amazon Shipment Item"
      [modelType]="shipmentDetailRef"
      [resourceService]="shipmentDetailService"
      [columns]="metadataFields"
      [isViewOnly]="true"
      [addIsVisible]="false"
      [deleteIsVisible]="false"
      [bulkMngtIsVisible]="false"
      [isExtraExportVisible]="false"
      [saveGridStateIsVisible]="false"
      [toogleFullScreenIsVisible]="false"
      [additionalReadOnlyCols]="additionalReadOnlyCols"
      [selectingItem]="selectedShipment"
      [isPrintList]="true"
      keyName="shipmentItemKey"
    ></app-forecast-rxdata-table>
  </div>

  <div
    class="shipment-pick-list__button-area"
    *ngIf="printListOptions.isMinified"
  >
    <button
      class="btn btn-light shipment-pick-list__close-btn"
      (click)="closeModal()"
      [disabled]="isLoading"
    >
      <i class="fa fa-times"></i>
      &nbsp; Close
    </button>

    <button
      class="btn btn-primary shipment-pick-list__print-btn"
      (click)="newOnPrintPickList()"
      [disabled]="isLoading"
    >
      <i class="fa fa-print"></i>
      &nbsp; Print
    </button>
  </div>
</div>

<div class="shipment-pick-list__processing" *ngIf="isLoading">
  <div class="spinner-border text-light" role="status">
    <span class="sr-only"></span>
  </div>
  <p>Printing...</p>
</div>
