import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import JsBarcode from 'jsbarcode';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { CompanyService } from 'src/app/core/services/company.service';
import { ShipmentService } from 'src/app/core/services/shipment.service';
import { ShipmentDetailService } from 'src/app/core/services/shipment-detail.service';
import {
  AuthenticationService,
  BaseComponent,
  ModalService,
} from '@stockaid/core';
import {
  OptionalDatas,
  StickerFormats,
  PrintOptions,
} from './sticker.constants';
import jspdf from 'jspdf';
import { formatDate } from '@angular/common';
import { SyncService } from 'src/app/core/services/sync.service';
import { PusherService } from 'src/app/core/services/pusher.service';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import { ShipmentDetailField } from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { ShipmentDetail } from 'src/app/core/models/shipment-detail';
import { State } from '@progress/kendo-data-query';
import { Logic } from 'src/app/core/infrastructure/enums/logic.enum';
import { ForecastRXDataTableComponent } from 'src/app/theme/shared/forecastui/forecast-rxdata-table/forecast-rxdata-table.component';
import { ImportFailModalComponent } from 'src/app/theme/shared/forecastui/import-fail-modal/import-fail-modal.component';
import Swal from 'sweetalert2';
import { UpdateSPOCompanyModalComponent } from 'src/app/theme/shared/components/modal/spo-company-modal/update-spo-company-modal.component';
import {
  ColumnComponent,
  ColumnReorderEvent,
} from '@progress/kendo-angular-grid';
import { GridState } from 'src/app/core/infrastructure/classes/grid-state';
import { User } from 'src/app/core/models/user';
import { IUserResponse } from 'src/app/core/infrastructure/interfaces/user-response.interface';
import { UserService } from 'src/app/core/services/user.service';
import {
  conditionalOperator,
  mapColumnStateToField,
  reorderColumnsByFields,
  reorderFields,
  reorderFieldsByColumns,
  sanatizeText,
  updateColumnsVisible,
  updateFieldsVisible,
} from '@stockaid/utils';
import { GridStateColumn } from 'src/app/core/infrastructure/classes/grid-state-column';
import { MetaData } from 'src/app/core/infrastructure/classes/meta-data';
import { GridStateColumnSort } from 'src/app/core/infrastructure/classes/grid-state-column-sort';
import { GridStatePagination } from 'src/app/core/infrastructure/classes/grid-state-pagination';
import { Shipment } from 'src/app/core/models/shipment';
import { ShipmentDetailType } from '@stockaid/shared-enums';
import { GridName } from 'src/app/theme/shared/forecastui/forecast-rxdata-table/forcast-rxdata-table.constant';
import { RestockType } from 'src/app/core/infrastructure/enums/restock-type.enum';
import { PurchaseOrder } from 'src/app/core/models/purchase-order';
import { PurchaseOrderItemService } from 'src/app/core/services/purchase-order-item.service';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss'],
})
export class CompleteComponent extends BaseComponent implements OnInit {
  @ViewChild(ForecastRXDataTableComponent) private grid;

  @Input() restock: Shipment;
  @Input() restockKey: string;
  @Input() isPurchaseOrder: boolean;
  @Input() currentUser: User;
  @Input() set metadata(metadata: MetaData) {
    this.userGridState = this.currentUser?.gridStateShipmentSumary;
    if (
      !Object.keys(this.userGridState || {})?.length ||
      !this.userGridState?.columns?.length
    ) {
      this.initState(metadata?.fields);
    }

    this.metadataFields = metadata?.fields;

    this.metadataFields = mapColumnStateToField(
      metadata?.fields,
      this.userGridState?.columns
    );

    this.metadataFields = reorderFieldsByColumns(
      this.metadataFields,
      this.userGridState?.columns
    );

    this.userMetaDataFields = this.metadataFields;

    this.gridStateShipmentComplete = this.userGridState;
    const gridState = {
      skip: 0,
      take: 100,
      sort: [],
      filter: {
        logic: 'and',
        filters: [],
      },
    };

    const itemFilters = [];
    this.gridStateShipmentComplete?.columns
      ?.filter((col) => {
        return Object.getOwnPropertyNames(new ShipmentDetail()).includes(
          col.name
        );
      })
      .forEach((col) => {
        if (col.sort) {
          gridState?.sort?.push({
            field: col.name,
            dir: col.sort?.direction as 'asc' | 'desc',
          });
        }

        if (col.filters?.filters?.length) {
          itemFilters.push({
            filters: col.filters.filters,
            logic: col.filters?.logic,
          });
        }
      });

    gridState.filter = {
      logic: Logic.and,
      filters: itemFilters?.length ? itemFilters : this.filters,
    };
    this.gridState = gridState as State;
  }

  @Output() backClick: EventEmitter<void> = new EventEmitter();

  readonly OptionalDatas = OptionalDatas;
  readonly StickerFormats = StickerFormats;
  readonly PrintOptions = PrintOptions;
  readonly additionalReadOnlyCols = [
    ShipmentDetailField.itemName,
    ShipmentDetailField.description,
  ];
  readonly GridName = GridName;
  readonly RestockType = RestockType;

  shouldLoadGrid: Subject<any> = new Subject();
  stickerForm: FormGroup;
  subtractQuantityForm: FormGroup;
  shouldShowCustomText1 = false;
  shouldShowCustomText2 = false;
  shouldShowCustomSize = false;
  isModifySticker = false;
  modifySticker = 'Modify Sticker Quantities';
  isDownloading: boolean = false;
  isPrinting: boolean = false;
  metadataFields: MetaDataField[] = [];
  userMetaDataFields: MetaDataField[] = [];
  shipmentDetailRef = new ShipmentDetail();
  isProcessing: boolean = false;
  header: string;
  message: string;
  isProcessFinished: boolean = true;
  hasCreatedShipment: boolean = false;

  gridStateShipmentComplete: GridState = new GridState();
  userGridState: GridState;

  filters = [
    {
      filters: [
        { field: ShipmentDetailField.hasSticker, operator: 'eq', value: true },
      ],
      logic: Logic.and,
    },
  ];

  gridState: State;
  shouldShowMaintenanceMessage = false;

  private willAddPage = false;

  constructor(
    public shipmentDetailService: ShipmentDetailService,
    private fb: FormBuilder,
    private shipmentService: ShipmentService,
    private companyService: CompanyService,
    private syncService: SyncService,
    private pusherService: PusherService,
    private modalService: ModalService,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private purchaseOrderItemService: PurchaseOrderItemService
  ) {
    super();
  }

  processShipmentNotification(data: any): void {
    this.isProcessFinished = false;
    this.syncService
      .ascTestConnection(this.currentCompany)
      .pipe(this.autoCleanUp())
      .subscribe(({ isConnectionOK, shouldShowMaintenanceMessage }) => {
        if (!isConnectionOK && !shouldShowMaintenanceMessage) {
          this.modalService.open(UpdateSPOCompanyModalComponent, {
            data: {
              company: this.currentCompany,
            },
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
        }
      });
    this.modalService.open(ImportFailModalComponent, {
      data: {
        message: data,
        errorTitle: 'Invalid Shipment IDs',
      },
      backdrop: 'static',
      keyboard: false,
    });
  }

  ngOnInit(): void {
    if (!this.isPurchaseOrder) {
      this.syncService
        .getAscShouldMaintain()
        .pipe(
          this.autoCleanUp(),
          tap(
            ({ shouldShowMaintenanceMessage }) =>
              (this.shouldShowMaintenanceMessage = shouldShowMaintenanceMessage)
          )
        )
        .subscribe();
    }

    this.shouldLoadGrid.pipe(this.autoCleanUp()).subscribe();

    this.initStickerForm();
    this.initSubtractQuantityForm();

    this.pusherService.userChannel.bind('notification', (data: any) => {
      switch (data.type) {
        case 'header':
          this.header = data.msg;
          break;

        case 'title':
          this.message = data.msg;
          break;

        case 'message':
          this.message = data.msg;
          break;

        case 'notice':
          this.isProcessing = false;
          if (!this.isProcessFinished) {
            break;
          }

          if (data.key === 'shipmentStatus') {
            this.processShipmentNotification(data);
          }

          break;
        case 'close':
          if (
            ['syncStatus', 'mwsSyncStart', 'mwsReportSyncStart'].includes(
              data.key
            )
          ) {
            this.router.navigate([
              '/dashboard',
              'shipments',
              'manage-shipments',
            ]);
            break;
          }

          if (!this.isProcessFinished) {
            break;
          }

          if (
            data.key === 'shipmentStatus' &&
            this.restockKey &&
            this.hasCreatedShipment
          ) {
            this.isProcessFinished = false;
            this.shipmentService
              .completeShipment(this.restockKey)
              .pipe(
                this.autoCleanUp(),
                switchMap(() =>
                  this.syncService.companySync({
                    ...this.currentCompany,
                    isOnlySyncReport: true,
                  })
                )
              )
              .subscribe();
            break;
          }
      }
    });
  }

  initState(metaData: MetaDataField[]): void {
    this.gridStateShipmentComplete.columns = [];
    metaData?.forEach((metaDataField) => {
      const column = new GridStateColumn();

      column.name = metaDataField.field;
      column.visible = metaDataField.initialUpload;
      delete column.sort;

      this.gridStateShipmentComplete?.columns?.push(column);
    });
    this.userGridState = this.gridStateShipmentComplete;
  }

  onStateChange(state): void {
    this.gridStateShipmentComplete?.columns?.forEach((column) => {
      const currentSort = state.sort?.find(
        (sortCol) => sortCol.field === column?.name
      );
      const currentFilter = state.filter?.filters.find(
        (fltr) => fltr?.filters[0]?.field === column.name
      );

      if (currentFilter) {
        column.filters = currentFilter;
      } else {
        delete column?.filters;
      }

      if (currentSort) {
        column.sort = new GridStateColumnSort({
          direction: currentSort.dir as string,
        });
      } else {
        delete column.sort;
      }

      return column;
    });

    this.gridStateShipmentComplete.pagination = new GridStatePagination({
      paginationCurrentPage: state.skip || 0,
      paginationPageSize: state.take || 100,
    });

    this.currentUser.gridStateShipmentComplete = this.gridStateShipmentComplete;

    this.userService.editUser(this.currentUser).subscribe();
  }

  onColumnChange(changedColumns: ColumnComponent[]): void {
    updateColumnsVisible(
      this.gridStateShipmentComplete?.columns,
      changedColumns
    );

    updateFieldsVisible(this.metadataFields, changedColumns);

    this.currentUser.gridStateShipmentComplete = this.gridStateShipmentComplete;

    this.userService.editUser(this.currentUser).subscribe();
  }

  onColumnReorder(column: ColumnReorderEvent): void {
    if (column.newIndex === 0) {
      return;
    }

    this.userMetaDataFields = reorderFields(this.userMetaDataFields, column);
    this.gridStateShipmentComplete.columns = reorderColumnsByFields(
      this.userMetaDataFields,
      this.gridStateShipmentComplete?.columns
    );

    this.currentUser.gridStateShipmentComplete = this.gridStateShipmentComplete;
    this.userService.editUser(this.currentUser).subscribe();
  }

  getCurrentUser(): Observable<IUserResponse> {
    return this.authService.getLoggedInUserFromSession().pipe(
      tap((user) => {
        const [currentUser] = user?.model || [];
        this.currentUser = currentUser;
      })
    );
  }

  get currentCompany() {
    return this.companyService.currentCompany();
  }

  get optionalData1(): AbstractControl {
    return this.stickerForm?.controls?.optionalData1;
  }

  get customText1(): AbstractControl {
    return this.stickerForm?.controls?.customText1;
  }

  get optionalData2(): AbstractControl {
    return this.stickerForm?.controls?.optionalData2;
  }

  get customText2(): AbstractControl {
    return this.stickerForm?.controls?.customText2;
  }

  get stickerFormat(): AbstractControl {
    return this.stickerForm?.controls?.stickerFormat;
  }

  get customWidth(): AbstractControl {
    return this.stickerForm?.controls?.customWidth;
  }

  get customHeight(): AbstractControl {
    return this.stickerForm?.controls?.customHeight;
  }

  get printOption(): AbstractControl {
    return this.stickerForm?.controls?.printOption;
  }

  get printRequiredStickerOnly(): AbstractControl {
    return this.stickerForm?.controls?.printRequiredStickerOnly;
  }

  get isSubtractQuantity(): AbstractControl {
    return this.subtractQuantityForm?.controls?.isSubtractQuantity;
  }

  search(searchTerm: string) {
    if (searchTerm) {
      let filterSearch = [
        {
          filters: [
            {
              field: ShipmentDetailField.hasSticker,
              operator: 'eq',
              value: true,
            },
            {
              field: ShipmentDetailField.itemName,
              operator: 'contains',
              value: searchTerm,
            },
          ],
          logic: Logic.and,
        },
      ];
      this.gridState.filter.filters = filterSearch;
      this.grid.gridState = this.gridState;
      this.grid.loadGridItems(true);
    }
  }

  clearSearch(searchTerm: string) {
    if (!searchTerm) {
      this.gridState.filter.filters = this.filters;
      this.grid.loadGridItems(true);
    }
  }

  handleModifySticker(): void {
    this.isModifySticker = !this.isModifySticker;

    if (this.isModifySticker) {
      this.modifySticker = 'See Preview';
    } else {
      this.modifySticker = 'Modify Sticker Quantities';
    }
  }

  handleOptionalData1Change(): void {
    switch (this.optionalData1.value) {
      case 'customText':
        this.customText1.setValidators([Validators.required]);
        this.shouldShowCustomText1 = true;
        break;

      case 'blank':
      default:
        this.optionalData1.value === 'blank' &&
          this.optionalData2.setValue('blank');
        this.customText1.setValidators([]);
        this.customText1.setValue('');
        this.shouldShowCustomText1 = false;
        break;
    }

    this.generateBarcode();
  }

  handleOptionalData2Change(): void {
    if (this.optionalData2.value === 'customText') {
      this.customText2.setValidators([Validators.required]);
      this.shouldShowCustomText2 = true;
    } else {
      this.customText2.setValidators([]);
      this.customText2.setValue('');
      this.shouldShowCustomText2 = false;
    }

    this.generateBarcode();
  }

  handleStickerFormatChange(): void {
    if (this.stickerFormat.value === 'customSize') {
      this.customWidth.setValidators([Validators.required, Validators.min(45)]);
      this.customHeight.setValidators([
        Validators.required,
        Validators.min(25.4),
      ]);
      this.shouldShowCustomSize = true;
    } else {
      this.customWidth.setValidators([]);
      this.customHeight.setValidators([]);
      this.shouldShowCustomSize = false;
    }
  }

  get optionalBarcodeText1() {
    return this.onResolveStringText(
      this.shouldShowCustomText1
        ? sanatizeText(this.customText1?.value)
        : OptionalDatas[this.optionalData1.value],
      this.customText1.value?.split(' ')?.length > 1 ? 'description' : 'text'
    );
  }

  get optionalBarcodeText2() {
    return this.onResolveStringText(
      this.shouldShowCustomText2
        ? sanatizeText(this.customText2?.value)
        : OptionalDatas[this.optionalData2.value],
      this.customText2.value?.split(' ')?.length > 1 ? 'description' : 'text'
    );
  }

  generateBarcode(): void {
    JsBarcode('#barcode', 'FNSKU', {
      width: 1.5,
      height: 50,
      fontSize: 14,
      font: 'sans-serif',
      displayValue: false,
    });
  }

  downloadPDF(purchaseOrder: PurchaseOrder, download = true): void {
    this.isDownloading = download;
    this.isPrinting = !download;
    let {
      imgWidth,
      imgHeight,
      pageFormat,
      maxStickerNumber,
      orientation,
      stickerSpaceHeight,
    } = this.constructStickerInfo();

    imgHeight -= ['_27_1', '_30_66'].includes(this.stickerFormat.value) ? 0 : 9;

    const printRequiredStickerOnly = this.printRequiredStickerOnly.value;
    const hasStickerFilter = printRequiredStickerOnly
      ? [{ field: 'hasSticker', operator: 'eq', value: true }]
      : [];
    const items$ = purchaseOrder
      ? this.purchaseOrderItemService.getPurchaseOrderItemsByPOKey(
          purchaseOrder.key,
          { take: 1000 }
        ).pipe(
          map((items) => items.map((i) => ({...i, stickerQty: 1})))
        )
      : this.shipmentDetailService.getShipmentDetailsByRestockKey(
          this.restockKey,
          ShipmentDetailType.forecastValid,
          conditionalOperator(this.isModifySticker, this.gridState, {
            skip: 0,
            take: 1000,
            sort: [],
            filter: {
              logic: 'and',
              filters: hasStickerFilter,
            },
          })
        );

    items$.pipe(this.autoCleanUp()).subscribe((items) => {
      const optionalDataMappings = {
        blank: '',
        sku: 'itemName',
        asin: 'asin',
        upc: 'upc',
        ean: 'ean',
      };
      const pdf = new jspdf({
        orientation,
        unit: 'mm',
        format: pageFormat,
        compress: true,
      });
      pdf.setLineDashPattern([5, 2, 2, 2], 0);
      pdf.setFontSize(9);

      const { maxStickerPerRow, maxStickerPerColumn } =
        this.constructMaxSticker(maxStickerNumber);

      let spaceWidth =
        ((pageFormat as number[])[0] - maxStickerPerRow * imgWidth) /
        (maxStickerPerRow + 1);
      let spaceHeight = conditionalOperator(
        maxStickerNumber === 1,
        0,
        ((pageFormat as number[])[1] -
          maxStickerPerColumn * (imgHeight + stickerSpaceHeight)) /
          2
      );

      let initialSpaceWidth = 0;
      let initialSpaceHeight = 0;
      if (['_27_1', '_30_66'].includes(this.stickerFormat.value)) {
        initialSpaceWidth = 4.7625;
        initialSpaceHeight = 12.7;
        spaceWidth = 3.175;
      }

      this.printStickers({
        spaceWidth,
        spaceHeight,
        initialSpaceWidth,
        initialSpaceHeight,
        items,
        optionalDataMappings,
        imgWidth,
        imgHeight,
        maxStickerPerRow,
        maxStickerPerColumn,
        pdf,
        stickerSpaceHeight,
      });

      if (download) {
        const today = formatDate(new Date(), "MM-dd-yyyy_hh-mm-aaaaa'm'", 'en');
        const fileName = this.isPurchaseOrder
          ? `purchase-order-${today.toUpperCase()}`
          : `shipments-${today.toUpperCase()}`;

        pdf.save(fileName);
        this.isDownloading = false;
      } else {
        const embed =
          "<embed width='100%' height='100%' src='" +
          pdf.output('datauristring') +
          "'/>";
        const x = window.open();
        x.document.open();
        x.document.write(embed);
        x.document.close();
        this.isPrinting = false;
      }
    });
  }

  onResolveStringText(
    stringText: string,
    type: string,
    width?: number
  ): string {
    const stringTextLength = stringText?.length;
    let totalCut: number = 16;
    let limitLength: number = 22;

    if (width < 60) {
      totalCut = 12;
      limitLength = 16;
    }

    if (!stringTextLength) {
      return '';
    }
    if (stringTextLength <= limitLength) {
      return stringText;
    }

    if (!stringTextLength) return '';
    switch (type) {
      case 'description': {
        const hasParentheses = stringText.indexOf('(') > -1;
        let descriptions = hasParentheses
          ? stringText.substring(stringText.indexOf('('), stringTextLength)
          : '';

        descriptions = this.findLongestLastPartOfDesc(
          hasParentheses,
          stringText,
          limitLength,
          totalCut,
          descriptions
        );

        const endingEllipsis = hasParentheses ? '...)' : '...';
        const descriptionRest = `...${
          descriptions.length > limitLength
            ? descriptions.substring(0, totalCut) + endingEllipsis
            : descriptions
        }`;

        return `${stringText.substring(0, totalCut)}${descriptionRest}`.trim();
      }

      case 'text': {
        let stringSplit = stringText.split('');
        if (stringSplit.length > totalCut * 2) {
          stringSplit = [...stringSplit].filter(
            (_, index) =>
              index <= totalCut || index > stringText.length - totalCut / 2
          );
          stringSplit.splice(totalCut + 1, 0, '...');
        }

        return `${stringSplit.join('')}`;
      }
    }
  }

  complete(): void {
    if (this.restock?.isShipByCase || this.restock?.isShipManual) {
      const isSubtractWarehouse = this.isSubtractQuantity?.value;
      this.shipmentService
        .delete(this.restockKey, isSubtractWarehouse)
        .subscribe(() => {
          this.router.navigate(['/dashboard', 'shipments', 'manage-shipments']);
        });

      return;
    }

    this.isProcessing = true;
    this.shipmentService
      .createShipment(this.restockKey)
      .pipe(this.autoCleanUp())
      .subscribe(() => {
        this.hasCreatedShipment = true;
      });
  }

  checkShipmentLocalQtyError(): Observable<any> {
    return this.shipmentService
      .checkShipmentLocalQtyError(this.restockKey)
      .pipe(
        this.autoCleanUp(),
        tap(({ doesInvalidShipmentItemsExist }) => {
          if (doesInvalidShipmentItemsExist) {
            Swal.fire({
              type: 'warning',
              html: `
              Some of the items does not have enough "Warehouse Qty" for "Shipment Qty".
              <br/>
              As this shipment has been processed by Amazon, you cannot modify it.
              <br/>
              Therefore, please click "Delete" to delete this shipment and create a new one.
              `,
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
            }).then((value) => {
              if (value.dismiss) {
                return;
              }

              this.shipmentService.delete(this.restockKey).subscribe(() => {
                this.router.navigate(['/dashboard', 'shipments', 'create']);
              });
            });
          }
        })
      );
  }

  private initStickerForm(): void {
    this.stickerForm = this.fb.group({
      optionalData1: ['blank', [Validators.required]],
      customText1: [''],
      optionalData2: ['blank', [Validators.required]],
      customText2: [''],
      stickerFormat: ['_30_66', [Validators.required]],
      customWidth: [0],
      customHeight: [0],
      printOption: ['continuously', [Validators.required]],
      printRequiredStickerOnly: [true],
    });
  }

  private initSubtractQuantityForm(): void {
    this.subtractQuantityForm = this.fb.group({
      isSubtractQuantity: [true],
    });
  }

  private constructStickerInfo() {
    let imgWidth;
    let imgHeight;
    let pageFormat: string | number[];
    let maxStickerNumber = 1;
    let orientation: any = 'p';
    let stickerSpaceHeight = 0;
    switch (this.stickerFormat.value) {
      case '_24':
        imgWidth = 64.0;
        imgHeight = 33.9;
        pageFormat = [210, 297];
        maxStickerNumber = 24;
        stickerSpaceHeight = 12;
        break;

      case '_27_1':
        imgWidth = 66.675;
        imgHeight = 25.4;
        pageFormat = [215.9, 279.4];
        maxStickerNumber = 27;
        stickerSpaceHeight = 3.175;
        break;

      case '_30_66':
        imgWidth = 66.675;
        imgHeight = 25.4;
        pageFormat = [215.9, 279.4];
        maxStickerNumber = 30;
        break;

      case '_50':
        imgWidth = 50.0;
        imgHeight = 30.0;
        pageFormat = [imgWidth, imgHeight + 3];
        orientation = 'l';
        break;

      case 'customSize':
        imgWidth = this.customWidth.value;
        imgHeight = this.customHeight.value;
        pageFormat = [imgWidth, imgHeight + 3];
        orientation = imgWidth > imgHeight ? 'l' : 'p';
        break;
    }

    return {
      imgWidth,
      imgHeight,
      pageFormat,
      maxStickerNumber,
      orientation,
      stickerSpaceHeight,
    };
  }

  private constructMaxSticker(maxStickerNumber: number) {
    let maxStickerPerRow;
    let maxStickerPerColumn;
    switch (maxStickerNumber) {
      case 1:
        maxStickerPerRow = 1;
        maxStickerPerColumn = 1;
        break;

      case 24:
        maxStickerPerRow = 3;
        maxStickerPerColumn = 8;
        break;

      case 27:
        maxStickerPerRow = 3;
        maxStickerPerColumn = 9;
        break;

      case 30:
        maxStickerPerRow = 3;
        maxStickerPerColumn = 10;
    }

    if (this.printOption.value === 'own') {
      maxStickerPerRow = 1;
      maxStickerPerColumn = 1;
    }

    return {
      maxStickerPerRow,
      maxStickerPerColumn,
    };
  }

  private printStickers({
    spaceWidth,
    spaceHeight,
    initialSpaceWidth,
    initialSpaceHeight,
    items,
    optionalDataMappings,
    imgWidth,
    imgHeight,
    maxStickerPerRow,
    maxStickerPerColumn,
    pdf,
    stickerSpaceHeight,
  }) {
    const options = {
      spaceWidth,
      spaceHeight,
      initialSpaceWidth,
      initialSpaceHeight,
      xCoordinate: initialSpaceWidth || spaceWidth,
      yCoordinate: initialSpaceHeight || spaceHeight,
      currentStickerNumberOnRow: 0,
      currentStickerNumberOnCol: 0,
      bigIdx: 0,
    };
    items = items.filter((item) =>
      conditionalOperator(this.isModifySticker, item.hasSticker, true)
    );
    for (const [idx, i] of items.entries()) {
      const barcodeValue1 = this.onResolveStringText(
        conditionalOperator(
          this.optionalData1.value === 'customText',
          sanatizeText(this.customText1?.value),
          i[optionalDataMappings[this.optionalData1.value]] || ''
        ),
        this.customText1.value?.split(' ')?.length > 1 ? 'description' : 'text',
        imgWidth
      );

      const barcodeValue2 = this.onResolveStringText(
        conditionalOperator(
          this.optionalData2.value === 'customText',
          sanatizeText(this.customText2?.value),
          i[optionalDataMappings[this.optionalData2.value]] || ''
        ),
        this.customText2.value?.split(' ')?.length > 1 ? 'description' : 'text',
        imgWidth
      );

      const barcodeOption = ['_27_1', '_30_66'].includes(
        this.stickerFormat.value
      )
        ? {
            margin: 0,
            marginBottom: 10,
          }
        : {};
      JsBarcode('#temp-barcode', `${i.fnsku || i.asin || i.name}`, {
        width: 1,
        height: 40,
        fontSize: 100,
        textMargin: 0,
        displayValue: false,
        ...barcodeOption,
      });

      this.printStickerForItem({
        options,
        idx,
        barcodeValue1,
        barcodeValue2,
        imgWidth,
        imgHeight,
        maxStickerPerRow,
        maxStickerPerColumn,
        pdf,
        stickerSpaceHeight,
        i,
        items,
      });
    }

    this.willAddPage = false;
  }

  private printStickerForItem({
    options,
    idx,
    barcodeValue1,
    barcodeValue2,
    imgWidth,
    imgHeight,
    maxStickerPerRow,
    maxStickerPerColumn,
    pdf,
    stickerSpaceHeight,
    i,
    items,
  }) {
    for (let stickerTurn = 0; stickerTurn < i.stickerQty; stickerTurn++) {
      if (this.willAddPage) {
        pdf.addPage();
        this.willAddPage = false;
      }

      if (
        this.printOption.value === 'blank' &&
        options.bigIdx % 2 !== 0 &&
        maxStickerPerRow !== 1 &&
        maxStickerPerColumn !== 1
      ) {
        options.currentStickerNumberOnRow++;
        options.xCoordinate += imgWidth + options.spaceWidth;
      }

      const wrapper = document.getElementById('barcode-wrapper');
      const img = document.getElementById('temp-barcode') as HTMLImageElement;
      wrapper.classList.remove('hidden');

      pdf.addImage(
        img.src,
        'JPEG',
        options.xCoordinate + 2,
        options.yCoordinate + 2,
        imgWidth - 4,
        ['_27_1', '_30_66'].includes(this.stickerFormat.value)
          ? imgHeight - 10 - 4
          : imgHeight - 4,
        '',
        'FAST'
      );
      pdf.text(
        `${i.fnsku ? i.fnsku : ''}    ${i.condition ? i.condition : ''}`,
        options.xCoordinate + imgWidth / 2,
        options.yCoordinate +
          (['_27_1', '_30_66'].includes(this.stickerFormat.value)
            ? imgHeight - 10
            : imgHeight - 1),
        { align: 'center' }
      );

      const description = this.onResolveStringText(
        sanatizeText(i.description),
        i.description?.split(' ')?.length > 1 ? 'description' : 'text',
        imgWidth
      );
      pdf.text(
        description,
        options.xCoordinate + imgWidth / 2,
        options.yCoordinate +
          (['_27_1', '_30_66'].includes(this.stickerFormat.value)
            ? imgHeight - 10 + 4
            : imgHeight + 2),
        { align: 'center' }
      );

      this.printBarcodeTexts({
        pdf,
        barcodeValue1,
        barcodeValue2,
        xCoordinate: options.xCoordinate,
        yCoordinate: options.yCoordinate,
        imgWidth,
        imgHeight: ['_27_1', '_30_66'].includes(this.stickerFormat.value)
          ? imgHeight - 10
          : imgHeight,
      });

      options.currentStickerNumberOnRow++;
      options.xCoordinate += imgWidth + options.spaceWidth;

      const spacingResult = this.printSpacing({
        items,
        idx,
        currentStickerNumberOnRow: options.currentStickerNumberOnRow,
        currentStickerNumberOnCol: options.currentStickerNumberOnCol,
        xCoordinate: options.xCoordinate,
        yCoordinate: options.yCoordinate,
        spaceWidth: options.spaceWidth,
        spaceHeight: options.spaceHeight,
        initialSpaceWidth: options.initialSpaceWidth,
        initialSpaceHeight: options.initialSpaceHeight,
        imgHeight,
        maxStickerPerRow,
        maxStickerPerColumn,
        stickerSpaceHeight,
      });

      options.currentStickerNumberOnRow =
        spacingResult.currentStickerNumberOnRow;
      options.currentStickerNumberOnCol =
        spacingResult.currentStickerNumberOnCol;
      options.xCoordinate = spacingResult.xCoordinate;
      options.yCoordinate = spacingResult.yCoordinate;
      this.willAddPage = spacingResult.willAddPage;

      if (idx === items.length - 1) {
        wrapper.classList.add('hidden');
      }

      options.bigIdx++;
    }
  }

  private printBarcodeTexts({
    pdf,
    barcodeValue1,
    barcodeValue2,
    xCoordinate,
    yCoordinate,
    imgWidth,
    imgHeight,
  }) {
    pdf.text(
      `${barcodeValue1}`,
      xCoordinate + imgWidth / 2,
      yCoordinate +
        imgHeight +
        (['_27_1', '_30_66'].includes(this.stickerFormat.value) ? 8 : 5),
      { align: 'center' }
    );
    !['_27_1', '_30_66'].includes(this.stickerFormat.value) &&
      pdf.text(
        `${barcodeValue2}`,
        xCoordinate + imgWidth / 2,
        yCoordinate + imgHeight + 8,
        { align: 'center' }
      );
  }

  private printSpacing({
    items,
    idx,
    currentStickerNumberOnRow,
    currentStickerNumberOnCol,
    xCoordinate,
    yCoordinate,
    spaceWidth,
    spaceHeight,
    initialSpaceWidth,
    initialSpaceHeight,
    imgHeight,
    maxStickerPerRow,
    maxStickerPerColumn,
    stickerSpaceHeight,
  }) {
    let willAddPage = false;
    if (currentStickerNumberOnRow === maxStickerPerRow) {
      currentStickerNumberOnCol++;
      currentStickerNumberOnRow = 0;
      xCoordinate = initialSpaceWidth || spaceWidth;
      yCoordinate += imgHeight + stickerSpaceHeight;
    }
    if (currentStickerNumberOnCol === maxStickerPerColumn) {
      currentStickerNumberOnCol = 0;
      yCoordinate = initialSpaceHeight || spaceHeight;
      if (idx !== items.length) {
        willAddPage = true;
      }
    }

    return {
      currentStickerNumberOnRow,
      currentStickerNumberOnCol,
      xCoordinate,
      yCoordinate,
      willAddPage,
    };
  }

  onColumnResize(column) {
    const { field, newWidth } = column;
    this.gridStateShipmentComplete.columns.forEach((column) => {
      if (column.name === field) {
        column.width = newWidth;
      }
    });
    this.currentUser.gridStateShipmentComplete = this.gridStateShipmentComplete;
    this.userService.editUser(this.currentUser).subscribe();
  }

  onBackClick() {
    this.backClick.emit();
  }

  private findLongestLastPartOfDesc(
    hasParentheses,
    stringText,
    limitLength,
    totalCut,
    descriptions
  ) {
    let tempDesc = descriptions;
    const stringTextLength = stringText.length;
    if (!hasParentheses) {
      let whitespaceIndex = stringText.lastIndexOf(' ');
      while (tempDesc.length <= limitLength - totalCut) {
        tempDesc = stringText
          .substring(
            stringText.lastIndexOf(' ', whitespaceIndex--),
            stringTextLength
          )
          .substring(1);
      }
    }

    return tempDesc;
  }
}
