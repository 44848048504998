import {
  ColumnComponent,
  ColumnReorderEvent,
} from '@progress/kendo-angular-grid';
import { format, fromUnixTime } from 'date-fns';
import { GridStateColumn } from '../infrastructure/classes/grid-state-column';
import { MetaDataField } from '../infrastructure/classes/meta-data-field';
import { State, process } from '@progress/kendo-data-query';
import { Company } from '../models/company';
import { Item } from '../models/item';
import { Vendor } from '../models/vendor';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import _ from 'lodash';
import { Month } from '../infrastructure/enums/common.enum';
import { AdvancedFilter } from '../infrastructure/enums/advanced-filter.enum';
import {
  ItemField,
  SummaryField,
  ItemMetricField,
  ShipmentField,
  ShipmentDetailField,
  RestockSuggestionSupplierField,
  PurchaseOrderField,
} from '../infrastructure/enums/meta-data-field.enum';
import { MetaDataFieldType } from '../infrastructure/enums/meta-data-field-type.enum';
import { GridName } from '../../theme/shared/forecastui/forecast-rxdata-table/forcast-rxdata-table.constant';
import { ColumnWidth } from '../infrastructure/enums/column-width.enum';
import { KeyValue } from '@angular/common';
import moment from 'moment';

const Months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

const extractPartsForSort = (str: string) => {
  const match = str.match(/(\D+)?(\d+)?/);
  return {
    nonNumeric: match[1] || '',
    numeric: match[2] ? parseInt(match[2], 10) : null
  };
}

export const formatTimestamp = (
  timestamp: number,
  dateFormat = 'MM/dd/YYY'
): string => {
  const date = fromUnixTime(timestamp);

  return format(new Date(date), dateFormat);
};

export const subtractByYears = (date: Date, years: number) => {
  date.setFullYear(date.getFullYear() - years)
  date.setHours(0, 0, 0, 0);
  return date;
};

export const conditionalOperator = (expression: boolean, a: any, b: any) => {
  return expression ? a: b;
}

export const logicalORAssignmentOperator = (a: any, b: any) => {
  return a || b;
}

/**
 * Get index of any field in metaData fields
 *
 * @param metaData
 * @param name
 */
export const getIndex = (metaData: MetaDataField[], name: string): number => {
  return metaData?.findIndex((item) => item.field === name);
};

/**
 * Keep index of any field in metaData fields
 *
 * @param metaData
 * @param columnName
 * @param keepPosition
 */
export const keepIndex = (
  metaData: MetaDataField[],
  columnName: string,
  keepPosition: number | string
): MetaDataField[] => {
  const columnIndex = getIndex(metaData, columnName);
  let keepIndex: number;
  if (typeof keepPosition === 'string') {
    // Keep column is always behind Position column
    keepIndex = getIndex(metaData, keepPosition);
  } else {
    keepIndex = keepPosition;
  }

  if (![-1, keepIndex + 1].includes(columnIndex)) {
    const column = metaData.splice(columnIndex, 1);
    metaData.splice(keepIndex, 0, ...column);
  }

  return metaData;
};

/**
 * Reorder columns state by order of metaData fields
 *
 * @param metaDataFields
 * @param columns
 */
export const reorderColumnsByFields = (
  metaDataFields: MetaDataField[],
  columns: GridStateColumn[]
): GridStateColumn[] => {
  return metaDataFields?.map((metaData) => {
    const column = columns?.find((col) => col?.name === metaData?.field);

    if (!column) {
      const gridStateColumn = new GridStateColumn({
        name: metaData.field,
        visible: true,
        width: '*',
      });

      return gridStateColumn;
    }

    return {
      ...column,
      visible: metaData.initialUpload,
    };
  });
};

/**
 * Reorder metaData fields by order of columns from user state
 *
 * @param metaDataFields
 * @param columns
 */
export const reorderFieldsByColumns = (
  metaDataFields: MetaDataField[],
  columns: GridStateColumn[]
): MetaDataField[] => {
  if (!columns?.length) {
    return metaDataFields;
  }

  const sortedFieldNames = columns
    .filter((c) => metaDataFields.find((m) => m?.field === c?.name))
    .map((c) => c?.name);

  return metaDataFields
    .slice()
    .sort(
      (a, b) =>
        sortedFieldNames.indexOf(a?.field) - sortedFieldNames.indexOf(b?.field)
    );
};

/**
 * Update metaData fields' visible
 *
 * @param metaDataFields
 * @param changedColumns
 */
export const updateFieldsVisible = (
  metaDataFields: MetaDataField[],
  changedColumns: ColumnComponent[]
): void => {
  metaDataFields.forEach((field) => {
    const matchColumn = changedColumns.find(
      (changedColumn) => field.field === changedColumn?.field
    );

    if (matchColumn) {
      field.initialUpload = !matchColumn?.hidden;
    }
  });
};

/**
 * Update columns' visible
 *
 * @param columns
 * @param changedColumns
 */
export const updateColumnsVisible = (
  columns: GridStateColumn[],
  changedColumns: ColumnComponent[]
): void => {
  columns.forEach((column) => {
    const matchColumn = changedColumns.find(
      (changedColumn) => column.name === changedColumn?.field
    );

    if (matchColumn) {
      column.visible = !matchColumn?.hidden;
    }
  });
};

/**
 * Map order of metaData fields from order of columns from user state
 *
 * @param metaDataFields
 * @param columns
 */
export const mapColumnStateToField = (
  metaDataFields: MetaDataField[],
  columns: GridStateColumn[]
): MetaDataField[] => {
  return metaDataFields?.map((field) => {
    const matchColumn = columns?.find(
      (column) => column?.name === field?.field
    );
    field.initialUpload = true;
    if (matchColumn) {
      field.initialUpload = matchColumn.visible;
      field.width = parseInt(matchColumn.width);
      field.locked = matchColumn.locked;
    }

    return field;
  });
};

/**
 * Handle reorder fields after reorder in grid
 *
 * @param metaDataField
 * @param column
 * @param hasDefaultFirstCol
 */
export const reorderFields = (
  metaDataField: MetaDataField[],
  column: ColumnReorderEvent,
  hasDefaultFirstCol = true
): MetaDataField[] => {
  const newIndex = hasDefaultFirstCol ? column.newIndex - 1 : column.newIndex;

  const displayedFields = metaDataField.filter((item) => item.initialUpload);
  const oldIndex = displayedFields.findIndex((f) => f.field === (column?.column as any)?.field);
  const hiddenFields = metaDataField.filter((item) => !item.initialUpload);
  const reorderedColumn = displayedFields.splice(oldIndex, 1);
  displayedFields.splice(newIndex, 0, ...reorderedColumn);

  metaDataField = displayedFields.concat(hiddenFields);

  return metaDataField;
};

/**
 * Handle reorder fields after reorder in grid
 *
 * @param userGridColumn
 * @param column
 * @param hasDefaultFirstCol
 */
export const reorderColumnsFieldGroup = (
  userGridColumn: GridStateColumn[],
  column: ColumnReorderEvent,
  hasDefaultFirstCol = true
): GridStateColumn[] => {
  let newIndex = hasDefaultFirstCol ? column.newIndex - 1 : column.newIndex;
  let oldIndex = hasDefaultFirstCol ? column.oldIndex - 1 : column.oldIndex;
  const columnState = userGridColumn.splice(oldIndex, 1)[0];

  userGridColumn.splice(newIndex, 0, columnState);
  return userGridColumn;
};

/**
 * Trigger a file downloading from a file or url
 *
 * @param fileOrUrl
 * @param fileName
 */
export const downloadFile = (fileOrUrl: File | string, fileName: string) => {
  const windowURL = window.URL || window.webkitURL;
  const blobURL =
    fileOrUrl.constructor === String
      ? fileOrUrl
      : windowURL.createObjectURL(fileOrUrl as File);
  const aTag = document.createElement('a');

  aTag.setAttribute('href', blobURL);
  aTag.setAttribute('download', fileName);
  aTag.setAttribute('target', '_blank');

  aTag.click();
  aTag.remove();

  windowURL.revokeObjectURL(blobURL);
};

/**
 * JSON.stringify with a custom replacer for dates object
 *
 * @param json
 * @returns A JSON string representing the given value, or undefined.
 */
export function stringifyJSONWithDates(json: any): string {
  return JSON.stringify(json, function (key, value) {
    if (this[key] instanceof Date && !isNaN(this[key] as any)) {
      return moment(this[key]).utc().startOf('day').toISOString();
    }

    return value;
  });
}

/**
 * Analyze and transform filter with datetime into suitable filter
 *
 * @param state
 * @returns changed state and fields to be disabled for extra filter
 */
export function handleFilterWithDates(state: State): [State, string[]] {
  const tempState = _.cloneDeep(state);
  let disabledExtraFields: string[] = [];
  // Handle datetime filters
  const dateFilters = tempState.filter?.filters.filter((fltr) =>
    ['Date', 'created', 'updated'].some((cond) =>
      fltr[0]?.field?.includes(cond)
    )
  );

  dateFilters.forEach((filter: any) => {
    const currentFilter = filter.filters[0];
    if (filter.filters.some((fltr) => fltr.operator === 'eq')) {
      const redundantFilterIndex = filter.filters[0].operator === 'eq' ? 1 : 0;
      const currentFilterValue = new Date(currentFilter.value);
      currentFilter.value = new Date(currentFilter.value);

      disabledExtraFields.push(currentFilter.field);

      filter.logic = 'and';

      currentFilter.operator = 'gte';

      filter.filters[redundantFilterIndex] = {
        field: currentFilter.field,
        operator: 'lt',
        value: new Date(
          currentFilterValue.setDate(currentFilterValue.getDate() + 1)
        ),
      };
    }

    if (filter.filters.some((fltr) => fltr.operator === 'neq')) {
      const redundantFilterIndex = filter.filters[0].operator === 'neq' ? 1 : 0;
      const currentFilterValue = new Date(currentFilter.value);

      disabledExtraFields.push(currentFilter.field);

      filter.logic = 'or';

      currentFilter.operator = 'gte';
      currentFilter.value = new Date(
        currentFilterValue.setDate(currentFilter.value.getDate() + 1)
      );

      filter.filters[redundantFilterIndex] = {
        field: currentFilter.field,
        operator: 'lt',
        value: currentFilterValue,
      };
    }

    filter.filters.map((item) => {
      if (item.operator === 'gt') {
        const itemValue = new Date(item.value);
        item.value = new Date(itemValue.setDate(item.value.getDate() + 1));
        item.operator = 'gte';
      }
    });

    filter.filters.map((item) => {
      if (item.operator === 'lte') {
        const itemValue = new Date(item.value);
        item.value = new Date(itemValue.setDate(item.value.getDate() + 1));
        item.operator = 'lt';
      }
    });
  });

  return [tempState, disabledExtraFields];
}

/**
 * Filling missing items for target based on source
 *
 * @param source
 * @param target
 * @returns Filled target
 */
export function fillingMissingItems(
  source: MetaDataField[],
  target: GridStateColumn[]
) {
  const missingItems = source.filter(
    (s) => target.findIndex((t) => t.name === s.field) === -1
  );

  return target.concat(
    missingItems.map((m) => {
      const column = new GridStateColumn();

      column.name = m.field;
      column.visible = m.initialUpload || false;
      delete column.sort;

      return column;
    })
  );
}

/**
 * Determine should lost sales functionality be disabled for history-data-table and view-of-history
 *
 * @param isLostSalesDisabled
 * @param useLostSalesOverride
 * @param isLostSaleTracking
 * @returns a boolean value of whether lost sales functionality is disabled or not
 */
export function getLostSalesDisabled(
  isLostSalesDisabled: boolean,
  useLostSalesOverride: boolean,
  isLostSaleTracking
): boolean {
  return (isLostSalesDisabled && !useLostSalesOverride) || isLostSaleTracking;
}

/**
 *
 * @param items
 * @param vendors
 * @param company
 * @returns items with service level with different sources
 */
export function getItemsAndServiceLevelWithDiffSources(
  items: Item[],
  vendors: Vendor[],
  company: Company
): Item[] {
  items.forEach((i: any) => {

    if(_.isEmpty(i.inventorySourcePreference)) {
      i.inventorySourcePreference= `${company.inventorySourcePreference}`
    }

    if (i.serviceLevel) {
      i.serviceLevel = `${i.serviceLevel} (Item)`;
      return;
    }

    const vendor = i.vendorKey && vendors.find((v) => v.key === i.vendorKey);

    if (vendor?.serviceLevel) {
      i.serviceLevel = `${vendor.serviceLevel} (Supplier)`;
    }

    if (!i.serviceLevel) {
      i.serviceLevel = `${company.serviceLevel} (Global)`;
    }

  });

  return items;
}

/**
 * Create table for jspdf object from headers, fields and data
 *
 * @param headers
 * @param fields
 * @param data
 * @returns jsPDF object with table
 */
export function drawTableForJsPDF<T>(
  pdf: jsPDF,
  headers: RowInput,
  fields: string[],
  data: T[]
): jsPDF {
  autoTable(pdf, {
    theme: 'grid',
    headStyles: {
      fillColor: null,
      textColor: 20,
      lineWidth: 0.25,
      lineColor: 10,
    },
    bodyStyles: { lineColor: 10 },
    showHead: 'firstPage',
    head: [headers],
    body: data.map((i) => fields.map((f) => i?.[f])),
  });

  return pdf;
}

/**
 * Transform boolean value to Yes/No
 *
 * @param value
 * @returns Yes/No
 */
export function transformBooleanToYesNo(value: boolean): string {
  switch (value) {
    case true:
      return 'Yes';

    case false:
      return 'No';

    default:
      return '';
  }
}

/**
 * Standardize String
 *
 * @param value
 * @returns string
 */
export function standardizeString(value: string): string {
  let convertToArray = value.toLowerCase().split('-');

  let result = convertToArray.map((val) => {
    let convertValToArray = val.trim().toLowerCase().split(' ');
    convertValToArray = convertValToArray.map((res) => {
      return res.replace(res.charAt(0), res.charAt(0).toUpperCase());
    });

    return convertValToArray.join(' ');
  });

  return result.join(' - ');
}

/**
 * Add text unit to a number
 *
 * @param value
 * @param unit
 * @returns result
 */
export function addTextUnitToNumber(value: number, unit: string, isItemHistory?: boolean): string {
  let result: string;
  switch (true) {
    case value === 0:
      result = '0';
      break;

    case value === 1:
      result = `${value}${unit ? ' ' + unit : ''}`;
      break;

    case value > 48 && isItemHistory:
      result = '48+ months';
      break;

    case value > 0 && value < 1:
    case value > 1:
      result = `${value}${unit ? ' ' + unit + 's' : ''}`;
      break;

    default:
      result = '';
  }

  return result;
}

export function getReadableKendoOperator(operator: string, filterValue: any): string {
  switch (operator) {
    case 'eq':
      return filterValue === null ? 'Is' : 'Equal To';
    case 'neq':
      return filterValue === null ? 'Is Not' : 'Does Not Equal To';
    case 'gt':
      return 'Greater Than';
    case 'gte':
      return 'Greater Than Or Equal To';
    case 'lt':
      return 'Less Than';
    case 'lte':
      return 'Less Than Or Equal To';
    case 'doesnotcontain':
      return 'Does Not Contain';
    case 'startswith':
      return 'Starts With';
    case 'endswith':
      return 'Ends With';
    case 'isempty':
      return 'Is Empty';
    case 'isnull':
      return 'Is Null';
    case 'isnotempty':
      return 'Is Not Empty';
    case 'isnotnull':
      return 'Is Not Null';
    default:
      return _.startCase(operator);
  }
}

export function getMarketPlace(marketplaceId): string {
  switch (marketplaceId) {
    case "EU":
    case "NA":
      return marketplaceId;
    case "A2EUQ1WTGCTBG2":
      return "CA"; // Canada
    case "ATVPDKIKX0DER":
      return "US"; // US
    case "A1AM78C64UM0Y8":
      return "MX"; // Mexico
    case "A1PA6795UKMFR9":
      return "DE"; // Germany
    case "A1RKKUPIHCS9HS":
      return "ES"; // Spain
    case "A13V1IB3VIYZZH":
      return "FR"; // France
    case "APJ6JRA9NG5V4":
      return "IT"; // Italy
    case "A1F83G8C2ARO7P":
      return "GB"; // UK
    case "A21TJRUUN4KGV":
      return "IN"; // India
    case "A1VC38T7YXB528":
      return "JP"; // Japan
    case "AAHKV2X7AFYLW":
      return "CN"; // China
    default:
      return '';
  }
}

export function generateMonthPercentages(): string[] {
  const monthPercentages: string[] = [];
  const today = new Date();
  const currentYear = today.getFullYear();
  let monthIndex = today.getMonth();
  for (let i = 0; i < 12; i++) {
    monthPercentages.push(
      monthIndex >= 12
        ? `${Month[monthIndex - 12]}\n${currentYear + 1}`
        : `${Month[monthIndex]}\n${currentYear}`
    );
    monthIndex++;
  }

  return monthPercentages;
}

export function transformColumnWidth(
  metaDataField: MetaDataField,
  isPrintList: boolean,
  itemName: string
) {
  if (isPrintList) {
    return 0;
  }

  switch (itemName) {
    case GridName.Item:
      switch (metaDataField.field) {
        case ItemField.history:
          return ColumnWidth.itemHistory;

        case ItemField.links:
          return ColumnWidth.itemLinks;

        case ItemField.isHidden:
        case ItemField.moq:
          return ColumnWidth.itemIsHidden;

        case ItemField.description:
        case ItemField.name:
          return ColumnWidth.itemDesc;

        case ItemField.imageUrl:
          return ColumnWidth.itemImage;

        case ItemMetricField.s7d:
        case ItemMetricField.s30d:
        case ItemMetricField.s90d:
        case ItemMetricField.s365d:
          return ColumnWidth.itemMetric;

        case ItemField.vendorName:
          return 190;

        case ItemField.vendorPrice:
        case ItemField.onHand:
        case ItemField.onHandFbm:
        case ItemField.onHandThirdParty:
          return 210;

        case ItemField.inventorySourcePreference:
        case ItemField.itemHistoryLength:
          return 230;

        default:
          return ColumnWidth.default;
      }

    case GridName.ItemsInPo:
    case GridName.AllAvailableItems:
      switch (metaDataField.field) {
        case SummaryField.details:
          return ColumnWidth.poDetails;

        case SummaryField.description:
          return ColumnWidth.poDesc;

        case SummaryField.asin:
          return ColumnWidth.poAsin;

        case SummaryField.imageUrl:
          return ColumnWidth.itemImage;

        default:
          return ColumnWidth.default;
      }

    case GridName.ForecastShipmentItem:
    case GridName.AmazonShipmentItem:
    case GridName.ForecastShipmentItemChosen:
    case GridName.ForecastShipmentItemValid:
      switch (metaDataField.field) {
        case ShipmentDetailField.itemName:
        case ShipmentDetailField.hasSticker:
        case ShipmentDetailField.cost:
        case ShipmentDetailField.fnsku:
          return ColumnWidth.shipmentDetailMedium;

        case ShipmentDetailField.shipmentQty:
        case ShipmentDetailField.caseQty:
        case ShipmentDetailField.description:
          return ColumnWidth.shipmentDetailLarge;

        case ShipmentDetailField.imageUrl:
          return ColumnWidth.itemImage;

        default:
          return ColumnWidth.default;
      }

    case GridName.Shipment:
      switch (metaDataField.field) {
        case ShipmentField.shipmentName:
          return ColumnWidth.shipmentName;
        case ShipmentField.receivedQty:
        case ShipmentField.requestedQty:
        case ShipmentField.destinationFulfillmentCenterId:
        case ShipmentField.totalCost:
          return ColumnWidth.shipmentMedium;
        case ShipmentField.status:
          return ColumnWidth.shipmentStatus;
        case ShipmentField.nextStep:
          return ColumnWidth.shipmentNextStep;
        case ShipmentField.options:
          return ColumnWidth.shipmentOptions;
        case ShipmentField.orderNotes:
          return ColumnWidth.shipmentOrderNotes;
        default:
          return ColumnWidth.default;
      }

    case GridName.SupplierList:
      switch (metaDataField.field) {
        case RestockSuggestionSupplierField.greenAlerts:
        case RestockSuggestionSupplierField.orangeAlerts:
        case RestockSuggestionSupplierField.yellowAlerts:
        case RestockSuggestionSupplierField.redAlerts:
        case RestockSuggestionSupplierField.targetOrderValue:
          return 200;

        case RestockSuggestionSupplierField.restockTotal:
          return 220;

        case RestockSuggestionSupplierField.freeFreightMinimum:
          return 240;
      }

    default:
      return ColumnWidth.default;
  }
}

export function transformFilterableColumn(
  metaDataField: MetaDataField,
  advancedFilter: any,
  itemName: GridName = null
): boolean {
  if([ItemField.history, ItemField.links, ItemField.imageUrl, SummaryField.details, PurchaseOrderField.options].includes(metaDataField.field as ItemField)) {
    return false;
  }

  if (
    [GridName.Shipment, GridName.ShipmentAWD].includes(itemName) &&
    metaDataField.field === ShipmentField.refNum
  ) {
    return false;
  }

  return (
    !metaDataField?.restrictFilterAndSort ||
    ![AdvancedFilter.active, AdvancedFilter.inactive].includes(advancedFilter)
  );
}

export function transformSortableField(metaDataField: MetaDataField, itemName: GridName = null): boolean {
  if (metaDataField?.restrictFilterAndSort) {
    return false;
  }

  const field = metaDataField?.field;

  return (
    (
      field !== ItemField.history &&
      field !== ItemField.links &&
      field !== ItemField.imageUrl &&
      field !== SummaryField.details &&
      field !== SummaryField.removeAll &&
      field !== ShipmentField.nextStep &&
      field !== ShipmentField.options &&
      field !== RestockSuggestionSupplierField.flag &&
      itemName !== GridName.PurchaseOrder
    ) ||
    (
      field !== PurchaseOrderField.shipmentId &&
      field !== ShipmentField.options &&
      itemName === GridName.PurchaseOrder
    )
  );
}

export function transformHiddenField(metaDataField: any, hiddenColumns: string[]): boolean {
  return (
    !metaDataField.initialUpload ||
    hiddenColumns.includes(metaDataField.field)
  );
}

export function transformFieldType(fieldType: MetaDataFieldType | string): any {
  let filterType = 'text';

  switch (fieldType) {
    case MetaDataFieldType.Date:
    case MetaDataFieldType.DateOnly:
      filterType = 'date';
      break;
    case MetaDataFieldType.Decimal:
    case MetaDataFieldType.Integer:
      filterType = 'numeric';
      break;
    case MetaDataFieldType.Boolean:
      filterType = 'boolean';
      break;
    case MetaDataFieldType.String:
    case MetaDataFieldType.UUID:
      break;
  }

  return filterType;
}

export function roundFloatNumber(number){
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export function sanatizeText(text: string) {
  return text?.replace(/[^a-zA-Z0-9,<.>/?;:'"\[{\]}\\|!@#$%^&*()\-_=+\s]+/g, ' ') || '';
}

export function getMonthHeaders (isIncludedYear = false) {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const headers = [];
  headers.push(...Months.slice(currentMonth).map((m) => isIncludedYear ? `${m} ${currentYear}` : m));
  headers.push(...Months.slice(0, currentMonth).map((m) => isIncludedYear ? `${m} ${currentYear + 1}` : m));

  return headers;
};

/**
 * Get text color depend on background color
 * @param hexColor
 * @returns string
 */
export function getContrastYIQ(hexColor) {
  if(!hexColor) {
    return;
  }

  const r = parseInt(hexColor.substring(1,3),16);
  const g = parseInt(hexColor.substring(3,5),16);
  const b = parseInt(hexColor.substring(5,7),16);
  const yiq = ((r*299)+(g*587)+(b*114))/1000;

  return (yiq >= 128) ? 'black' : 'white';
};

export function sortColumnsByField(list, field, dir) {
  return process(list, { sort: [{ field: field, dir: dir }]}).data
}


export function getUnitFromText(unitText: string): string {
  if (!unitText) {
    return '';
  }

  switch (true) {
    case unitText.includes('feet'):
      return 'ft';

    case unitText.includes('kilometer'):
      return 'km';

    case unitText.includes('hectometer'):
      return 'hm';

    case unitText.includes('decameter'):
      return 'dam';

    case unitText.includes('meter'):
      return 'meter';

    case unitText.includes('decimeter'):
      return 'dm';

    case unitText.includes('centimeter'):
      return 'cm';

    case unitText.includes('millimeter'):
      return 'mm';

    default:
      return '';
  }
}

/**
 * Comparator function to sort strings by their non-numeric parts lexicographically.
 * If the non-numeric parts are equal, it sorts by their numeric parts.
 *
 * @param {string} a - The first string to compare.
 * @param {string} b - The second string to compare.
 * @returns {number} A negative number if `a` should come before `b`, a positive number
 *                   if `a` should come after `b`, or 0 if they are considered equal.
 */
export function sortMixTextNumber(a, b) {
  const partsA = extractPartsForSort(a);
  const partsB = extractPartsForSort(b);

  const stringComparison = partsA.nonNumeric.localeCompare(partsB.nonNumeric);

  if (stringComparison !== 0) {
    return stringComparison;
  } else {
    const numA = partsA.numeric;
    const numB = partsB.numeric;

    if (numA !== null && numB !== null) {
      return numA - numB;
    } else if (numA !== null) {
      return -1;
    } else if (numB !== null) {
      return 1;
    } else {
      return 0;
    }
  }
}

export function getBase64ImageFromURL(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx!.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}

//when using the keyvalue pipe in the html with *ngFor it rearranges the entries by key which is an undesired effect.
//This preserves the order of the entries.
export const preserveOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
  return 0;
}

export function loadCalendly(Calendly) {
  if(!Calendly) return;

  Calendly.initBadgeWidget({
    url: 'https://calendly.com/d/g7b-h8r-wt5/support-meeting',
    text: 'Schedule time with us',
    color: '#0069ff',
    textColor: '#ffffff',
    branding: undefined
  });
}
