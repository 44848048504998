import { addTextUnitToNumber, conditionalOperator } from '@stockaid/utils';
import { differenceInDays, format, startOfDay, subDays } from 'date-fns';
import _ from 'lodash';
import { ServiceLevelListDisplay } from 'src/app/core/constants/service-level.constant';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { InventorySourcePreference } from 'src/app/core/infrastructure/enums/inventory-source-preference.enum';
import { MetaDataFieldType } from 'src/app/core/infrastructure/enums/meta-data-field-type.enum';
import { RestockModel } from 'src/app/core/infrastructure/enums/restock-model.enum';
import { RestockType } from 'src/app/core/infrastructure/enums/restock-type.enum';
import { Company } from 'src/app/core/models/company';
import { Item } from 'src/app/core/models/item';
import { RestockSuggestion } from 'src/app/core/models/restock-suggestion';
import { Result } from 'src/app/core/models/result';
import { Snapshot } from 'src/app/core/models/snapshot';
import { Summary } from 'src/app/core/models/summary';

const formatDate = (date) => {
  return date ? format(new Date(date), 'MM/dd/YYY') : null;
};
const substractDays = (date, amount) => {
  return date ? format(subDays(new Date(date), amount), 'MM/dd/YYY') : null;
};

export function getRecentHistoricalData(
  dataSource: any,
  isTotalComponentDataOn = false,
  companyType: string
): any {
  const { summary } = dataSource as { summary: Summary };
  const finalSummary =
    isTotalComponentDataOn || _.isEmpty(summary?.isolatedValues)
      ? summary
      : summary.isolatedValues;
  const lastWordDisplayName = companyType === CompanyType.ASC ? "Sold" : "Shipped";

  return [
    {
      displayName: '7 Day Units ' + lastWordDisplayName,
      data: finalSummary.s7d,
    },
    {
      displayName: '30 Day Units ' + lastWordDisplayName,
      data: finalSummary.s30d,
    },
    {
      displayName: '90 Day Units ' + lastWordDisplayName,
      data: finalSummary.s90d,
    },
    {
      displayName: '180 Day Units ' + lastWordDisplayName,
      data: finalSummary.s180d,
    },
    {
      displayName: '365 Day Units ' + lastWordDisplayName,
      data: finalSummary.s365d,
    },
    {
      displayName: 'MTD Units ' + lastWordDisplayName,
      data: finalSummary.sMtd,
    },
    {
      displayName: 'YTD Units ' + lastWordDisplayName,
      data: finalSummary.sYtd,
    },
  ];
}

const sanitizeInboundValues = (company, isPurchasing, values) => {
  let { inboundFcTransfer, warehouseQty, openPurchaseOrders } = values;
  if (!company.isUsingFcTransfer && !isPurchasing) {
    inboundFcTransfer = 0;
  }

  if (!company.isUsingWarehouseInventory && !isPurchasing) {
    warehouseQty = 0;
  }

  if (!company.isUsingOnOrder && !isPurchasing) {
    openPurchaseOrders = 0;
  }

  return {
    inboundFcTransfer,
    warehouseQty,
    openPurchaseOrders,
  };
};

export function getInventoryStatus(
  dataSource: any,
  isPurchasing: boolean,
  isTotalComponentDataOn: boolean
): any {
  const { summary, company, restockSuggestion } = dataSource as {
    summary: Summary;
    company: Company;
    restockSuggestion: RestockSuggestion;
  };
  let unusedInboundValue = 0;
  if (restockSuggestion && !isPurchasing) {
    unusedInboundValue += conditionalOperator(
      !company.isUsingInboundWorking && !!restockSuggestion.inboundWorking,
      restockSuggestion.inboundWorking,
      0
    );

    unusedInboundValue += conditionalOperator(
      !company.isUsingInboundShipped && !!restockSuggestion.inboundShipped,
      restockSuggestion.inboundShipped,
      0
    );

    unusedInboundValue += conditionalOperator(
      !company.isUsingInboundReceiving && !!restockSuggestion.inboundReceiving,
      restockSuggestion.inboundReceiving,
      0
    );
  }

  const inbound =
    conditionalOperator(
      isPurchasing && isTotalComponentDataOn,
      summary.inbound,
      summary.restockInbound
    ) - unusedInboundValue;
  let inboundFcTransfer = conditionalOperator(
    isPurchasing && isTotalComponentDataOn,
    summary.inboundFcTransfer,
    summary.restockInboundFcTransfer
  );

  let warehouseQty = conditionalOperator(
    isPurchasing && isTotalComponentDataOn,
    summary.onHandThirdParty,
    summary.restockOnHandThirdParty
  );

  let openPurchaseOrders = conditionalOperator(
    isPurchasing && isTotalComponentDataOn,
    summary.openPurchaseOrders,
    summary.restockOpenPurchaseOrders
  );

  const sanitizedValues = sanitizeInboundValues(company, isPurchasing, {
    inboundFcTransfer,
    warehouseQty,
    openPurchaseOrders,
  });

  inboundFcTransfer = sanitizedValues.inboundFcTransfer;
  warehouseQty = sanitizedValues.warehouseQty;
  openPurchaseOrders = sanitizedValues.openPurchaseOrders;

  let onHand = conditionalOperator(
    isPurchasing && isTotalComponentDataOn,
    summary.onHand,
    summary.restockOnHand
  );
  if (summary.inventorySourcePreference === InventorySourcePreference.FBM) {
    onHand = 0;
  }

  let onHandFbm = conditionalOperator(
    isPurchasing && isTotalComponentDataOn,
    summary?.onHandFbm,
    summary?.isolatedValues?.onHandFbm
  );
  if (summary.inventorySourcePreference === InventorySourcePreference.FBA) {
    onHandFbm = 0;
  }

  const isFBM = dataSource?.item?.isFbm;

  const inventoryStatus = conditionalOperator(
    company.companyType === CompanyType.ASC,
    [
      {
        displayName: 'On Hand FBA Qty',
        data: onHand,
      },
      {
        displayName: 'On Hand FBM Qty',
        data: onHandFbm,
      },
      {
        displayName: 'Inbound',
        data: inbound,
      },
      {
        displayName: 'FC Transfer',
        data: inboundFcTransfer,
      },
      {
        displayName: 'Sum',
        data:
          onHand +
          inbound +
          inboundFcTransfer,
      },
      {
        displayName: 'Warehouse Qty',
        data: warehouseQty,
        ...(company.isUsingAWDInventoryQty
          ? { tooltip: 'Warehouse Qty + AWD Inventory' }
          : {}
        )

      },
      {
        displayName: 'On PO',
        data: openPurchaseOrders,
        ...(company.isUsingAWDInventoryQty
          ? { tooltip: 'Open Qty + Inbound to AWD' }
          : {}
        )
      },
    ],
    [
      {
        displayName: 'On Hand Qty',
        data: conditionalOperator(
          isPurchasing && isTotalComponentDataOn,
          summary.onHand,
          summary.restockOnHand
        ),
      },
      {
        displayName: 'On Hand Qty Min',
        data: conditionalOperator(
          isPurchasing && isTotalComponentDataOn,
          summary.onHandMin,
          summary.restockOnHandMin
        ),
      },
      {
        displayName: 'Warehouse Qty',
        data: conditionalOperator(
          isPurchasing && isTotalComponentDataOn,
          summary.onHandThirdParty,
          summary.restockOnHandThirdParty
        )
      },
      {
        displayName: 'Warehouse Qty Min',
        data: conditionalOperator(
          isPurchasing && isTotalComponentDataOn,
          summary.onHandThirdPartyMin,
          summary.restockOnHandThirdPartyMin
        ),
      },
    ]
  );

  addRow(
    inventoryStatus,
    company.companyType,
    { isPurchasing, isTotalComponentDataOn, isFBM },
    summary
  );

  return inventoryStatus;
}

export function addRow(
  inventoryStatus,
  companyType,
  condition,
  summary
) {
  if (companyType === CompanyType.QBFS && condition.isPurchasing) {
    inventoryStatus.push({
        displayName: 'Existing PO Qty',
        data: summary.openPurchaseOrders
    })
  }
}

export function getFutureProjections(
  dataSource: any,
  restockType: RestockType,
  isPurchasing: boolean,
  isTotalComponentDataOn: boolean,
  isCustom: boolean
): any {
  const { summary, result, restockSuggestion } = dataSource as {
    summary: Summary;
    snapshotsByGroup: Snapshot[];
    result: Result;
    restockSuggestion: RestockSuggestion;
  };

  const summaryValues = conditionalOperator(
    isTotalComponentDataOn || _.isEmpty(summary?.isolatedValues),
    summary,
    summary?.isolatedValues
  );
  const orderInterval = result?.settingsSources?.orderInterval?.value;
  const leadTime = result?.settingsSources?.leadTime?.value;
  const safetyStockLtd = conditionalOperator(
    !summaryValues?.safetyStockLtd && summaryValues?.safetyStockLtd !== 0,
    Math.round(summary?.safetyStockLtd),
    Math.round(summaryValues?.safetyStockLtd)
  );
  const recommendedSafetyStockRow = isPurchasing
    ? [
        {
          displayName: 'Recommended Safety Stock (SL)',
          data: conditionalOperator(isCustom, 'N/A', safetyStockLtd),
          tooltip: conditionalOperator(
            isCustom,
            'Modified Forecast in use, safety stock not included',
            ''
          ),
        },
      ]
    : [];

  // Calculate projected sales over horizon day
  let projectedSalesOverHorizonDay = 0;
  let newLeadTime = 0;
  if (restockType === RestockType.Warehouse) {
    restockSuggestion.trueRestockModel = RestockModel.LOCAL;
  }
  switch (restockSuggestion?.trueRestockModel) {
    case RestockModel.DIRECT_SHIP:
      projectedSalesOverHorizonDay =
        restockSuggestion.leadTime + restockSuggestion.targetMaxDays;
      newLeadTime = restockSuggestion.leadTime;
      break;

    case RestockModel.LOCAL:
      projectedSalesOverHorizonDay =
        restockSuggestion.localLeadTimeDays + restockSuggestion.targetMaxDays;
      newLeadTime = restockSuggestion.localLeadTimeDays;
      break;
  }

  if (isPurchasing) {
    projectedSalesOverHorizonDay = orderInterval + leadTime;
  }

  const fullMeanLtd = isTotalComponentDataOn
    ? summary?.fullMeanLtd
    : summary?.restockFullMeanLtd;
  const poMeanLtd = isTotalComponentDataOn
    ? summary?.poMeanLtd
    : summary?.restockPoMeanLtd;

  const newFullMeanLtd =
    restockType === RestockType.Warehouse
      ? summary?.warehouseFullMeanLtd
      : summary?.newFullMeanLtd;
  const newPoMeanLtd =
    restockType === RestockType.Warehouse
      ? summary?.warehousePoMeanLtd
      : summary?.newPoMeanLtd;

  if (!summaryValues.outOfStockDate) {
    summaryValues.outOfStockDate = summary.outOfStockDate || summary.isolatedValues?.outOfStockDate;
  }
  const outOfStockDate = formatDate(summaryValues?.outOfStockDate);
  const recommendedOrderDate = substractDays(
    summaryValues?.outOfStockDate,
    summary?.leadTime || 0
  );

  const newOutOfStockDate = formatDate(summary?.newOutOfStockDate);
  const newRecommendedOrderDate =
    restockType === RestockType.Warehouse
      ? substractDays(summary?.newOutOfStockDate, restockSuggestion?.localLeadTimeDays || 0)
      : substractDays(summary?.newOutOfStockDate, newLeadTime);

  const outOfStockToCalculate = isPurchasing
    ? startOfDay(new Date(summaryValues?.outOfStockDate))
    : startOfDay(new Date(summary?.newOutOfStockDate));
  const today = startOfDay(new Date());
  const daysLeft = differenceInDays(outOfStockToCalculate, today);

  return [
    {
      displayName: `Projected Sales Over Current Horizon (${projectedSalesOverHorizonDay} days)`,
      val: projectedSalesOverHorizonDay,
      data: Math.round(isPurchasing ? fullMeanLtd : newFullMeanLtd),
      tooltip: 'Calculated prediction of sales for the current sales period',
    },
    {
      displayName: `Projected Sales Over PO Horizon (${projectedSalesOverHorizonDay} days)`,
      val: projectedSalesOverHorizonDay,
      data: Math.round(isPurchasing ? poMeanLtd : newPoMeanLtd),
      tooltip:
        'Calculated prediction of future sales for the next ordering period',
    },
    ...recommendedSafetyStockRow,
    {
      displayName: 'Projected Kit Demand',
      data: 0,
      // data: snapshot?.childDemand,
    },
    {
      displayName: 'Projected OOS Date',
      data: isPurchasing ? outOfStockDate : newOutOfStockDate,
    },
    {
      displayName: 'Projected Days Left in Stock',
      data: summaryValues?.outOfStockDate ? Math.max(daysLeft, 0) : '> 1yr',
    },
    {
      displayName: 'Recommended Order Date',
      data: isPurchasing ? recommendedOrderDate : newRecommendedOrderDate,
    },
  ];
}

export function getSalesHistory(
  dataSource: any,
  isTotalComponentDataOn: boolean
): any {
  const { result, item } = dataSource as { result: Result; item: Item };

  return [
    {
      displayName: 'Total Months',
      data: addTextUnitToNumber(
        (isTotalComponentDataOn
          ? result?.totalItemHistoryLength
          : result?.restockTotalItemHistoryLength) || 0,
        '',
        true
      ),
    },
    {
      displayName: 'Applied Backfill',
      data: item.useBackfill ? 'Y' : 'N',
    },
    {
      displayName: 'Applied Overrides',
      data: item.useHistoryOverride ? 'Y' : 'N',
    },
  ];
}

export function getOrderingParameters(dataSource: any): any {
  const { item, result } = dataSource as { item: Item; result: Result };
  const serviceLevelAdditional =
    result.settingsSources?.serviceLevel.source === 'Vendor'
      ? 'Supplier'
      : result.settingsSources?.serviceLevel.source;

  return [
    {
      displayName: 'Service Level',
      data: ServiceLevelListDisplay.get(
        result.settingsSources?.serviceLevel.value
      ).val,
      additionalData: serviceLevelAdditional,
    },
    {
      displayName: 'Case Pack Qty',
      data: item.lotMultipleQty,
    },
  ];
}

export function getVendorInformation(dataSource: any): any {
  const { item, result } = dataSource as { item: Item; result: Result };

  let additionalDatas = {
    leadTimeAdditional:
      result.settingsSources.leadTime.source === 'Vendor'
        ? 'Supplier'
        : result.settingsSources.leadTime.source,
    orderIntervalAdditional:
      result.settingsSources.orderInterval.source === 'Vendor'
        ? 'Supplier'
        : result.settingsSources.orderInterval.source,
    moqAdditional:
      result.settingsSources.moq.source === 'Vendor'
        ? 'Supplier'
        : result.settingsSources.moq.source,
  };

  return [
    {
      displayName: 'Supplier Name',
      data: item.vendorName,
    },
    {
      displayName: 'Lead Time',
      data: result.settingsSources.leadTime.value,
      additionalData: additionalDatas.leadTimeAdditional,
    },
    {
      displayName: 'Order Interval',
      data: result.settingsSources.orderInterval.value,
      additionalData: additionalDatas.orderIntervalAdditional,
    },
    {
      displayName: 'MOQ',
      data: result.settingsSources.moq.value,
      additionalData: additionalDatas.moqAdditional,
    },
  ];
}

export function getItemRelationships(dataSource: any): any {
  const { item, snapshots, snapshotsByGroup } = dataSource as {
    snapshots: Snapshot[];
    snapshotsByGroup: Snapshot[];
    item: any;
  };

  return [
    {
      displayName: 'Tags',
      data: item.tags?.join(','),
    },
    {
      displayName: 'Included in Kit',
      data: snapshotsByGroup.length > 1 ? 'Y' : 'N',
    },
    {
      displayName: 'Kit Includes',
      data: snapshots.length !== 0 ? 'Y' : 'N',
    },
  ];
}

export function getPurchaseOrders(): any {
  return [
    {
      displayName: 'PO Number',
      field: 'refNum',
      type: MetaDataFieldType.String,
    },
    {
      displayName: 'Supplier Name',
      field: 'vendorName',
      type: MetaDataFieldType.String,
    },
    {
      displayName: 'Receive Date',
      field: 'dueDate',
      type: MetaDataFieldType.DateOnly,
    },
    {
      displayName: 'PO Date',
      field: 'docDate',
      type: MetaDataFieldType.DateOnly,
    },
    {
      displayName: 'Order Qty',
      field: 'orderQty',
      type: MetaDataFieldType.Decimal,
    },
    {
      displayName: 'Open Qty',
      field: 'openQty',
      type: MetaDataFieldType.Decimal,
    },
  ];
}

export function getKitDemand(): any {
  return [
    {
      displayName: 'Child',
      field: 'childName',
      type: MetaDataFieldType.String,
    },
    {
      displayName: 'Inventory',
      field: 'supply',
      type: MetaDataFieldType.Decimal,
    },
    {
      displayName: 'Units Per Assembly',
      field: 'unitsPerAssembly',
      type: MetaDataFieldType.Decimal,
    },
  ];
}

export function getParentKitDemand(): any {
  return [
    {
      displayName: 'Kits and Component',
      field: 'itemName',
      type: MetaDataFieldType.String,
    },
    {
      displayName: 'Direct Demand',
      field: 'directDemand',
      type: MetaDataFieldType.Decimal,
    },
    {
      displayName: 'Inventory',
      field: 'supply',
      type: MetaDataFieldType.Decimal,
    },
    {
      displayName: 'Demand Inventory',
      field: 'snapshotQty',
      type: MetaDataFieldType.Decimal,
    },
    {
      displayName: 'Units Per Assembly',
      field: 'unitsPerAssembly',
      type: MetaDataFieldType.Decimal,
    },
  ];
}

export function getConsolidate(isAscCompany): any {
  return [
    {
      displayName: isAscCompany ? 'SKU' : 'Item Name',
      field: 'itemName',
      type: MetaDataFieldType.String,
    },
    {
      displayName: 'Description',
      field: 'description',
      type: MetaDataFieldType.String,
    },
    {
      displayName: 'Consolidated Qty',
      field: 'consolidatedQty',
      type: MetaDataFieldType.Decimal,
    },
    {
      displayName: 'Child Qty',
      field: 'childQty',
      type: MetaDataFieldType.String,
    },
    {
      displayName: 'Purchase As',
      field: 'lotMultipleItemName',
      type: MetaDataFieldType.String,
    },
    {
      displayName: 'Case Pack Qty',
      field: 'lotMultipleQty',
      type: MetaDataFieldType.Decimal,
    },
  ];
}
