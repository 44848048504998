import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { BaseComponent } from '@stockaid/core';

import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';

import { MetadataService } from 'src/app/core/services/metadata.service';
import { ShipmentService } from 'src/app/core/services/shipment.service';
import { ShipmentDetailService } from 'src/app/core/services/shipment-detail.service';

import { Shipment } from 'src/app/core/models/shipment';
import { ShipmentDetail } from 'src/app/core/models/shipment-detail';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ForecastRXDataTableComponent } from 'src/app/theme/shared/forecastui/forecast-rxdata-table/forecast-rxdata-table.component';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ShipmentDetailField } from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { drawTableForJsPDF } from '@stockaid/utils';

@Component({
  selector: 'app-shipment-pick-list-view.component',
  templateUrl: './shipment-pick-list-view.component.html',
  styleUrls: ['./shipment-pick-list-view.component.scss'],
})
export class ShipmentPickListViewComponent
  extends BaseComponent
  implements OnInit
{
  @Input() shipmentKey: string;

  @ViewChild('pickList') pickList: ElementRef;
  @ViewChild('forecastTable')
  forecastTable: ForecastRXDataTableComponent<ShipmentDetail>;

  metadataFields: MetaDataField[] = [];
  shipmentDetailRef = new ShipmentDetail();
  additionalReadOnlyCols = Object.getOwnPropertyNames(new Shipment());
  reloadGrid: Subject<boolean> = new Subject<boolean>();
  isLoading = false;
  selectedShipment: Shipment;
  printListOptions: {
    isMinified: boolean;
  } = {
    isMinified: true,
  };

  constructor(
    public shipmentDetailService: ShipmentDetailService,
    private shipmentService: ShipmentService,
    private metadataService: MetadataService,
    private activeModal: NgbActiveModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.shipmentService
      .getByIdWithAdditionalInfos(this.shipmentKey, ['address', 'total'])
      .pipe(
        this.autoCleanUp(),
        tap((shipment) => (this.selectedShipment = shipment)),
        switchMap(() => {
          return this.metadataService.getShipmentDetailMetaData(
            'shipmentDetails'
          );
        })
      )
      .subscribe((metadata) => {
        this.metadataFields = metadata?.fields
          .filter(
            (f) =>
              ![
                ShipmentDetailField.localQty,
                ShipmentDetailField.imageUrl,
                ShipmentDetailField.receivedQty,
                ShipmentDetailField.notes,
              ].includes(f.field as ShipmentDetailField)
          )
          .map((f) => ({ ...f, includedInChooser: true }));
      });
  }

  get shipmentId(): string {
    return this.selectedShipment?.shipmentId;
  }

  get shipmentName(): string {
    return this.selectedShipment?.shipmentName;
  }

  get destinationFulfillmentCenterId(): string {
    return this.selectedShipment?.destinationFulfillmentCenterId;
  }

  get label(): string {
    return this.selectedShipment?.labelPrepPreference === 'SELLER_LABEL'
      ? 'Seller'
      : 'Amazon';
  }

  get shipmentSource(): string {
    return this.selectedShipment?.shipmentSource;
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  newOnPrintPickList() {
    this.isLoading = true;
    let pdf = new jsPDF('p', 'mm');
    pdf.setProperties({
      title: this.shipmentName + '-pick_list',
    });

    // Draw Shipment Pick List title
    autoTable(pdf, {
      theme: 'plain',
      headStyles: { fontStyle: 'bold', fontSize: 15 },
      head: [['Shipment Pick List']],
    });

    // Draw Shipment infos
    autoTable(pdf, {
      theme: 'grid',
      body: [['', '']],
      didDrawCell: (data) => {
        if (data.column.dataKey === 0 && data.cell.section === 'body') {
          autoTable(pdf, {
            theme: 'plain',
            startY: data.cell.y + 2,
            margin: { left: data.cell.x + data.cell.padding('left') },
            body: [
              [`Shipment ID: ${this.shipmentId}`],
              [`Name: ${this.shipmentName}`],
              [`Label: ${this.label}`],
              [''],
              [`Total SKUs: ${this.selectedShipment?.totalSKUs || 0}`],
              [`Total Units: ${this.selectedShipment?.totalUnits || 0}`],
            ],
          });
        }

        if (data.column.dataKey === 1 && data.cell.section === 'body') {
          const addressLine2OfShipTo = this.selectedShipment?.shipToAddress?.addressLine2
            ? `${this.selectedShipment?.shipToAddress?.addressLine2 || ''} ${this.selectedShipment?.shipToAddress?.countryCode || ''}`
            : `${this.selectedShipment?.shipToAddress?.city || ''} ${this.selectedShipment?.shipToAddress?.stateOrProvinceCode || ''} ${this.selectedShipment?.shipToAddress?.postalCode || ''} ${this.selectedShipment?.shipToAddress?.countryCode || ''}`
          autoTable(pdf, {
            theme: 'plain',
            startY: data.cell.y + 2,
            margin: { left: data.cell.x + data.cell.padding('left') },
            body: [
              ['Ship From:', 'Ship To:'],
              [
                this.selectedShipment?.shipFromAddress?.fullName || this.selectedShipment?.shipmentSource || '',
                this.selectedShipment?.destinationFulfillmentCenterId || '',
              ],
              [
                this.selectedShipment?.shipFromAddress?.addressLine1 || '',
                this.selectedShipment?.shipToAddress?.addressLine1 || '',
              ],
              [
                this.selectedShipment?.shipFromAddress
                  ? `${this.selectedShipment?.shipFromAddress?.city || ''} ${this.selectedShipment?.shipFromAddress?.stateOrProvinceCode || ''} ${this.selectedShipment?.shipFromAddress?.postalCode || ''} ${this.selectedShipment?.shipFromAddress?.countryCode || ''}`
                  : '',
                this.selectedShipment?.shipToAddress
                  ? addressLine2OfShipTo
                  : '',
              ],
            ],
          });
        }
      },
      bodyStyles: {
        minCellHeight: 50,
        lineColor: 10,
      },
    });

    // Draw Shipment Items
    const headers = this.forecastTable.grid.headerColumns.map((s) => ({
      content: s.title,
      styles: {
        cellWidth: 'wrap',
      },
    }));
    const fields = this.forecastTable.grid.headerColumns.map((s) => s.field);
    pdf = drawTableForJsPDF<ShipmentDetail>(
      pdf,
      headers,
      fields,
      this.forecastTable.items
    );

    // Many browsers have security that prevent displaying data URIs in new tabs
    // So we have to open a new window that renders an iframe with the blob URL
    // https://github.com/parallax/jsPDF/issues/1413
    pdf.autoPrint();
    const embed =
      '<html>' +
      '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
      '<body>' +
      '<iframe src="' +
      pdf.output('bloburl') +
      '"></iframe>' +
      '</body></html>';
    const x = window.open();
    x.document.open();
    x.document.write(embed);
    x.document.close();

    this.isLoading = false;
  }
}
